import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Contact } from '../../../../../../common/Contact'
import { CONTACTS } from '../../../contacts.constants';

@Component({
    selector: 'app-dialog-comission',
    templateUrl: './dialog-comission.component.html',
    styleUrls: ['./dialog-comission.component.scss']
})
export class DialogComissionComponent implements OnInit {

    public form: FormGroup;
    public contact: Contact;
    public TLD = 'cl';
    public contactList: Contact[] = CONTACTS;

    constructor() { }

    ngOnInit(): void {
        this.contact = this._getContact(this.TLD);

        this.form = new FormGroup({
            name: new FormControl('', Validators.required),
            rut: new FormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(12)]),
            phone: new FormControl('', [Validators.required, Validators.pattern('[0-9 ]{8,9}')]),
            email: new FormControl('', [Validators.required, Validators.email])
        })
    }

    public onSubmit(): void {
        if (this.form.invalid) {
            return;
        }
    }

    private _getContact(TLD: string): Contact {
        const aux = (TLD === 'mx' ? 'me' : TLD);
        return this.contactList.filter(c => c.country_code === aux)[0];
    }

}
