import { Contact } from '../../../../common/Contact';
import { CONTACTS } from './../contacts.constants';
import { Component, OnInit, Output } from '@angular/core';
import { Meta } from '../../../../common/Meta';
import { urlEnv } from '../../environments/environment';
import { SEOService } from '../shared/services/seo.service';
import { UrlService } from '../shared/services/url.service';

@Component({
    selector: 'app-contact-wrapper',
    templateUrl: './contact-wrapper.component.html'
})
export class ContactWrapperComponent implements OnInit {
    public urlEnv = urlEnv;
    playVideo = false;

    @Output() TLD = 'cl';
    @Output() Meta: Meta;
    @Output() MessageFrom = 'TUU';
    @Output() ContactList: Contact[] = CONTACTS.filter(c => c.country_code === 'cl');

    title = 'Contactar con <b><i>ventas</i></b>';

    constructor(
        private urlService: UrlService,
        private seoService: SEOService
    ) {
        this.Meta = new Meta(
            'Contactar con ventas | Haulmer',
            'Completa tus datos y en pocos segundos uno de nuestros ejecutivos de venta se comunicará contigo. Descubre todo lo que Haulmer tiene para ofrecer.',
            'TUU',
            this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-contact.png',
            'png',
            644,
            374,
            'Contacto con ventas, Haulmer'
        );

        this.Meta.addKeyword('contactar ventas');
        this.Meta.addKeyword('ventas');
        this.Meta.addKeyword('atención al cliente');
        this.Meta.addKeyword('centro de soporte');
    }

    ngOnInit() {
        this.seoService.updateCanonicalUrl('contactar-con-ventas/');

        if (this.urlService.getValue('partner') === 'true') {
            this.MessageFrom = 'TUU - Quiero ser socio';
        }
    }
}
