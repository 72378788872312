import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MetaService } from '@ngx-meta/core';

import { urlEnv } from '../../environments/environment';
import { SEOService } from '../shared/services/seo.service';

@Component({
    selector: 'app-catalog-page',
    templateUrl: './catalog-page.component.html'
})
export class CatalogPageComponent implements OnInit {

    public urlEnv = urlEnv;

    public showImage1 = false;
    public showImage2 = false;
    public showImage3 = false;

    public dataPriceSection = {
        buttons: [
            {
                id: 'TUU-cata-precios-compra-simple'
            },
            {
                id: 'UU-cata-precios-compra-con-complementos'
            }
        ]
    };

    public itemsFAQ = [
        {
            title: '¿Cuántos productos puedo agregar al catálogo?',
            text: 'No hay límite para agregar productos a tu catálogo.'
        }, {
            title: '¿Dentro de TUU puedo visualizar y personalizar en detalle mi stock?',
            text: '¡Sí, y es lo mejor de TUU Catálogo! Al ser el catálogo más completo disponible actualmente, contamos con funciones que te permiten definir desde el precio hasta la cantidad de stock con el que cuentas, el código de barra que vas a escanear y cada pequeño detalle o característica que hace únicos a tus productos, todo desde TUU mismo dispositivo para recibir pagos.'
        }, {
            title: '¿Cuánto control tengo por sobre mis productos?',
            text: '¡Control total! Cuando eres TUU puedes acceder a funcionalidades que te darán todo el poder por sobre tu catálogo, además de contar con SKU ilimitados, opción multicatálogos para manejar más de uno solo al mismo tiempo y la máxima personalización al detalle de todos los productos que desees ingresar.'
        }, {
            title: '¿Con TUU es posible manejar más de un tipo de impuestos?',
            text: 'Sí, tanto en nuestra aplicación como en el espacio de trabajo, podrás asignar más de un impuesto a tus productos seleccionándolos desde una lista pre establecida por TUU. Esta lista contiene todos los impuestos existentes en Chile.'
        }
    ];

    public dataSupport = [
        {
            id: 't-catalogo-capacitacion',
            url: 'https://help.haulmer.com/meetings'
        }, {
            id: 't-catalogo-soporte',
            url: '/contactar-con-soporte'
        }
    ];

    constructor(
        private readonly _meta: MetaService,
        @Inject(PLATFORM_ID) private platformId: any,
        private seoService: SEOService
    ) { }

    ngOnInit(): void {
        this._setMeta();
        this.seoService.updateCanonicalUrl('catalogo/');

        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 500);
    }

    private _setMeta() {
        this._meta.setTitle('Crea catálogos y organiza tus productos por categorías.');
        this._meta.setTag('description', 'Agiliza tus ventas utilizando catálogos de tus productos. Crea, edita y organiza fácilmente. Vende por unidades o fracciones de medidas y asocia tus productos a múltiples impuestos.');
        this._meta.setTag('keywords', 'Punto de venta, sistema de venta, POS, software punto de venta');
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-catalog.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Crea catálogos y organiza tus productos por categorías.');
    }

}
