import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-plan-list',
    templateUrl: './plan-list.component.html',
    styleUrls: ['./plan-list.component.scss']
})
export class PlanListComponent implements OnInit {

    list1 = [
        {
            icon: 'icon-sprite-infinity',
            text: 'Ilimitados Puntos de ventas.'
        },
        {
            icon: 'icon-sprite-layers',
            text: 'Catálogo con <b>productos ilimitados</b> y variantes de producto.'
        },
        {
            icon: 'icon-sprite-copy',
            text: 'Inventarios ilimitados <b>sin tope de bodegas y/o sucursales</b>.'
        }
    ];

    list2 = [
        {
            icon: 'icon-sprite-file',
            text: '<b>Emisión ilimitada,</b> boleta, factura, y guía de despacho electrónica.'
        },
        {
            icon: 'icon-sprite-users',
            text: '<b>Ilimitadas Razón social</b> para emision de documentos electrónicos.'
        },
        {
            icon: 'icon-sprite-calendar-today',
            text: '<b>Agenda online</b> con sistema de reserva de horas.'
        }
    ];

    constructor() { }

    ngOnInit(): void {
    }

}
