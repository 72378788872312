import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { traceButton2 } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-section-warranty',
    templateUrl: './section-warranty.component.html',
    styleUrls: ['./section-warranty.component.scss']
})
export class SectionWarrantyComponent implements OnInit {
    @Input() public loadingImages: boolean;
    @Input() public isDirect: boolean;

    constructor(@Inject(PLATFORM_ID) private platformId: any) { }

    ngOnInit(): void {
    }

    goToUrl(url: string, event: Event): void {
        if (isPlatformBrowser(this.platformId)) {
            window.open(url, '_blank');
        }
        traceButton2(event, 'navigate');
    }

}
