import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-benefits-for-clients',
    templateUrl: './benefits-for-clients.component.html',
    styleUrls: ['./benefits-for-clients.component.scss']
})
export class BenefitsForClientsComponent {
    @Input() public loadingImages: boolean;

}
