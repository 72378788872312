import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, PLATFORM_ID, HostListener, } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ApiService } from '../shared/services/api.service';
import { UrlService } from '../shared/services/url.service';
import { Unsubscriber } from '../../../../common/unsuscriber';
import { ReCaptchaV3Service } from 'ngx-captcha';

@Component({
    selector: 'app-sim',
    templateUrl: './sim.component.html',
    styleUrls: ['./sim.component.scss'],
})
export class SimComponent extends Unsubscriber implements OnInit, AfterViewInit {
    @ViewChild('fakeInputWidth') public fakeInputWidth: ElementRef;
    @ViewChild('simInput') public simInput: ElementRef;

    private siteKey: string = '6LeFGJMUAAAAAEDLJLme8uOEGMOTwsjYB6v-gZe7'
    private captcha: unknown;

    private isMobile: boolean = false;

    public badIcc: boolean = false;
    public iccUrl;
    public inputSim: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(19),
        Validators.maxLength(30)
    ]);

    public responseKind: 'success' | 'error' | "notExist";
    public responseStatus: 0 | 1 | 2 | 3 | 4;

    public isFetching = false;
    public responses = {
        success: {
            1: {
                head: "¡De este lado luce todo bien! tu SIM se encuentra activa",
                text: "Verifica que tengas un buen nivel de señal y que los datos del POS estén activos. Recuerda que el SIM es ilimitado solo para uso de aplicaciones Haulmer."
            },
            2: {
                head: "¡Ya puedes volver a disfrutar de los servicios TUU!",
                subhead: "Consejos para evitar suspensión de tu SIM",
                text: [
                    "No uses tu SIM en otro equipo POS, recuerda que el SIM es de uso exclusivo y con tráfico ilimitado para el uso de aplicaciones Haulmer.",
                    "Evita el abuso de tráfico de datos (solo se garantizan los datos suficientes para el óptimo funcionamiento de las aplicaciones Haulmer en TUU POS).",
                    "Dale un uso periódico a tu POS, ya que los SIM se desactivan cuando no se ha detectado tráfico de datos durante 3 meses."
                ]
            }
        },
        error: {
            head: "¡Tu SIM está suspendida!",
            1: {
                reason: "Hemos detectado que tu SIM no se encuentra en la máquina TUU asignada, recuerda que el SIM es de uso exclusivo y con tráfico ilimitado para el uso de aplicaciones TUU.",
                solution: "Cambia el SIM al dispositivo original y se reactivará de forma inmediata."
            },
            2: {
                reason: "Abuso de tráfico de datos (Solo se garantizan los datos suficientes para el óptimo funcionamiento de las aplicaciones Haulmer en TUU POS).",
                solution: 'Debes completar el siguiente formulario para reactivar tu SIM, esta solicitud puede tardar hasta 12 horas y debe ser procesada durante días hábiles.'
                //solution: "Reactivaremos tu SIM por 2 días hábiles, con el compromiso de utilizar la SIM para uso exclusivo de la Máquina TUU."
            },
            3: {
                reason: "No se ha detectado tráfico de datos durante los últimos 3 meses.",
                solution: "Apretar el botón reactivar."
            },
            4: {
                reason: "No ha pagado su plan mensual",
                solution: "Ingresa a tu espacio de trabajo y paga tus servicios pendientes."
            },
            0:
            {
                reason: 'El motivo no es claro.',
                solution: 'Contactate con un ejecutivo para obtener más información.'
            }
        },
        notExist: {
            head: "Tu SIM no se encuentra registrada en nuestro servicio",
            1: {
                text: "Corrobora el número de SIM o tu conexión a internet. Verifica con tu proveedor o intenta utilizar una red wifi."
            }
        }
    }

    public messages: object[] = [{ 1: 1 }];

    @HostListener('window:resize')
    onResize() {
        this.isMobile = window.innerWidth < 990;
    }

    constructor(
        @Inject(DOCUMENT) public document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
        private apiService: ApiService,
        private urlService: UrlService,
        private reCaptchaV3Service: ReCaptchaV3Service
    ) {
        super()
    }

    public ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.isMobile = window.innerWidth < 990;
            this.iccUrl = this.urlService.getValue('icc');
            if (!!this.iccUrl) {
                this.inputSim.setValue(this.iccUrl);
            }
        }
    }

    public ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.simInput.nativeElement.focus();
            setTimeout(() => {
                const keyEvent = new Event('keyup');
                this.simInput.nativeElement.dispatchEvent(keyEvent);
            }, 1);
        }
    }

    public onSearch(): void {
        this.startCaptcha(true);
        if (this.inputSim.invalid || this.badIcc === true) {
            return;
        }

        const body = {
            "input": this.inputSim.value,
            "option": "1"
        }
        this.badIcc = false;
        this.isFetching = true;
        this.apiService.getStatusSim(body).subscribe(x => {
            if (x.estado === 'ACTIVE') {
                this.responseKind = 'success';
                this.responseStatus = 1;
            } else if (x.estado === 'DEACTIVATED') {
                this.responseKind = 'error';
                if (x.motivo === 'sin_trafico') {
                    this.responseStatus = 3;
                } else if (x.motivo === 'alto_consumo') {
                    this.responseStatus = 2;
                } else if (x.motivo === 'dispositivo_diferente') {
                    this.responseStatus = 1;
                } else {
                    this.responseStatus = 0;
                }
            }
        }, err => {
            this.responseKind = 'notExist';
            this.responseStatus = 1;
            this.badIcc = true;
        }, () => {
        })
    }

    public onActiveSim(): void {
        this.startCaptcha(true);
        if (this.inputSim.invalid || this.badIcc) {
            return;
        }
        const body = {
            "input": this.inputSim.value,
            "option": "2"
        }

        this.isFetching = true;
        this.apiService.getStatusSim(body).subscribe(x => {
            if (x.mensaje === 'Activado correctamente') {
                this.responseKind = 'success';
                this.responseStatus = 2;
            }
        }, err => {
            console.error(err);
        }, () => {
        })
    }

    public allowOnlyNumbers(): void {
        this.inputSim.setValue(this.inputSim.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g));
    }

    public moveClearBtn(): void {
        if (this.inputSim.value.length > 30) {
            return;
        }
        this.badIcc = false;
        this.isFetching = false;
        this.responseKind = undefined;
        const fakeWidthEl = this.fakeInputWidth.nativeElement as HTMLSpanElement;
        const leftPosition = fakeWidthEl.clientWidth + 16;
        const clearBtn = this.document.getElementById('clearInputBtn') as HTMLButtonElement;
        clearBtn.style.left = leftPosition + 'px';
    }

    public typeOf(item): string {
        return typeof item;
    }

    public clearInputValue(): void {
        this.badIcc = false;
        this.isFetching = false;
        this.inputSim.setValue('');
        this.responseKind = undefined;
        this.simInput.nativeElement.focus();
    }

    public goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    private startCaptcha(state: boolean): void {
        let bottom = this.isMobile ? '165px;' : '180px;';
        this.reCaptchaV3Service.execute(this.siteKey,
            'sim_estado',
            (token) => {
                if (state) {
                    this.captcha = token;
                    const element = document.getElementsByClassName('grecaptcha-badge')[0];
                    element.setAttribute('style', element.getAttribute('style') + 'bottom:' + bottom + ' z-index: 1;');
                }
            });
    }
}
