import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MetaService } from '@ngx-meta/core';

import { urlEnv } from '../../environments/environment';
import { SEOService } from '../shared/services/seo.service';

@Component({
    selector: 'app-price-page',
    templateUrl: './price-page.component.html'
})
export class PricePageComponent implements OnInit {
    public urlEnv = urlEnv;

    public showImage1 = false;

    public itemsFAQ = [
        {
            title: '¿Debo pagar por el despacho del producto?',
            text: '¡No es necesario! Todos nuestros complementos se despachan totalmente gratis.'
        }, {
            title: '¿Puedo comprar una nueva máquina POS TUU si ya tengo un plan TUU?',
            text: 'No hay límites en la compra de dispositivos. Puedes tener cuantos quieras e incluso controlar desde un solo lugar todas las sucursales que cuenten con TUU POS interconectadas. Para adquirirlo, sólo  debes comprar directamente en nuestro sitio o contactando a un ejecutivo.'
        }, {
            title: '¿Qué beneficios obtengo independiente de mi plan?',
            text: 'Ya sea que tienes TUU o TUU+, ambos incluyen cantidad ilimitada de creación de puntos de venta, opción de catálogo con productos ilimitados y variantes, inventarios sin topes de bodegas ni sucursales, emisión ilimitada de boletas, facturas y guías de despacho e ilimitada razón social para emisión de documentos electrónicos.'
        }
    ];

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private readonly _meta: MetaService,
        private seoService: SEOService
    ) { }

    ngOnInit(): void {
        this._setMeta();
        this.seoService.updateCanonicalUrl('precios/');

        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 500);
    }

    private _setMeta() {
        this._meta.setTitle('Con TUU, acepta pagos con tarjetas y administra tu negocio.');
        this._meta.setTag('description', 'Registra ventas, emite boletas y acepta pagos con tarjetas. API disponible para emisión de documentos en los principales ecommerce del mercado.');
        this._meta.setTag('keywords', 'Pagos con tarjetas, boleta electrónica, Máquina POS, punto de venta, control de inventario, boleta electrónica, facturación electrónica, integración API facturación');
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-price.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Con TUU, acepta pagos con tarjetas y administra tu negocio.');
    }

}
