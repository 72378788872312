import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MetaService } from '@ngx-meta/core';

import { urlEnv } from '../../environments/environment';
import { SEOService } from '../shared/services/seo.service';

@Component({
    selector: 'app-supplies-page',
    templateUrl: './supplies-page.component.html'
})
export class SuppliesPageComponent implements OnInit {

    public urlEnv = urlEnv;

    public showImage1 = false;

    public itemsFAQ = [
        {
            title: '¿Cuándo estarán disponibles los abonos en mi cuenta bancaria?',
            text: 'El abono del <b>100% de tus ventas diarias sin cortes bancarios</b> se carga de forma automática en la cuenta de tu comercio desde 1 día hábil, tanto para compras con tarjeta de débito como crédito.'
        }, {
            title: '¿Puedo recibir pagos con tarjetas en cualquier dispositivo?',
            text: 'La aplicación de pagos funciona de forma exclusiva en el terminal dispuesto por TUU.'
        }, {
            title: '¿Cuánto tarda en habilitarse el servicio para comenzar a procesar pagos con tarjetas?',
            text: 'Todos los POS se envían activados. EL proceso de habilitación comienza con la recolección de datos del comercio y cuenta de abono. Una vez que este proceso sea verificado y aceptado, se hará la activación de tu dispositivo POS para luego hacer el envío.'
        }, {
            title: 'Actualmente cuento con un proveedor de boletas electrónicas ¿puedo ser TUU también?',
            text: 'Sí, puedes mantener tu proveedor de boletas actual y utilizar TUU al mismo tiempo para procesar tus pagos con tarjetas. De todas formas, te recordamos que TUU entrega de manera gratuita la opción de emitir boletas electrónicas, para que así aproveches de controlar todo desde un solo lugar.'
        }, {
            title: '¿Cómo funciona el abono de dinero para ventas con tarjetas de crédito?',
            text: 'Los abonos de transacciones con tarjetas de crédito pueden ser parciales o totales dependiendo de tu configuración:<ul><li>Puedes usar la modalidad de <b>abono total</b> desactivando las cuotas sin interés desde la configuración de tu canal de venta. De esta manera podrás recibir el dinero total de la venta desde 1 día hábil sin importar el número de cuotas.</li><li>La modalidad de <b>abono parcial</b> se activa cuando tu configuración de comercio acepta cuotas de comercio sin interés, las cuales pueden ir de 2 a 12 cuotas. Bajo esta modalidad el abono de ventas se hará parcialmente de acuerdo al número de cuotas.</li></ul>'
        }
    ];

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private readonly _meta: MetaService,
        private seoService: SEOService
    ) { }

    ngOnInit(): void {
        this._setMeta();

        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 500);
        this.seoService.updateCanonicalUrl('rollos-e-insumos/');
    }

    private _setMeta() {
        this._meta.setTitle('Con TUU, acepta pagos con tarjetas y administra tu negocio.');
        this._meta.setTag('description', 'Registra ventas, emite boletas y acepta pagos con tarjetas. API disponible para emisión de documentos en los principales ecommerce del mercado.');
        this._meta.setTag('keywords', 'Pagos con tarjetas, boleta electrónica, Máquina POS, punto de venta, control de inventario, boleta electrónica, facturación electrónica, integración API facturación');
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-rolls-supplies.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Con TUU, acepta pagos con tarjetas y administra tu negocio.');
    }
}
