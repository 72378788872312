import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MetaService } from '@ngx-meta/core';

import { urlEnv } from '../../environments/environment';

@Component({
    selector: 'app-reservation-page',
    templateUrl: './reservation-page.component.html',
    styleUrls: ['./reservation-page.component.scss']
})
export class ReservationPageComponent implements OnInit {

    public urlEnv = urlEnv;

    public showImages1: boolean;
    public showImages2: boolean;

    constructor(
        private readonly _meta: MetaService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
    }

    ngOnInit(): void {
        this._setMeta();

        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 500);
    }

    private _setMeta() {
        this._meta.setTitle('TUU Reserva: el Software de Agendamiento de Horas Todo en Uno.');
        this._meta.setTag('description', 'Permite a tus clientes agendar horas con facilidad 24/7 con un sistema de reservas todo en uno. Ahorra costos y tiempo recibiendo pagos y agendas con TUU.');
        this._meta.setTag('keywords', 'Agenda online, sistema de reserva, sistema de agendamiento, sistema de reservas online, software de gestión, software reservas, agenda para empresas, calendario en línea, agendar citas online, agenda online, agenda para pacientes, sistema de agenda, software de citas, agenda médica online.');
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-reservation.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'TUU Reserva: Sistema de agendamiento online para tu negocio. ');
    }
}
