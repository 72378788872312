import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-pos-tuu-vs-others',
    templateUrl: './pos-tuu-vs-others.component.html',
    styleUrls: ['./pos-tuu-vs-others.component.scss']
})
export class PosTuuVsOthersComponent implements OnInit {
    @Input() public loadingImages: boolean;

    constructor() { }

    ngOnInit(): void {
    }

}
