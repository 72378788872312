import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { UrlService } from '../shared/services/url.service';
import { isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { ApiService } from '../shared/services/api.service';

@Component({
    selector: 'app-friend-code',
    templateUrl: './friend-code.component.html',
    styleUrls: ['./friend-code.component.scss']
})
export class FriendCodeComponent implements OnInit {
    public isMobile: boolean = false;
    private urlRut: string = '77777777';
    public viewNumber: Number = 1;
    public tryAgain = false


    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        public dialog: MatDialog,
        private _router: Router,
        private urlService: UrlService,
        private apiService: ApiService
    ) { }

    @HostListener('window:resize')
    onResize() {
        this.isMobile = window.innerWidth < 767;
    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.isMobile = window.innerWidth < 767;
            const rut = this.urlService.getValue('rut');
            const fromDevice = this.urlService.getValue('fromdevice');
            if(!!fromDevice) {
                sessionStorage.setItem('fromdevice', fromDevice);
            }

            if (!!rut) {
                sessionStorage.setItem('rut', rut);
                this._router.navigate([], {
                    queryParams: {
                        'rut': null,
                        'fromdevice': null
                    }
                });
            }
        }
    }

    public openDialog(): void {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '320px',
            position: {
                top: '280px'
            },
            data: {
                tryAgain: this.tryAgain
            }
        });

        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.onSubmit();
            } else {
                this.exit();
            }
        });
    }

    public exit(): void {
        this._router.navigate(['/codigo-amigo/salir']);
    }

    public onSubmit() {
        this.viewNumber = 3;
        this.apiService
            .getGiftCode(sessionStorage.getItem('rut'))
            .subscribe(x => {
                if (!x['error']) {
                    sessionStorage
                        .setItem('code', x['code']);
                    this._router
                        .navigate(['/codigo-amigo/mitad-y-mitad/.']);
                } else {
                    this.viewNumber = 1;
                    this.openDialog();
                }
            }), err => {
                this.viewNumber = 1;
                this.openDialog();
            }
    }
}
