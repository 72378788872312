import { Component, ElementRef, OnInit } from '@angular/core';

import { ScrollToClass } from '../../shared/scrollTo.class';

@Component({
    selector: 'app-banner-cyber-day',
    templateUrl: './banner-cyber-day.component.html',
    styleUrls: ['./banner-cyber-day.component.scss']
})
export class BannerCyberDayComponent extends ScrollToClass implements OnInit {

    public value = 'TUUCYBER';

    constructor(
        private _el: ElementRef
    ) {
        super(_el);
    }

    ngOnInit(): void {
    }

    public navigateToSection(className: string): void {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 500);
    }

}
