import { Component } from '@angular/core';

@Component({
    selector: 'app-partners-price-first-screen',
    templateUrl: './partners-price-first-screen.component.html',
    styleUrls: ['./partners-price-first-screen.component.scss']
})
export class PartnersPriceFirstScreenComponent {

    isShowVideo: boolean;

}
