import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MetaService } from '@ngx-meta/core';
// import { MatDialog } from '@angular/material/dialog';

import { urlEnv } from '../../environments/environment';
import { ICardPlan } from './card-plan/card-plan.component';
// import { DialogInstructionComponent } from './dialog-instruction/dialog-instruction.component';
import { UrlService } from '../shared/services/url.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-plan-page',
    templateUrl: './plan-page.component.html',
    styleUrls: ['./plan-page.component.scss']
})
export class PlanPageComponent implements OnInit {
    public urlEnv = urlEnv;

    private params: { subproduct: string; qtyproduct: string; period_opt?: string; pr_code?: string; purchaseOrigin?: string; videoask?: string; opciones?: string; maquina_r?: string;} = { subproduct: '', qtyproduct: '' };
    private paramsOrder: Array<string> = ['subproduct', 'qtyproduct', 'period_opt', 'purchaseOrigin', 'pr_code', 'videoask', 'opciones', 'maquina_r'];

    public cards: Array<ICardPlan> = [
        {
            name: 'Básico',
            commission: '1,99',
            detail: {
                value: 'Gratis',
                link: ''
            },
            button: {
                text: 'Conocer funciones básicas',
                route: '/precios'
            },
            list: [
                '<b>Pago:</b> Acepta pagos con tarjetas',
                '<b>Boleta:</b> Emite sin límites',
                '<b>Reserva:</b> Agenda horas de clientes',
                '<b>Punto </b>de venta: Vende con rapidez',
                '<b>Catálogo:</b> Organiza todos tus productos',
                '<b>Inventario:</b> Controla el stock de tus productos'
            ]
        }, {
            name: 'Plus',
            commission: '1,79',
            detail: {
                supvalue: 'Pruébalo 3 meses gratis',
                subtext: 'Sólo para nuevos clientes',
                price: 15000,
                link: ''
            },
            button: {
                text: 'Conocer funciones básicas',
                route: '/precios'
            },
            list: [
                '<b>Funciones avanzadas para Reserva</b> con agendas y sucursales ilimitadas.',
                '<b>API para integrar la emisión de documentos</b> electrónicos.',
                '<b>Plugins para integrar</b> emisión de documentos con tu ecommerce.',
                '<b>Emisión masiva de documentos</b> con Complemento Google Sheet.'
            ]
        }
    ];

    public selectedPlan = 1;

    constructor(
        public location: Location,
        private readonly _meta: MetaService,
        private router: Router,
        // private _dialog: MatDialog,
        private urlService: UrlService
    ) { }

    ngOnInit(): void {
        this._setMeta();
        this.selectPlan();
    }

    // public openInstruction(): void {
    //     const bottomPosition = window.innerWidth > 540 ? null : '0';

    //     this._dialog.open(DialogInstructionComponent, {
    //         panelClass: 'dialogInstruction',
    //         backdropClass: 'backdrop-opacity-25',
    //         width: '584px',
    //         position: { bottom: bottomPosition }
    //     });

    // }

    private _setMeta() {
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.urlEnv + 'assets/images/og/og-plan-and-products.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
    }

    private selectionPlan() {
        const subproduct = this.urlService.getValue('subproduct');
        this.params.qtyproduct = this.urlService.getValue('qtyproduct');
        this.params.pr_code = this.urlService.getValue('pr_code') ?? null;
        this.params.purchaseOrigin = this.urlService.getValue('purchaseOrigin') ?? null;
        this.params.videoask = this.urlService.getValue('videoask') ?? null;
        this.params.opciones = this.urlService.getValue('opciones') ?? null;
        this.params.maquina_r = this.urlService.getValue('maquina_r') ?? null;
        if (!subproduct.split(',')[0].includes('TUU')) {
            this.params.qtyproduct = '1,' + this.params.qtyproduct;
        }

        if (this.selectedPlan === 0) {
            this.params.subproduct = 'TUU,' + subproduct;
            this.params.period_opt = null;
        } else {
            this.params.subproduct = 'TUUP,' + subproduct;
            this.params.period_opt = '1';
        }
    }

    public selectPlan() {
        const subproduct = this.urlService.getValue("subproduct") ?? '';
        if (subproduct.split(',')[0] === 'TUU') {
            this.selectedPlan = 0;
        }
    }

    public goToUrl(): void {
        this.selectionPlan();
        if (this.params.purchaseOrigin === 'reserva') {
            this.params.subproduct = this.params.subproduct.split(',').reduce((a, b) => {
                if ((a === 'TUUP' && b === 'TUU') || (b === 'TUUP' && a === 'TUU')) {
                    return this.selectedPlan === 0 ? 'TUU' : 'TUUP'
                } else if (a === b) {
                    return a;
                } else {
                    return `${a},${b}`;
                }
            });
            this.router.navigate(['seleccion-producto'], {
                queryParams: this.params,
                queryParamsHandling: 'merge',
            })
        } else {
            const params = new URLSearchParams();
            this.paramsOrder.forEach(paramName => {
                if (this.params[paramName]) {
                    params.append(paramName, this.params[paramName]);
                }
            });
            const url = decodeURIComponent(this.urlEnv.TUU_CART_URL + params.toString());
            window.open(url, '_self');
        }
    }
    public goBack() {
        this.location.back();
    }
}
