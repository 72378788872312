import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { traceButton2 } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-first-screen-resellers',
    templateUrl: './first-screen-resellers.component.html',
    styleUrls: ['./first-screen-resellers.component.scss']
})
export class FirstScreenResellersComponent {

    constructor(
        @Inject(PLATFORM_ID) private platformId: any
    ) { }

    public goToUrl(url: string, event: Event): void {
        if (isPlatformBrowser(this.platformId)) {
            window.open(url, '_blank');
            traceButton2(event, 'typeform');
        }
    }

}
