import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MetaService } from '@ngx-meta/core';

import { urlEnv } from '../../environments/environment';

@Component({
    selector: 'app-resellers',
    templateUrl: './resellers.component.html'
})
export class ResellersComponent implements OnInit {
    public urlEnv = urlEnv;

    public showImage1 = false;
    public showImage2 = false;
    public showImage3 = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private readonly _meta: MetaService
    ) {
    }

    ngOnInit(): void {
        this._setMeta();

        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 500);
    }

    private _setMeta() {
        this._meta.setTitle('Gana dinero con el Programa de Revendedores TUU');
        this._meta.setTag('description', 'Con el Programa revendedores TUU gana dinero vendiendo máquinas de pagos y boletas electrónicas');
        this._meta.setTag('keywords', 'Revendedores, revendedor, máquina de pagos, emprendimiento, comisión, máquina pos, TUU, ganancias, punto de venta, pagos con tarjetas, POS, máquina para pagar con tarjeta, pago con tarjeta, boleta electrónica, emprendedores');
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-resellers.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Gana dinero con el Programa de Revendedores TUU');
    }

}
