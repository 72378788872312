import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-partner-logos',
    templateUrl: './partner-logos.component.html',
    styleUrls: ['./partner-logos.component.scss']
})
export class PartnerLogosComponent {
    @Input() public loadingImages: boolean;
}
