import { Component } from '@angular/core';

@Component({
    selector: 'app-quotas-logos',
    templateUrl: './quotas-logos.component.html',
    styleUrls: ['./quotas-logos.component.scss']
})
export class QuotasLogosComponent {

}
