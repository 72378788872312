import { Component, ElementRef, Input } from '@angular/core';

import { ScrollToClass } from '../../shared/scrollTo.class';

@Component({
    selector: 'app-benefits-installments',
    templateUrl: './benefits-installments.component.html',
    styleUrls: ['./benefits-installments.component.scss']
})
export class BenefitsInstallmentsComponent extends ScrollToClass {
    @Input() public loadingImages: boolean;

    constructor(private _el: ElementRef) {
        super(_el);
    }

    public navigateToSection(className: string): void {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 500);
    }
}
