import { Component, Input } from '@angular/core';


@Component({
    selector: 'app-section-payment-methods',
    templateUrl: './section-payment-methods.component.html',
    styleUrls: ['./section-payment-methods.component.scss']
})
export class SectionPaymentMethodsComponent {
    @Input() public loadingImages: boolean;

    public benefitsList1 = [
        {
            icon: 'icon-sprite-credit-card',
            iconSize: 32,
            text: '<b>Acepta pagos</b> con todo tipo de tarjetas de débito y/o crédito.'
        }, {
            icon: 'icon-sprite-chip',
            iconSize: 32,
            text: '<b>Recibe tus pagos donde sea</b> con TUU Chip de internet ilimitado.',
            // tooltip: 'El tráfico es de uso exclusivo para el óptimo funcionamiento del terminal y no puede ser utilizado para otro uso'
        }, {
            icon: 'icon-sprite-pci',
            iconSize: 46,
            text: '<b>Certificación PCI</b> para garantizar la protección de tus datos y la de tus clientes.'
        }
    ];

    public benefitsList2 = [
        {
            icon: 'icon-sprite-nfc',
            iconSize: 40,
            text: '<b>Funciona con aplicaciones NFC,</b> Google Pay, Apple Pay, Contactless, entre otros.'
        }, {
            icon: 'icon-sprite-pie-chart',
            iconSize: 40,
            text: 'Acepta pagos <b>en cuotas o al contado con tarjetas.</b>'
        }, {
            icon: 'icon-sprite-calendar-today',
            iconSize: 32,
            text: 'Recibe tus abonos <b>desde 1 día hábil.</b>'
        }
    ];
}
