import {
    AfterViewInit,
    Component,
    OnInit,
    Input,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { panelStatus } from '../seguimiento-common/panels.service';
@Component({
    selector: 'app-seguimiento-reclamos-page',
    templateUrl: './seguimiento-reclamos.component.html',
    styleUrls: ['../seguimiento-common/seguimiento-styles.component.scss'],
})
export class SeguimientoReclamosPageComponent implements OnInit, AfterViewInit {
    @Input() public cardMap: any;
    @ViewChild('stepper', { static: false }) private matstep: any;
    matMenu$ = this.paneStat.matMenu$;
    constructor(private paneStat: panelStatus, private renderer: Renderer2) {}
    public ngOnInit(): void {}
    public ngAfterViewInit(): void {
        this.setStyles();
    }
    public getPanel(): boolean {
        return this.paneStat.getValue('isOpenMat4');
    }
    public mngPanel() {
        this.paneStat.managePanel('isOpenMat4');
    }
    private setStyles() {
        try {
            let completed = false;
            if (this.cardMap.activeIndex == 3) completed = true;
            for (let index = 0; index <= this.cardMap.activeIndex; index++) {
                this.renderer.setStyle(
                    this.matstep._elementRef.nativeElement.children[index],
                    'border-color',
                    'blue'
                );
                this.renderer.setProperty(
                    this.matstep._steps._results[index],
                    'completed',
                    true
                );
            }
            this.renderer.setProperty(
                this.matstep._steps._results[this.cardMap.activeIndex],
                'completed',
                completed
            );
            this.renderer.setProperty(
                this.matstep,
                '_selectedIndex',
                this.cardMap.activeIndex
            );
        } catch (error) {
            console.error(error);
        }
    }
}
