import { IDataStrip } from './../../../../../common/interfaces/promotions.interface';
import { Component } from "@angular/core";
import { AppType } from "../../../../../common/assets/website-data";

@Component({
    selector: 'app-strip-wrapper',
    templateUrl: './strip.component.html'
})

export class StripWrapperComponent {

    public dataStrip: IDataStrip = {
        text: `
        <span><i>MODELOS MINI Y MINI S CON EMISIÓN DE BOLETA <img class="strip-img__many" src="/assets/images/icons/icon-face-party.svg" alt="icon fire" /> Celébralo con 30% OFF en maquinitas MINI <img class="strip-img__many" src="/assets/images/icons/icon-fire.svg" alt="icon fire" /> Usa el código: TUUMINI30</i></span>
        `,
        style: 'many__text'
    };

    public dataStripCyber: IDataStrip = {
        text: `
        <span>
            <i class='color-white'>
                <img class="strip-img__many__extend logo-tuu-fix" src="/assets/images/icons/logo-tuu.svg" alt="icon tuu" width=58px height=23px />
                ESTAMOS EN CYBER
            </i>
                😎
            <i class='color-accent-third'>
                HASTA 60%OFF EN MÁQUINAS POS
            </i>
                🔥
            <i class='color-white'>
                POR TIEMPO LIMITADO
            </i>
        </span>
        `,
        style: 'many__text'
    };

    public get appType() {
        return AppType.tuu;
    }
}