import { Component } from '@angular/core';

@Component({
    selector: 'app-quotas-faq',
    templateUrl: './quotas-faq.component.html',
    styleUrls: ['./quotas-faq.component.scss']
})
export class QuotasFaqComponent {
    public items = [
        {
            title: '¿Cómo ofrezco las cuotas TUU a mis clientes?',
            text: 'Las Cuotas TUU estarán disponibles de forma automática en tu dispositivo y se mostrarán al momento de recibir un pago con tarjeta de crédito. Excepto en el caso que tu cliente tenga una promoción de su banco más conveniente que Cuotas TUU, en este caso no se mostrará la opción de usar Cuotas TUU.'
        }, {
            title: '¿Qué pasa si el banco de mi cliente tiene alguna promoción mejor?',
            text: 'Cuando tu cliente use su tarjeta revisaremos en tiempo real si tiene una promoción disponible por su banco con interés más bajo que el de Cuotas TUU. Si el interés de su banco es más bajo, la opción de pagar con Cuotas TUU se desactivará.'
        }, {
            title: '¿Tiene algún costo para mi negocio?'
        }, {
            title: '¿Cómo beneficia esto a mi negocio?',
            text: 'Dar mejores facilidades de pago a tus clientes es un incentivo para concretar sus compras e incluso estén dispuesto a hacer compras por montos más grandes.'
        }
    ];

    public activePanel = -1;

    public openPanel(index): void {
        setTimeout(() => {
            this.activePanel = index;
        }, 0);

    }

}
