import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { traceButton2 } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-section-try-reservation',
    templateUrl: './section-try-reservation.component.html',
    styleUrls: ['./section-try-reservation.component.scss'],
})
export class SectionTryReservationComponent {

    constructor(
        private router: Router
    ) { }

    public navigateTo() {
        this.router.navigate(['seleccion-producto'], {
            queryParams: {
                subproduct: 'TUUP',
                purchaseOrigin: 'reserva'
            }
        })
    }
    public track(event: Event){
        traceButton2(event, 'navigate');
    }
}
