import { Component, Input, OnInit } from '@angular/core';
import { traceButton2 } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-section-certification',
    templateUrl: './section-certification.component.html',
    styleUrls: ['./section-certification.component.scss']
})
export class SectionCertificationComponent implements OnInit {
    @Input() public loadingImages: boolean;

    constructor() { }

    ngOnInit(): void {
    }

    goToUrl(url: string, event:Event): void {
        window.open(url, '_blank');
        traceButton2(event, 'navigate');
    }

}
