import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-section-faq',
    templateUrl: './section-faq.component.html',
    styleUrls: ['./section-faq.component.scss']
})
export class SectionFaqComponent implements OnInit {
    @Input() items;
    @Input() title = 'Todo <b><i>lo que TUU necesitas</i></b> saber';
    @Input() isSuptitle = true;

    public activePanel = -1;

    constructor() { }

    ngOnInit(): void {
    }

    public openPanel(index): void {
        setTimeout(() => {
            this.activePanel = index;
        }, 0);

    }

}
