import { Component, Input, OnInit } from '@angular/core';
import { traceButton2 } from '../../../../../common/services/button-trace';
import { Router } from '@angular/router';
import { urlMapping } from '../route-ids';

@Component({
    selector: 'app-section-support',
    templateUrl: './section-support.component.html',
    styleUrls: ['./section-support.component.scss']
})
export class SectionSupportComponent implements OnInit {
    @Input() public loadingImages: boolean;
    @Input() public data: any;

    public url:string;

    defaultData = [
        {
            id: '',
            url: 'https://calendly.com/agenda-haulmer/'
        }, {
            id: '',
            url: '/contactar-con-soporte'
        }
    ];

    constructor(
        private _router: Router
    ) {
        this.data = this.defaultData;
    }

    ngOnInit(): void {
        const urlTree = this._router.parseUrl(this._router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';;
        this.url = urlMapping[urlSegment] || urlSegment;
        this.defaultData[0].id = 't-'+this.url+'-capacitacion';
        this.defaultData[1].id = 't-'+this.url+'-soporte';
    }

    goToUrl(url: string, event: Event): void {
        window.open(url, '_blank');
        traceButton2(event, 'navigate');
    }

    public onClick(event: Event): void {
        traceButton2(event, 'navigate');
    }
}
