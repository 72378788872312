import { Component } from '@angular/core';

@Component({
    selector: 'app-section-more-convenient',
    templateUrl: './section-more-convenient.component.html',
    styleUrls: ['./section-more-convenient.component.scss']
})
export class SectionMoreConvenientComponent {

}
