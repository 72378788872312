import { Component, ElementRef } from '@angular/core';

import { ScrollToClass } from '../../shared/scrollTo.class';

@Component({
    selector: 'app-how-works-parking',
    templateUrl: './how-works-parking.component.html',
    styleUrls: ['./how-works-parking.component.scss']
})
export class HowWorksParkingComponent extends ScrollToClass {

    public list = [
        {
            icon: 'icon-sprite-credit-card-search',
            text: 'Ingresa el número de patente y selecciona el tipo de vehículo: auto, moto o camión.'
        },
        {
            icon: 'icon-sprite-paint-brush',
            text: 'Registra información adicional como marca o color.'
        },
        {
            icon: 'icon-sprite-file-check',
            text: 'Imprime el ticket para su seguimiento.'
        },
        {
            icon: 'icon-sprite-qrcode-scan',
            text: 'Escanéa el voucher a la salida del vehículo, acepta el pago y ¡Listo!'
        }
    ];

    constructor(
        private _el: ElementRef
    ) {
        super(_el);
    }

    public navigateToSection(className: string, event: Event): void {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 500);
    }


}
