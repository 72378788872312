import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { DATA_PRICE_ASSOCIATION } from '../../shared/section-price/data-price';
import { ScrollToClass } from '../../shared/scrollTo.class';
import { traceButton2 } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-section-buy-today',
    templateUrl: './section-buy-today.component.html',
    styleUrls: ['./section-buy-today.component.scss']
})
export class SectionBuyTodayComponent extends ScrollToClass implements OnInit {
    @Input() public loadingImages: boolean;
    public DATA_PRICE_ASSOCIATION = DATA_PRICE_ASSOCIATION;
    constructor(private _el: ElementRef) {
        super(_el);
    }

    ngOnInit(): void {
    }

    public navigateToSection(className: string, event:Event): void {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 500);
        traceButton2(event,'scroll');
    }

}
