import { Component, ElementRef } from '@angular/core';

import { ScrollToClass } from '../../shared/scrollTo.class';

@Component({
    selector: 'app-quotas-first-screen',
    templateUrl: './quotas-first-screen.component.html',
    styleUrls: ['./quotas-first-screen.component.scss']
})
export class QuotasFirstScreenComponent extends ScrollToClass {

    constructor(private _el: ElementRef) {
        super(_el);
    }

    public navigateToSection(className: string): void {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 500);
    }

}
