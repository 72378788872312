import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

import { urlEnv } from '../../environments/environment';
import { MetaService } from '@ngx-meta/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-reservation-plans-page',
    templateUrl: './reservation-plans-page.component.html',
    styleUrls: ['./reservation-plans-page.component.scss']
})
export class ReservationPlansPageComponent implements OnInit {

    public urlEnv = urlEnv;

    constructor(
        private readonly _meta: MetaService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
    }

    ngOnInit(): void {
        this._setMeta();

        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 500);
    }

    private _setMeta() {
        this._meta.setTitle('Planes TUU Reserva: Contrata tu agenda online');
        this._meta.setTag('description', 'Elige el plan TUU Reserva que más se acomode a tus necesidades para agendar online mediante sistema de reserva online');
        this._meta.setTag('keywords', 'Planes tuu reserva, agendas, agenda online, sistema de reserva, sistema de agendamiento, sistema de reservas online, software de gestión, software reservas, agenda para empresas, calendario en línea, agendar citas online, agenda online, agenda para pacientes, sistema de agenda, software de citas, agenda médica online');
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-plans-reservation.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Planes TUU Reserva: Contrata tu agenda online');
    }

}
