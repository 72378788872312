import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MetaService } from '@ngx-meta/core';
import { urlEnv } from '../../environments/environment';
import { SEOService } from '../shared/services/seo.service';

@Component({
    selector: 'app-pos-page',
    templateUrl: './pos-page.component.html',
    styleUrls: ['./pos-page.component.scss']
})
export class PosPageComponent implements OnInit {

    public showImage1 = false;
    public showImage2 = false;
    public showImage3 = false;

    public urlEnv = urlEnv;

    public dataPriceSection = {
        buttons: [
            {
                id: 'TUU-punto-precios-compra-simple'
            },
            {
                id: 'TUU-punto-precios-compra-con-complementos'
            }
        ]
    };

    public itemsFAQ = [
        {
            title: '¿Hasta dónde puedo controlar mi Punto de venta?',
            text: 'No hay límites para tu control. Con TUU puedes clasificar y personalizar a tu preferencia tanto sucursales como bodegas, mantener el registro de tu stock al día, todo desde una interfaz de fácil usabilidad que conecta todas las sucursales en las que tengas un dispositivo funcionando.'
        }, {
            title: '¿Qué diferencia a TUU de otros servicios de Punto de venta?',
            text: 'Nuestra máquina POS las hace todas. Desde incorporar una impresora térmica de alta velocidad hasta contar con tecnologías inteligentes capaces de gestionar la totalidad de tu stock, ya sea positivo o negativo. Además, la fluidez y precisión del diseño de su pantalla táctil hará que te lleves una grata sorpresa, y, por sobretodo, que disfrutes la experiencia.'
        }, {
            title: '¿En qué me ayuda un Punto de venta como el de TUU?',
            text: '¡En todo! Un Punto de venta como el nuestro te ayudará a crecer de manera exponencial sin duda alguna, ya que controlando el detalle de tus ventas diarias y comparándolas con tus niveles previos sabrás como tomar las mejores decisiones.'
        }
    ];

    public dataSupport = [
        {
            id: 't-pdv-capacitacion',
            url: 'https://help.haulmer.com/meetings'

        }, {
            id: 't-pdv-soporte',
            url: '/contactar-con-soporte'
        }
    ];

    constructor(
        private readonly _meta: MetaService,
        @Inject(PLATFORM_ID) private platformId: any,
        private seoService: SEOService
    ) { }

    ngOnInit(): void {
        this._setMeta();
        this.seoService.updateCanonicalUrl('punto-de-venta/');

        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 500);
    }

    private _setMeta() {
        this._meta.setTitle('TUU, sistema de punto de venta con control de inventario.');
        this._meta.setTag('description', 'Software de punto de venta para TUU negocio. Crea catálogos de productos, mantén control de inventario y emite boletas o facturas electrónicas en cada venta.');
        this._meta.setTag('keywords', 'punto de venta, punto de venta para negocios, sistema de venta, software punto de venta,POS, control de inventario, gestión de inventario, control de stock, boleta electrónica, factura electrónica.');
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-pos.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'TUU, sistema de punto de venta con control de inventario.');
    }

}
