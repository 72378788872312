import { Component, ElementRef, Input } from '@angular/core';
import { ScrollToClass } from '../../shared/scrollTo.class';
import { traceButton2 } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-reservation-benefits',
    templateUrl: './reservation-benefits.component.html',
    styleUrls: ['./reservation-benefits.component.scss'],
})
export class ReservationBenefitsComponent extends ScrollToClass {
    @Input() public showImages: boolean;

    public items = [
        {
            icon: 'icon-sprite-calendar-today',
            title: 'Agenda Online',
            text: 'Crea tu propia web con catálogo de servicios para agendamiento.',
            image: 'agenda-online'
        }, {
            icon: 'icon-sprite-users',
            title: 'Gestiona tus clientes',
            text: 'Perfil de clientes con historial de citas, montos pagados y más detalles.',
            image: 'gestiona-tus-clientes'
        }, {
            icon: 'icon-sprite-card-circle',
            title: 'Recibe pagos online y presencial',
            text: 'Cobra online en tu web de reserva, presencial con nuestras máquinas POS o combina ambos métodos',
            image: 'recibe-pagos-online-presencial'
        }, {
            icon: 'icon-sprite-file-check',
            title: 'Reserva en APP',
            text: 'Se puede ver horas agendadas y revisar datos de la agenda. Además de recibir el pago de la agenda y ver el historial de la misma.',
            image: 'reserva-en-app'
        }, {
            icon: 'icon-sprite-envelopes',
            title: 'Disminuye inasistencias',
            text: 'Envía recordatorios de cita automáticos para confirmar reservas y disminuir inasistencias.',
            image: 'disminuye-inasistencias'
        }, {
            icon: 'icon-sprite-file',
            title: 'Emite boletas',
            text: 'De tus cobros y serán sincronizadas con el SII, sin costos adicionales.',
            image: 'emite-boletas-automatico'
        }, {
            icon: 'icon-shopping-cart',
            title: 'Inventario y Catálogo',
            text: 'El negocio puede asociar venta de productos a la reserva de los clientes y administrar el stock de los mismos.',
            image: 'inventario-y-catalogo'
        }
    ];

    constructor(private _el: ElementRef) {
        super(_el)
    }

    public track(event: Event, type: string){
        traceButton2(event, type);
    }

}
