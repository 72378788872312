import { ComponentFactoryResolver, ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ModalMenuService {
    public componentRef!: ComponentRef<any>;
    private componentSubscriber!: Subject<string>;

    constructor(private resolver: ComponentFactoryResolver) { }

    openModal(entry: ViewContainerRef, component: Type<any>): Observable<any> {
        entry.clear();
        const factory = this.resolver.resolveComponentFactory(component);
        this.componentRef = entry.createComponent(factory);
        this.componentSubscriber = new Subject<string>();

        return this.componentSubscriber.asObservable();
    }

    closeModal(entry?: ViewContainerRef) {
        if (entry) entry.clear();
        this.componentSubscriber.complete();
        this.componentRef.destroy();
    }

}
