import { Component, OnInit, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-see-product3',
    templateUrl: './dialog-see-product3.component.html',
    styleUrls: ['./dialog-see-product3.component.scss']
})
export class DialogSeeProduct3Component implements OnInit {
    @HostBinding('class') classList = 'default';

    optionsList = [
        {
            text: 'Sunmi OS basado en Android 11 Go',
            icon: 'icon-sprite-android2'
        }, {
            text: 'Conexión Wifi 2.4G/5G y <br> Soporte Bluetooth 4.0',
            icon: 'icon-sprite-wifi'
        }, {
            text: 'Impresora térmica 58 mm, velocidad de impresión 70 mm/s',
            icon: 'icon-sprite-print'
        }, {
            text: 'Pantalla 5” FW 960X480',
            icon: 'icon-sprite-screen'
        }, {
            text: 'Soporta rollos de papel de <br> 58mm de ancho',
            icon: 'icon-sprite-rolls'
        }, {
            text: 'Batería de litio 7,2V/2600 mAh',
            icon: 'icon-sprite-buttery'
        }, {
            text: 'Garantía de 2 años',
            icon: 'icon-sprite-guarantee'
        }
    ];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        if (!!this.data) {
            this.classList = this.data.name;
        }
    }
}
