import { ChangeDetectorRef, Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IPopUpData } from '../../../../../common/interfaces/pop-up.interface';
import { MatTooltip } from '@angular/material/tooltip';
@Component({
    selector: 'app-pop-up-promo-traffic',
    templateUrl: './pop-up.component.TUU.html',
    styleUrls: ['./pop-up.component.TUU.scss']
})
export class PopUpExterno implements OnInit {
    @ViewChild('tooltipCopy') public tooltipCopy: MatTooltip;
    private activeCaptcha = false;
    private captcha: any;
    public countdown: string;
    public originalTimer: string;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IPopUpData,
        @Inject(PLATFORM_ID) private platformId: any,
        private _http: HttpClient,
        private snackBar: MatSnackBar,
        private dialog: MatDialogRef<PopUpExterno>,
        private cdRef: ChangeDetectorRef,
        private clipboard: Clipboard,
    ) {

    }

    @HostListener('window:resize', ['$event'])
    onResize() { }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.promoTraffic();
        }
    }

    private ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    private promoTraffic() {
        let totalSeconds = Number(this.data.body.expireTime);
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = totalSeconds % 60;
        this.countdown = `${minutes}:${seconds.toString().padStart(2, '0')}`;
        this.originalTimer = this.countdown;

        const interval = setInterval(() => {
            seconds--;
            if (seconds < 0) {
                seconds = 59;
                minutes--;
                if (minutes < 0) {
                    clearInterval(interval);
                    return;
                }
            }
            this.countdown = `${minutes}:${seconds.toString().padStart(2, '0')}`;
        }, 1000);
    }

    public registerPromo(version: string): void {
        switch (version) {
            case 'version1':
                sessionStorage.setItem('prCode', String(this.data.body.promoCode));
                this.clipboard.copy(String(this.data.body.promoCode));
                break;
            case 'version2':
                sessionStorage.setItem('prCode', String(this.data.body.promoCode.join(',')));
                break;
            default:
                break;
        }
        this.dialog.close();
    }
}
