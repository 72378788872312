import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
@Component({
    selector: 'ap-half-and-half',
    templateUrl: './half-and-half.component.html',
    styleUrls: ['./half-and-half.component.scss']
})
export class HalfAndHalfComponent implements OnInit {

    public rewardCode: string;
    public isMobile: boolean = false;
    public isNeededBackButton: boolean = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private _router: Router
    ) {
        if (isPlatformBrowser(platformId)) {
            if (sessionStorage.getItem('fromdevice')) {
                this.isNeededBackButton = true;
            }
        }
    }

    @HostListener('window:resize')
    onResize() {
        this.isMobile = window.innerWidth < 767
    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.isMobile = window.innerWidth < 767;
            this.rewardCode = sessionStorage.getItem('code');
        }
    }

    public exit(): void {
        this._router.navigate(['/codigo-amigo/salir/'])
    }

    public back(): void {
        this._router.navigate(['codigo-amigo']);
    }
}
