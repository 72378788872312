import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../../../environments/environment';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class ApiService {

    constructor(private http: HttpClient) {
    }

    public sendFormSomosTUU(body) {
        const url = API.SEND_FORM_SOMOS_TUU;
        return this.http.post(url, body);
    }

    public getGiftCode(rut: string) {
        const url = API.GIFT_CODE_TUU + rut;
        return this.http.get(url, { responseType: 'json' });
    }

    public getStatusSim(body): Observable<any> {
        const url = API.API_STATUS_SIM;
        return this.http.post(url, body);
    }

    public getStatusJira(body): Observable<any> {
        const url = API.API_JIRA_BOARD;
        return this.http.post(url, body);
    }

    public getPromoActivation(): Observable<any> {
        const url = API.API_PROMO_ACTIVATION;
        return this.http.get(url);
    }

    public sendContactFormTUU(body): Observable<any> {
        const url = API.SEND_CONTACT_FORM_TUU;
        const headers = new HttpHeaders({
            "Content-Type": "text/plain;charset=utf-8"
          });
        return this.http.post(url, JSON.stringify(body), { headers });
    }

    public sendContactFormTUU_Partners(body): Observable<any> {
        const url = API.SEND_CONTACT_FORM_TUU_PARTNERS;
        const headers = new HttpHeaders({
            "Content-Type": "text/plain;charset=utf-8"
          });
        return this.http.post(url, JSON.stringify(body), { headers });
    }
}
