export const DATA_FOOTER = {
    dataFooterTuu: [
        {
            id1: '',
            id2: 'footer-productos-pago',
            link: '/pago',
            text: 'TUU Pago'
        },
        {
            id1: '',
            id2: 'footer-productos-reserva',
            link: '/tuu-reserva',
            text: 'TUU Reserva'
        },
        {
            id1: '',
            id2: 'footer-productos-boleta',
            link: '/boleta-electronica',
            text: 'TUU Boleta'
        },
        {
            id1: '',
            id2: 'footer-productos-inventario',
            link: '/inventario',
            text: 'TUU Inventario'
        },
        {
            id1: '',
            id2: 'footer-productos-pdv',
            link: '/punto-de-venta',
            text: 'TUU Punto de venta'
        },
        {
            id1: '',
            id2: 'footer-productos-catalogo',
            link: '/catalogo',
            text: 'TUU Catálogo'
        }
    ],
    // dataFooterService : [
    //     {
    //         link: '',
    //         text: 'TUU Emprende (PRONTO)',
    //         disable: 'disable'
    //     },
    //     {
    //         link: '',
    //         text: 'TUU Contador (PRONTO)',
    //         disable: 'disable'
    //     }
    // ],
    dataFooterProgram: [
        {
            id1: '',
            id2: 'footer-productos-revendedores',
            link: '/revendedores',
            text: 'TUU Revendedores'
        },
        {
            id1: '',
            id2: 'footer-productos-partners',
            link: '/partner',
            text: 'TUU Partner'
        }
    ],
    dataFooterSupport: [
        {
            id1: '',
            id2: 'footer-soporte-documentacion',
            link: 'https://help.tuu.cl/',
            text: 'Documentación'
        },
        {
            id1: '',
            id2: 'footer-soporte-videotutoriales',
            link: 'https://www.youtube.com/playlist?list=PLJm1NCE-HYjuBuzi-mmmbHLXJrIycw2Gx',
            text: 'Videos Tutoriales'
        },
        {
            id1: '',
            id2: 'footer-soporte-soporte',
            link: '/contactar-con-soporte',
            text: 'Contactar con soporte'
        },
        {
            id1: '',
            id2: 'footer-soporte-ventas',
            link: '/contactar-con-ventas',
            text: 'Contactar con ventas'
        }
    ]
};
