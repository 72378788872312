import { AfterViewInit, Component, OnInit, Input, Renderer2, ViewChild } from '@angular/core';
import { panelStatus } from '../seguimiento-common/panels.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { distinctUntilChanged } from 'rxjs/operators';
@Component({
    selector: 'app-seguimiento-hes-page',
    templateUrl: './seguimiento-hes.component.html',
    styleUrls: ['../seguimiento-common/seguimiento-styles.component.scss']
})

export class SeguimientoHesPageComponent implements OnInit, AfterViewInit {
    @Input() public cardMap: any;
    @ViewChild('stepper', { static: false }) private matstep: any;
    matMenu$ = this.paneStat.matMenu$;
    readonly breakpoint$ = this.breakpointObserver
        .observe(['(min-width: 760px)'])
        .pipe(distinctUntilChanged())
    constructor(
        private paneStat: panelStatus,
        private renderer: Renderer2,
        private breakpointObserver: BreakpointObserver) { }
    public ngOnInit(): void {
        this.timeManage();
    }
    public ngAfterViewInit(): void {
        this.breakpoint$.subscribe((breakpoint) => {
            this.setStyles();
        })
    }
    public getPanel(): boolean {
        return this.paneStat.getValue('isOpenMat1');
    }
    public mngPanel() {
        this.paneStat.managePanel('isOpenMat1');
    }
    private timeManage() {
        for (let index = 0; index < this.cardMap.cardList.length; index++) {
            if (this.cardMap.cardList[index].lastUpdate != undefined) this.cardMap.cardList[index].lastUpdate = new Date(this.cardMap.cardList[index].lastUpdate).toLocaleString();
        }
    }
    private setStyles() {
        for (let index = 0; index < this.cardMap.cardList.length; index++) {
            if (index < this.cardMap.activeIndex) this.renderer.setProperty(this.matstep._steps._results[index], 'completed', true);
            this.renderer.setAttribute(this.matstep._elementRef.nativeElement.children[index].children[0].children[2].children[0].children[0].children[0].children[0], "class", this.cardMap.cardList[index].elipse)
            this.renderer.addClass(this.matstep._elementRef.nativeElement.children[index], this.cardMap.cardList[index].className);
        }
        if (this.cardMap.activeIndex < this.cardMap.cardList.length) {
            for (let index = this.cardMap.activeIndex; index < this.cardMap.cardList.length; index++) {
                this.renderer.setProperty(this.matstep._steps._results[this.cardMap.activeIndex], 'completed', false);
                if (index > this.cardMap.activeIndex) this.renderer.setAttribute(this.matstep._elementRef.nativeElement.children[index].children[0].children[2].children[0].children[0].children[0].children[0], "class", "elipse-disabled")
            }
        }
        this.renderer.setProperty(this.matstep, '_selectedIndex', this.cardMap.activeIndex);
    }
}
