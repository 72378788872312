import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-section-inventory-benefits',
    templateUrl: './section-inventory-benefits.component.html',
    styleUrls: ['./section-inventory-benefits.component.scss']
})
export class SectionInventoryBenefitsComponent implements OnInit {
    @Input() public loadingImages: boolean;

    list = [
        {
            icon: 'icon-sprite-package',
            size: 40,
            text: '<b>Gestiona varias sucursales</b> transfiriendo stock entre ellos y generando informes detallados.'
        },
        {
            icon: 'icon-sprite-refresh-cw',
            size: 40,
            text: '<b>Actualiza las cantidades en stock de tus sucursales</b> automáticamente cada vez que vendas algo.'
        },
        {
            icon: 'icon-sprite-bookmark',
            size: 38,
            text: '<b>Haz seguimiento de productos o lotes</b> de inventario y ten el cotrol sobre estos.'
        },
        {
            icon: 'icon-sprite-trending-down',
            size: 40,
            text: 'Gestiona el stock de productos en tus <b>sucursales y bodegas ilimitadas.</b>'
        }
    ];

    constructor() { }

    ngOnInit(): void {
    }

}
