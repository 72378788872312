import { Component, ElementRef } from '@angular/core';

import { ScrollToClass } from '../../shared/scrollTo.class';

@Component({
    selector: 'app-first-screen-parking',
    templateUrl: './first-screen-parking.component.html',
    styleUrls: ['./first-screen-parking.component.scss']
})
export class FirstScreenParkingComponent extends ScrollToClass {

    constructor(
        private _el: ElementRef
    ) {
        super(_el);
    }

    public navigateToSection(className: string, event: Event): void {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 500);
    }

}
