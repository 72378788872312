import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class DataService {

    idPlan$ = new EventEmitter<number>();

    isHardwareSelected$ = new EventEmitter<boolean>();

    constructor() { }

}
