export const DATA_PRICE_ASSOCIATION = {
    "mini": {
        price: 59900,
        old_price: 59900,
        show_new_price: false,
        installmentPrice: 19967,
    },
    "mini-s": {
        price: 79900,
        old_price: 79900,
        show_new_price: false,
        installmentPrice: 26633,
    },
    "se": {
        price: 79900,
        old_price: 79900,
        show_new_price: false,
        installmentPrice: 26633,
    },
    // "pro": {
    //     price: 99900,
    //     old_price: 99900,
    //     show_new_price: false,
    //     installmentPrice: 33300,
    // },
    "pro-semi-nueva": {
        price: 59940,
        old_price: 59940,
        show_new_price: false,
        installmentPrice: 59940,
    },
    "se-semi-nueva": {
        price: 47940,
        old_price: 47940,
        show_new_price: false,
        installmentPrice: 10
    },
    "mini-semi-nueva": {
        price: 17940,
        old_price: 17940,
        show_new_price: false,
        installmentPrice: 20
    },
    "mini-s-semi-nueva": {
        price: 29940,
        old_price: 29940,
        show_new_price: false,
        installmentPrice: 30
    }

}

export const DATA_CARD_PRICE = [
    {
        device: 'device-p2-mini',
        features: [
            {
                icon: 'icon-chip-tuu',
                name: 'Chip de <br>Internet',
                width: 36,
                height:27
            }
        ],
        title: 'Mini',
        price: DATA_PRICE_ASSOCIATION['mini'].price,
        old_price: DATA_PRICE_ASSOCIATION['mini'].old_price,
        show_new_price: DATA_PRICE_ASSOCIATION['mini'].show_new_price,
        installmentPrice: DATA_PRICE_ASSOCIATION['mini'].installmentPrice,
        button: {
            id: 'precios-comprar-mini',
            text: 'Comprar',
            path: '/comprar-mini'
        },
        list: [
            {
                icon: 'icon-sprite-card-atm',
                text: '<b>Acepta tarjetas</b> con banda</br> magnética, chip y sin contacto.'
            },
            {
                icon: 'icon-sprite-file',
                text: '<b>Recibe pago con tarjetas</b> y emite<br/> voucher válido como boleta<br/> electrónica.'
            },
            {
                icon: 'icon-sprite-attach-money',
                text: '<b>Recibe pago con efectivo</b> y emite boleta o factura electrónica desde tu POS o espacio de trabajo.',
                /*tooltip: {
                    text: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
                }*/
            },
            {
                icon: 'icon-sprite-sim-card',
                text: '<b>Chip de Internet</b> con gigas libres.'
            },
            {
                icon: 'icon-sprite-battery',
                text: '<b>Batería</b> de larga duración.'
            }, {
                icon: 'icon-sprite-android-phone-slash',
                text: '<b>No necesitas</b> un celular.'
            }
        ]
    },
    {
        device: 'device-p2-mini-s',
        features: [
            {
                icon: 'icon-scan',
                name: 'Escaner <br>Integrado',
                width: 33,
                height:48
            },
            {
                icon: 'icon-chip-tuu',
                name: 'Chip de <br>Internet',
                width: 36,
                height:27
            }
        ],
        title: 'Mini S',
        price: DATA_PRICE_ASSOCIATION['mini-s'].price,
        old_price: DATA_PRICE_ASSOCIATION['mini-s'].old_price,
        show_new_price: DATA_PRICE_ASSOCIATION['mini-s'].show_new_price,
        installmentPrice: DATA_PRICE_ASSOCIATION['mini-s'].installmentPrice,
        button: {
            id: 'precios-comprar-mini-s',
            text: 'Comprar',
            path: '/comprar-mini-s'
        },
        list: [
            {
                icon: 'icon-sprite-card-atm',
                text: '<b>Acepta tarjetas</b> con banda</br> magnética, chip y sin contacto.'
            },
            {
                icon: 'icon-sprite-file',
                text: '<b>Recibe pago con tarjetas</b> y emite<br/> voucher válido como boleta<br/> electrónica.'
            },
            {
                icon: 'icon-sprite-attach-money',
                text: '<b>Recibe pago con efectivo</b> y emite boleta o factura electrónica desde tu POS o espacio de trabajo.',
                /*tooltip: {
                    text: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
                }*/
            },
            {
                icon: 'icon-sprite-scan',
                text: '<b>Escáner profesional</b> integrado.'
            },
            {
                icon: 'icon-sprite-sim-card',
                text: '<b>Chip de internet</b> con gigas libres.'
            },
            {
                icon: 'icon-sprite-battery',
                text: '<b>Batería</b> de larga duración.'
            }, {
                icon: 'icon-sprite-android-phone-slash',
                text: '<b>No necesitas</b> un celular.'
            }
        ]
    },
    {
        device: 'device-sunmi-se',
        features: [
            {
                icon: 'icon-roll-tuu',
                name: 'Impresora<br>térmica',
                width: 35,
                height:36
            },
            {
                icon: 'icon-chip-tuu',
                name: 'Chip de <br>Internet',
                width: 36,
                height:27
            }
        ],
        title: 'SE',
        price: DATA_PRICE_ASSOCIATION['se'].price,
        old_price: DATA_PRICE_ASSOCIATION['se'].old_price,
        show_new_price: DATA_PRICE_ASSOCIATION['se'].show_new_price,
        installmentPrice: DATA_PRICE_ASSOCIATION['se'].installmentPrice,
        button: {
            id: 'precios-comprar-se',
            text: 'Comprar',
            path: '/comprar-se'
        },
        list: [
            {
                icon: 'icon-sprite-card-atm',
                text: '<b>Acepta tarjetas</b> con banda</br> magnética, chip y sin contacto.'
            },
            {
                icon: 'icon-sprite-file',
                text: '<b>Recibe pago con tarjetas</b> y emite<br/> voucher válido como boleta<br/> electrónica.'
            },
            {
                icon: 'icon-sprite-attach-money',
                text: '<b>Recibe pago con efectivo</b> y emite<br/> boleta o factura electrónica desde tu POS o espacio de trabajo.',
                /*tooltip: {
                    text: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
                }*/
            },
            {
                icon: 'icon-sprite-printer',
                text: '<b>Impresora</b> térmica integrada.'
            },
            {
                icon: 'icon-sprite-sim-card',
                text: '<b>Chip de Internet</b> con gigas libres.'
            },
            {
                icon: 'icon-sprite-battery',
                text: '<b>Batería</b> de larga duración.'
            }, {
                icon: 'icon-sprite-android-phone-slash',
                text: '<b>No necesitas</b> un celular.'
            }, {
                icon: 'icon-sprite-camera',
                text: 'Cámara de <b>0,3 megapixeles</b>'
            }, {
                icon: 'icon-sprite-screen',
                text: '<b>Pantalla táctil</b> de 5”',
                tooltip: {
                    text: 'Pantalla Táctil 5" FW+, Resolución: 960x480px',
                    style: 'w-196'
                }
            }
        ]
    },
    // {
    //     device: 'device-sunmi-pro',
    //     features: [
    //         {
    //             icon: 'icon-roll-tuu',
    //             name: 'Impresora<br>térmica'
    //         },
    //         {
    //             icon: 'icon-chip-tuu',
    //             name: 'Chip de <br>Internet'
    //         }
    //     ],
    //     title: 'Pro',
    //     price: DATA_PRICE_ASSOCIATION['pro'].price,
    //     old_price: DATA_PRICE_ASSOCIATION['pro'].old_price,
    //     show_new_price: DATA_PRICE_ASSOCIATION['pro'].show_new_price,
    //     installmentPrice: DATA_PRICE_ASSOCIATION['pro'].installmentPrice,
    //     button: {
    //         id: 'TUU-pro-H-precios-compra-simple',
    //         text: 'Comprar',
    //         path: '/comprar-pro',
    //         isDisabled: false
    //     },
    //     list: [
    //         {
    //             icon: 'icon-sprite-card-atm',
    //             text: '<b>Acepta tarjetas</b> con banda</br> magnética, chip y sin contacto.'
    //         },
    //         {
    //             icon: 'icon-sprite-file',
    //             text: '<b>Recibe pago con tarjetas</b> y emite<br/> voucher válido como boleta<br/> electrónica.'
    //         },
    //         {
    //             icon: 'icon-sprite-attach-money',
    //             text: '<b>Recibe pago con efectivo</b> y emite<br/> boleta o factura electrónica.',
    //             tooltip: {
    //                 text: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
    //             }
    //         },
    //         {
    //             icon: 'icon-sprite-printer',
    //             text: '<b>Impresora</b> térmica integrada.'
    //         },
    //         {
    //             icon: 'icon-sprite-sim-card',
    //             text: '<b>Chip de Internet</b> con gigas libres.'
    //         },
    //         {
    //             icon: 'icon-sprite-battery',
    //             text: '<b>Batería</b> de larga duración.'
    //         }, {
    //             icon: 'icon-sprite-android-phone-slash',
    //             text: '<b>No necesitas</b> un celular.'
    //         }, {
    //             icon: 'icon-sprite-camera',
    //             text: 'Cámara de <b>5 megapixeles</b>'
    //         }, {
    //             icon: 'icon-sprite-screen',
    //             text: '<b>Pantalla táctil</b> de 5,5”',
    //             tooltip: {
    //                 text: 'Pantalla Táctil 5.5" HD, Resolución: 1440x720px',
    //             }
    //         }
    //     ]
    // }
]
