import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { traceButton2 } from '../../../../../common/services/button-trace';
import { ScrollToClass } from '../../shared/scrollTo.class';

@Component({
    selector: 'app-payment-call-to-action',
    templateUrl: './payment-call-to-action.component.html',
    styleUrls: ['./payment-call-to-action.component.scss']
})
export class PaymentCallToActionComponent extends ScrollToClass implements OnInit {
    @Input() public loadingImages: boolean;

    constructor(
        private _el: ElementRef
    ) {
        super(_el);
    }

    ngOnInit(): void {
    }

    public navigateToSection(className: string, event: Event): void {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 500);
        traceButton2(event, 'scroll')
    }

}
