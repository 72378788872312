import { SafeHtml } from '@angular/platform-browser';

export const RESPONSE_TABLE_CERT = [
    {
        Caso: 'Tarea por Hacer',
        Avance: '0',
        Estados: 'Tarea por Hacer',
        Mensaje: 'La firma electrónica del representante legal del comercio ha sido cargada exitosamente. <br>Dentro de los próximos minutos se iniciará proceso automático para verificar información asociada y configuraciones requeridas.',
        MensajeSecundario: `<a href="https://help.tuu.cl/">
                                Centro de Ayuda TUU
                            </a>`
    },
    {
        Caso: 'Prueba de Configuración',
        Avance: '1',
        Estados: 'Prueba de Configuración',
        Mensaje: 'Estamos revisando que todo este correctamente configurado para poder continuar',
        MensajeSecundario: `<a href="https://help.tuu.cl/">
                                Centro de Ayuda TUU
                            </a>`
    },
    {
        Caso: 'En Cola',
        Avance: '2',
        Estados: 'En Cola',
        Mensaje: 'Hemos verificado que tanto el comercio como su representante legal cumplen con los requisitos básicos impuestos por el SII. El proceso de certificación de los documentos electrónicos seleccionados iniciará automáticamente dentro de las próximas horas.',
        MensajeSecundario: `<a href="https://help.tuu.cl/">
                                Centro de Ayuda TUU
                            </a>`
    },
    {
        Caso: 'En Progreso: Etapa 1',
        Avance: '3',
        Estados: 'Certificación automática en curso',
        Mensaje: 'Hemos verificado que tanto el comercio como su representante legal cumplen con los requisitos básicos impuestos por el SII. <br>El proceso de certificación de los documentos electrónicos seleccionados ha iniciado. Esta etapa puede tardar hasta 1 día hábil.',
        MensajeSecundario: `<a href="https://help.tuu.cl/">
                                Centro de Ayuda TUU
                            </a>`
    },
    {
        Caso: 'En Progreso: Etapa 2',
        Avance: '3',
        Estados: 'Certificación automática en curso',
        Mensaje: 'Hemos verificado que tanto el comercio como su representante legal cumplen con los requisitos básicos impuestos por el SII. <br>El proceso de certificación de los documentos electrónicos seleccionados ha iniciado. Esta etapa puede tardar hasta 1 día hábil.',
        MensajeSecundario: `<a href="https://help.tuu.cl/">
                                Centro de Ayuda TUU
                            </a>`
    },
    {
        Caso: 'En Progreso: Etapa 3',
        Avance: '3',
        Estados: 'Certificación automática en curso',
        Mensaje: 'Hemos verificado que tanto el comercio como su representante legal cumplen con los requisitos básicos impuestos por el SII. <br>El proceso de certificación de los documentos electrónicos seleccionados ha iniciado. Esta etapa puede tardar hasta 1 día hábil.',
        MensajeSecundario: `<a href="https://help.tuu.cl/">
                                Centro de Ayuda TUU
                            </a>`
    },
    {
        Caso: 'En Progreso: Etapa 4',
        Avance: '3',
        Estados: 'Certificación automática en curso',
        Mensaje: 'Hemos verificado que tanto el comercio como su representante legal cumplen con los requisitos básicos impuestos por el SII. <br>El proceso de certificación de los documentos electrónicos seleccionados ha iniciado. Esta etapa puede tardar hasta 1 día hábil.',
        MensajeSecundario: `<a href="https://help.tuu.cl/">
                                Centro de Ayuda TUU
                            </a>`
    },
    {
        Caso: 'En Progreso: Etapa 4 (Upload)',
        Avance: '3',
        Estados: 'Certificación automática en curso',
        Mensaje: 'Hemos verificado que tanto el comercio como su representante legal cumplen con los requisitos básicos impuestos por el SII. <br>El proceso de certificación de los documentos electrónicos seleccionados ha iniciado. Esta etapa puede tardar hasta 1 día hábil.',
        MensajeSecundario: `<a href="https://help.tuu.cl/">
                                Centro de Ayuda TUU
                            </a> `
    },
    {
        Caso: 'ESPERANDO RESPUESTA DEL SII',
        Avance: '4',
        Estados: 'Certificación enviada al SII: Esperando respuesta del SII',
        Mensaje: 'En este momento la certificación de documentos tributarios electrónicos del comercio se encuentra en espera de revisión y aprobación final por parte del SII. Esto puede tardar un máximo de 3 días hábiles.',
        MensajeSecundario: `<a href="https://agenda.haulmer.com/">
                                Capacitación online: Agenda tu hora con un ejecutivo
                            </a>`
    },
    {
        Caso: 'ESPERANDO RESPUESTA SII',
        Avance: '4',
        Estados: 'Certificación enviada al SII: Esperando respuesta del SII',
        Mensaje: 'En este momento la certificación de documentos tributarios electrónicos del comercio se encuentra en espera de revisión y aprobación final por parte del SII. Esto puede tardar un máximo de 3 días hábiles.',
        MensajeSecundario: `<a href="https://agenda.haulmer.com/">
                                Capacitación online: Agenda tu hora con un ejecutivo
                            </a>`
    },
    {
        Caso: 'EN ESPERA DE COMPLETACIÓN',
        Avance: '5',
        Estados: 'Certificación Finalizada: En espera de comienzo de operación',
        Mensaje: '¡Tenemos buenas noticias! La certificación de documentos tributarios electrónicos del comercio ha finalizado. Inicia sesión en <a href="https://espacio.haulmer.com/">espacio de trabajo</a> para comenzar operación.',
        MensajeSecundario: `<ul>
                                <li>
                                    <a href="https://espacio.haulmer.com/">
                                        Espacio de Trabajo
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/watch?v=lyJnKVVrPaU&list=PLu7bvUiAPJCHn_NixQEFscbbhzp3BxoET&index=10">
                                        Video: Comienza operación en tu espacio de trabajo
                                    </a>
                                </li>
                                <li>
                                    <a href="https://agenda.haulmer.com/">
                                        Capacitación online: Agenda tu hora con un ejecutivo
                                    </a>
                                </li>
                            </ul>`
    },
    {
        Caso: 'TERMINADO',
        Avance: '6',
        Estados: 'Certificación Terminada: Comercio se encuentra operando',
        Mensaje: 'Los documentos tributarios electrónicos se han integrado exitosamente. Ya puedes comenzar a emitir documentos electrónicos desde tu plataforma web y aplicaciones.',
        MensajeSecundario: `<ul>
                                <li>
                                    <a href="https://espacio.haulmer.com/">
                                        Espacio de Trabajo
                                    </a>
                                </li>
                                <li>
                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/donde-ubicar-documentos-electr%C3%B3nicos-dentro-del-espacio-de-trabajo/fCHkbv6ckhjJ6djPTeQGWp">
                                        FAQ: ¿Dónde ubicar Documentos electrónicos dentro del Espacio de trabajo?
                                    </a>
                                </li>
                                <li>
                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/%C2%BFc%C3%B3mo-declarar-el-modelo-de-emisi%C3%B3n-de-boletas-en-el-sii/akS1qaL95H37T5yNMy8XFR">
                                        FAQ: ¿Cómo declarar el modelo de emisión de boletas en el SII?
                                    </a>
                                </li>
                                <li>
                                    <a href="https://agenda.haulmer.com/">
                                        Capacitación online: Agenda tu hora con un ejecutivo
                                    </a>
                                </li>
                            </ul>`
    },
    {
        Caso: 'CLIENTE: FOLIOS INSUFICIENTES',
        Avance: '1',
        Estados: 'Certificación en Conflicto: Folios Insuficientes SII',
        Mensaje: 'La certificación de documentos tributarios electrónicos del comercio se encuentra en conflicto por falta de folios de prueba. Mientras no soluciones este inconveniente, no podremos continuar con la habilitación del servicio. Una vez solucionado no es necesario que nos notifiques, ya que el proceso se retomará de forma automática. Para mayores detalles, recuerda revisar la información que enviamos a tu correo electrónico y seguir los pasos solicitados.',
        MensajeSecundario: `<ul>
                                <li>
                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/situaciones-pendientes-en-el-sii/sEVG3YDCVPnu9BW3xqYHiu">
                                        FAQ: ¿Cómo solucionar situaciones pendientes en el SII?
                                    </a>
                                </li>
                                <li>
                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/%C2%BFc%C3%B3mo-realizar-una-solicitud-de-folios-en-el-sii/6ZQDvsyeaNkCg87gMYBDqJ">
                                        FAQ: ¿Cómo realizar una solicitud de folios en el SII?
                                    </a>
                                </li>
                            </ul>`
    },
    {
        Caso: 'CLIENTE: VERIFICACIÓN DE ACTIVIDADES',
        Avance: '1',
        Estados: 'Certificación en Conflicto: Verificación de actividades en domicilio SII',
        Mensaje: 'La certificación de documentos tributarios electrónicos del comercio se encuentra en conflicto por falta de verificación de actividades en domicilio. Mientras no soluciones este inconveniente, no podremos continuar con la habilitación del servicio. Una vez solucionado no es necesario que nos notifiques, ya que el proceso se retomará de forma automática. Para mayores detalles, recuerda revisar la información que enviamos a tu correo electrónico y seguir los pasos solicitados.',
        MensajeSecundario: `<ul>
                                <li>
                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/%C2%BFc%C3%B3mo-solicitar-la-verificaci%C3%B3n-de-actividades-de-forma-online/kxyW7zDHHn8LsdwDK69XS7">
                                        FAQ: ¿Cómo solicitar la verificación de actividades de forma online?
                                    </a>
                                </li>
                            </ul>`
    },
    {
        Caso: 'CLIENTE: PENDIENTE F29',
        Avance: '1',
        Estados: 'Certificación en Conflicto: Pendiente formulario F29 o Folios insuficientes SII',
        Mensaje: 'La certificación de documentos tributarios electrónicos del comercio se encuentra en conflicto por errores en declaración mensual de IVA (F29). Mientras no soluciones este inconveniente, no podremos continuar con la habilitación del servicio. Una vez solucionado no es necesario que nos notifiques, ya que el proceso se retomará de forma automática. Para mayores detalles, recuerda revisar la información que enviamos a tu correo electrónico y seguir los pasos solicitados.',
        MensajeSecundario: `<ul>
                                <li>
                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/situaciones-pendientes-en-el-sii/sEVG3YDCVPnu9BW3xqYHiu">
                                        FAQ: ¿Cómo solucionar situaciones pendientes en el SII?
                                    </a>
                                </li>
                                <li>
                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/%C2%BFc%C3%B3mo-realizar-una-solicitud-de-folios-en-el-sii/6ZQDvsyeaNkCg87gMYBDqJ">
                                        FAQ: ¿Cómo realizar una solicitud de folios en el SII?
                                    </a>
                                </li>
                            </ul>`
    },
    {
        Caso: 'CLIENTE: PENDIENTE RIAC',
        Avance: '5',
        Estados: 'Certificación en Conflicto: Pendiente RIAC SII (Rut e Inicio de Actividades)',
        Mensaje: 'El proceso de certificación finalizó correctamente, sin embargo, el SII informa lo siguiente:<br>"Sr. Contribuyente, para proseguir con su solicitud de postulación a la Factura Electrónica, debe solicitar una Verificación de Actividad en el área RIAC (Rut e Inicio de Actividades/ Modificaciones) de la Unidad del SII a la que pertenece. Por favor vuelva a ingresar su postulación una vez que haya efectuado el trámite antes mencionado."<br>Como empresa no tenemos la facultad de intervenir, por lo que te solicitamos que por favor te comuniques con el SII para revisar esta situación. Una vez sea solucionada, debes ingresar a tu Espacio de Trabajo para comenzar a utilizar el servicio.',
        MensajeSecundario: `<a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/situaciones-pendientes-en-el-sii/sEVG3YDCVPnu9BW3xqYHiu">
                                FAQ: ¿Cómo solucionar situaciones pendientes en el SII?
                            </a>`
    },
    {
        Caso: 'CLIENTE: PENDIENTE OTROS',
        Avance: '1',
        Estados: 'Certificación en Conflicto: Otras situaciones Pendientes SII',
        Mensaje: 'La certificación de tu empresa se encuentra en conflicto por situaciones pendientes de la empresa o del representante legal. Mientras no soluciones este inconveniente, no podremos continuar con la habilitación del servicio. Una vez solucionado no es necesario que nos notifiques, ya que el proceso se retomará de forma automática. Para mayores detalles, recuerda revisar la información que enviamos a tu correo electrónico y seguir los pasos solicitados.',
        MensajeSecundario: `<a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/situaciones-pendientes-en-el-sii/sEVG3YDCVPnu9BW3xqYHiu">
                                FAQ: ¿Cómo solucionar situaciones pendientes en el SII?
                            </a>`
    },
    {
        Caso: 'CLIENTE: SITUACIONES PENDIENTES',
        Avance: '1',
        Estados: 'Certificación en Conflicto: Situaciones Pendientes',
        Mensaje: 'La certificación de documentos tributarios electrónicos de tu comercio se encuentra en conflicto por situaciones pendientes de la empresa o del representante legal. Mientras no soluciones este inconveniente, no podremos continuar con la habilitación del servicio.   Una vez solucionado no es necesario que nos notifiques, ya que el proceso se retomará de forma automática.  Para mayores detalles, recuerda revisar la información que enviamos a tu correo electrónico y seguir los pasos solicitados.',
        MensajeSecundario: `<a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/situaciones-pendientes-en-el-sii/sEVG3YDCVPnu9BW3xqYHiu">
                                FAQ: ¿Cómo solucionar situaciones pendientes en el SII?
                            </a>`
    },
    {
        Caso: 'CLIENTE: FIRMA REVOCADA',
        Avance: '1',
        Estados: 'Certificación en Conflicto: Firma Revocada',
        Mensaje: 'El certificado digital (también llamado firma electrónica) del representante legal del comercio cargado al inicio del proceso ha sido revocado. Es necesario renovar certificado y cargar nuevamente para continuar.  Una vez solucionado no es necesario que nos notifiques, ya que el proceso se retomará de forma automática.',
        MensajeSecundario: `<ul>
                                <li>
                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/%C2%BFc%C3%B3mo-emitir-mi-firma-electr%C3%B3nica/5DPQgfWWZTrvJKvFRftdDF">
                                        FAQ: ¿Cómo emitir mi firma electrónica?
                                    </a>
                                </li>
                                <li>
                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/diferencias-entre-firma-avanzada-y-simple/wJBGYmCdwvXndZpUCqJm5s">
                                        FAQ: Diferencias entre firma avanzada y simple
                                    </a>
                                </li>
                                <li>
                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/como-centralizar-firma-electr%C3%B3nica-y-habilitar-la-autenticaci%C3%B3n-en-el-portal-de-sii/qWMJrm2jeKQYw3HxRa1k9R">
                                        FAQ: ¿Cómo centralizar Firma Electrónica y Habilitar la Autenticación en el portal de SII?
                                    </a>
                                </li>
                                <li>
                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/%C2%BFc%C3%B3mo-cargar-o-reemplazar-mi-firma-electr%C3%B3nica-en-documentos-electr%C3%B3nicos/cjmC4uQy82dEdzUcx2jCZo">
                                        FAQ: ¿Cómo cargar o reemplazar mi firma electrónica en Documentos electrónicos?
                                    </a>
                                </li>
                            </ul>`
    },
    {
        Caso: 'CLIENTE: AUTENTICACIÓN NO CONFIGURADA',
        Avance: '1',
        Estados: 'Certificación en Conflicto: Autenticación de firma no configurada en SII',
        Mensaje: 'El certificado digital (también llamado firma electrónica) del representante legal del comercio no ha sido autentificado. Es necesario cargar certificado en plataforma del SII del representsante y habilitar autenticación por medio de dicho documento para continuar.  Una vez solucionado no es necesario que nos notifiques, ya que el proceso se retomará de forma automática.',
        MensajeSecundario: `<ul>
                                <li>
                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/c%C3%B3mo-verificar-el-o-los-representantes-legales-de-una-empresa-en-el-sitio-web-del-sii/jK69uw5ann263XTbZjsfes">
                                        FAQ: ¿Cómo verificar el o los Representante(s) legal(es) de una empresa en el sitio web del SII?
                                    </a>
                                </li>
                                <li>
                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/como-centralizar-firma-electr%C3%B3nica-y-habilitar-la-autenticaci%C3%B3n-en-el-portal-de-sii/qWMJrm2jeKQYw3HxRa1k9R">
                                        FAQ: ¿Cómo centralizar Firma Electrónica y Habilitar la Autenticación en el portal de SII?
                                    </a>
                                </li>
                            </ul>`
    },
    {
        Caso: 'CLIENTE: FIRMA EXPIRADA',
        Avance: '1',
        Estados: 'Certificación en Conflicto: Firma Expirada',
        Mensaje: 'El certificado digital (también llamado firma electrónica) del representante legal del comercio cargado al inicio del proceso ha expirado. Es necesario renovar certificado y cargar nuevamente para continuar.  Una vez solucionado no es necesario que nos notifiques, ya que el proceso se retomará de forma automática.',
        MensajeSecundario: `<ul>
                                <li>
                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/%C2%BFc%C3%B3mo-emitir-mi-firma-electr%C3%B3nica/5DPQgfWWZTrvJKvFRftdDF">
                                        FAQ: ¿Cómo emitir mi firma electrónica?
                                    </a>
                                </li>
                                <li>
                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/diferencias-entre-firma-avanzada-y-simple/wJBGYmCdwvXndZpUCqJm5s">
                                        FAQ: Diferencias entre firma avanzada y simple
                                    </a>
                                </li>
                                <li>
                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/como-centralizar-firma-electr%C3%B3nica-y-habilitar-la-autenticaci%C3%B3n-en-el-portal-de-sii/qWMJrm2jeKQYw3HxRa1k9R">
                                        FAQ: ¿Cómo centralizar Firma Electrónica y Habilitar la Autenticación en el portal de SII?
                                    </a>
                                </li>
                                <li>
                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/%C2%BFc%C3%B3mo-cargar-o-reemplazar-mi-firma-electr%C3%B3nica-en-documentos-electr%C3%B3nicos/cjmC4uQy82dEdzUcx2jCZo">
                                        FAQ: ¿Cómo cargar o reemplazar mi firma electrónica en Documentos electrónicos?
                                    </a>
                                </li>
                            </ul>`
    },
    {
        Caso: 'ABORTADO AUTOMÁTICO',
        Avance: '0',
        Estados: 'Certificación Detenida: Abortado automático',
        Mensaje: 'El proceso de certificación de documentos electrónicos se ha detenido automáticamente puesto que se excedió el máximo de reintentos por más de 34 días. Mientras no soluciones el inconveniente notificado en instancias anteriores, no podremos continuar con la habilitación del servicio. ',
        MensajeSecundario: 'Para reactivar, te pedimos que contactes a soporte +56 2 3210 9063 - Opción 2 (soporte)'
    },
    {
        Caso: 'ABORTADO MANUAL CLIENTE',
        Avance: '0',
        Estados: 'Certificación Detenida: Abortado manual cliente',
        Mensaje: 'El proceso de certificación de documentos electrónicos ha sido cancelado por solicitud del cliente. ',
        MensajeSecundario: 'Para reactivar, te pedimos que contactes a soporte +56 2 3210 9063 - Opción 2 (soporte)'
    },
    {
        Caso: 'ABORTADO MANUAL AGENTE',
        Avance: '0',
        Estados: 'Certificación Detenida: Abortado manual agente',
        Mensaje: 'El proceso de certificación de documentos electrónicos ha sido cancelado por solicitud del cliente. ',
        MensajeSecundario: 'Para reactivar, te pedimos que contactes a soporte +56 2 3210 9063 - Opción 2 (soporte)'
    },
    {
        Caso: 'HABILITACIÓN: ERROR COMUNA',
        Avance: '1',
        Estados: 'Error de configuración: Error Comuna',
        Mensaje: 'La certificación del comercio se encuentra en conflicto debido a un error de configuración interna. Estamos trabajando para solucionarlo lo más rápido posible.',
        MensajeSecundario: '<a href="https://help.tuu.cl/">Centro de Ayuda TUU</a>'
    },
    {
        Caso: 'HABILITACIÓN: ERROR ACTIVIDADES',
        Avance: '1',
        Estados: 'Error de configuración: Error Actividades',
        Mensaje: 'La certificación del comercio se encuentra en conflicto debido a un error de configuración interna. Estamos trabajando para solucionarlo lo más rápido posible.',
        MensajeSecundario: '<a href="https://help.tuu.cl/">Centro de Ayuda TUU</a>'
    },
    {
        Caso: 'HABILITACIÓN: FALLAS TIMBRAJE',
        Avance: '1',
        Estados: 'Error de configuración: Fallas Timbraje',
        Mensaje: 'La certificación del comercio se encuentra en conflicto debido a un error de configuración interna. Estamos trabajando para solucionarlo lo más rápido posible.',
        MensajeSecundario: '<a href="https://help.tuu.cl/">Centro de Ayuda TUU</a>'
    },
    {
        Caso: 'HABILITACIÓN: ERROR INSCRIPCIÓN',
        Avance: '1',
        Estados: 'Error de configuración: Error Inscripción',
        Mensaje: 'La certificación del comercio se encuentra en conflicto debido a un error de configuración interna. Estamos trabajando para solucionarlo lo más rápido posible.',
        MensajeSecundario: '<a href="https://help.tuu.cl/" >Centro de Ayuda TUU</a>'
    },
    {
        Caso: 'HABILITACIÓN: ERROR CONFIGURACIÓN CORREOS',
        Avance: '1',
        Estados: 'Error de configuración: Error configuración Correos',
        Mensaje: 'La certificación del comercio se encuentra en conflicto debido a un error de configuración interna. Estamos trabajando para solucionarlo lo más rápido posible.',
        MensajeSecundario: '<a href="https://help.tuu.cl/">Centro de Ayuda TUU</a>',
    },
    {
        Caso: 'HABILITACIÓN: ERROR PERMISOS SII',
        Avance: '1',
        Estados: 'Error de configuración: Error permisos SII',
        Mensaje: 'La certificación del comercio se encuentra en conflicto debido a un error de configuración interna. Estamos trabajando para solucionarlo lo más rápido posible.',
        MensajeSecundario: '<a href="https://help.tuu.cl/">Centro de Ayuda TUU</a>',
    },
    {
        Caso: 'HABILITACIÓN: ERROR GLOSA',
        Avance: '1',
        Estados: 'Error de configuración: Error Glosa',
        Mensaje: 'La certificación del comercio se encuentra en conflicto debido a un error de configuración interna. Estamos trabajando para solucionarlo lo más rápido posible.',
        MensajeSecundario: '<a href="https://help.tuu.cl/">Centro de Ayuda TUU</a>'
    },
    {
        Caso: 'DESARROLLO: OTROS CASOS',
        Avance: '1',
        Estados: 'Error de Desarrollo: Otros Casos',
        Mensaje: 'La certificación del comercio se encuentra en conflicto debido a un error de configuración interna. Estamos trabajando para solucionarlo lo más rápido posible.',
        MensajeSecundario: '<a href="https://help.tuu.cl/">Centro de Ayuda TUU</a>'
    },
    {
        Caso: 'DESARROLLO: ERROR INTERNO DB',
        Avance: '1',
        Estados: 'Error de Desarrollo: Error base de datos',
        Mensaje: 'La certificación del comercio se encuentra en conflicto debido a un error de configuración interna. Estamos trabajando para solucionarlo lo más rápido posible.',
        MensajeSecundario: '<a href="https://help.tuu.cl/>Centro de Ayuda TUU</a>'
    },
    {
        Caso: 'HABILITACIÓN: MÚLTIPLES ERRORES',
        Avance: '1',
        Estados: 'Error de Configuración: Errores Múltiples',
        Mensaje: 'La certificación del comercio se encuentra en conflicto debido a un error de configuración interna. Estamos trabajando para solucionarlo lo más rápido posible.',
        MensajeSecundario: '<a href="https://help.tuu.cl/>Centro de Ayuda TUU</a>'
    },
    {
        Caso: 'HABILITACIÓN: ERROR COMENZAR OPERACIÓN',
        Avance: '1',
        Estados: 'Error de configuración: Error al comenzar operación',
        Mensaje: 'La certificación del comercio se encuentra en conflicto debido a un error de configuración interna. Estamos trabajando para solucionarlo lo más rápido posible.',
        MensajeSecundario: '<a href="https://help.tuu.cl/>Centro de Ayuda TUU</a>'
    }
]

export const RESPONSE_TABLE_BOLE = [
    {
        Caso: 'Tareas por Hacer',
        Avance: "0",
        Estados: 'Certificación por realizar',
        Mensaje: 'La firma electrónica del represententante legal del comercio ha sido cargada.',
        MensajeSecundario: undefined
    },
    {
        Caso: 'Certificando',
        Avance: '1',
        Estados: 'Certificación automática en curso',
        Mensaje: 'Hemos verificado que tanto el comercio como su representante legal cumplen con los requisitos básicos impuestos por el SII. <br>El proceso de certificación de los documentos electrónicos seleccionados ha iniciado. Esta etapa puede tardar hasta 1 día hábil.',
        MensajeSecundario: undefined
    },
    {
        Caso: 'Listo para activar',
        Avance: '2',
        Estados: 'Certificación Finalizada: En espera de comienzo de operación',
        Mensaje: `
        ¡Tenemos buenas noticias! La certificación de boletas electrónicas del comercio ha finalizado. Inicia sesión en <a href="https://espacio.haulmer.com/">espacio de trabajo</a> para comenzar operación.<br>
        <b>Nota: En caso de que hayas solicitado habiltar facturas electrónicas, deberás esperar a que dicha certificación también finalice para poder comenzar operación.</b>`,
        MensajeSecundario: `
        <ul>
            <li>
                <a href="https://espacio.haulmer.com/">Espacio de Trabajo</a>
            </li>
            <li>
                <a href="https://www.youtube.com/watch?v=lyJnKVVrPaU&list=PLu7bvUiAPJCHn_NixQEFscbbhzp3BxoET&index=10">Video: Comienza operación en tu espacio de trabajo</a>
            </li>
            <li>
                <a href="https://agenda.haulmer.com/">Capacitación online: Agenda tu hora con un ejecutivo</a>
            </li>
        </ul>
        `
    },
    {
        Caso: 'Finalizada',
        Avance: '3',
        Estados: 'Certificación Terminada: Comercio se encuentra operando',
        Mensaje: 'Las boletas electrónicas se han integrado exitosamente. Ya puedes comenzar a emitir boletas desde tu plataforma web y aplicaciones.',
        MensajeSecundario: `
        <ul>
            <li>
                <a href="https://espacio.haulmer.com/">Espacio de Trabajo</a>
            </li>
            <li>
                <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/donde-ubicar-documentos-electr%C3%B3nicos-dentro-del-espacio-de-trabajo/fCHkbv6ckhjJ6djPTeQGWp">FAQ: ¿Dónde ubicar Documentos electrónicos dentro del Espacio de trabajo?</a>
            </li>
            <li>
                <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/%C2%BFc%C3%B3mo-declarar-el-modelo-de-emisi%C3%B3n-de-boletas-en-el-sii/akS1qaL95H37T5yNMy8XFR">FAQ: ¿Cómo declarar el modelo de emisión de boletas en el SII?</a>
            </li>
            <li>
                <a href="https://agenda.haulmer.com/">Capacitación online: Agenda tu hora con un ejecutivo</a>
            </li>
        </ul>
        `
    },
    {
        Caso: 'Certificación en conflicto',
        Avance: '1',
        Estados: 'Certificación en conflicto',
        Mensaje: 'El proceso de certificación de boletas electrónicas ha sido detenido por algún conflicto. ',
        MensajeSecundario: undefined
    },
    {
        Caso: 'Certificación conflicto',
        Avance: '1',
        Estados: 'Certificación en conflicto',
        Mensaje: 'El proceso de certificación de boletas electrónicas ha sido detenido por algún conflicto. ',
        MensajeSecundario: undefined
    },
    {
        Caso: 'Cancelada',
        Avance: '0',
        Estados: 'Certificación Cancelada',
        Mensaje: 'El proceso de certificación de boletas electrónicas ha sido cancelado por solicitud del cliente. ',
        MensajeSecundario: undefined
    }, {
        Caso: 'Cancelado',
        Avance: '0',
        Estados: 'Certificación Cancelada',
        Mensaje: 'El proceso de certificación de boletas electrónicas ha sido cancelado por solicitud del cliente. ',
        MensajeSecundario: undefined
    }, {
        Caso: 'Set de pruebas',
        Avance: '1',
        Estados: 'Certificación automática en curso',
        Mensaje: `Hemos verificado que tanto el comercio como su representante legal cumplen con los requisitos básicos impuestos por el SII. <br>El proceso de certificación de los documentos electrónicos seleccionados ha iniciado. Esta etapa puede tardar hasta 1 día hábil.`,
        MensajeSecundario: undefined
    }, {
        Caso: 'Prueba de configuración',
        Avance: '1',
        Estados: 'Certificación automática en curso',
        Mensaje: `Hemos verificado que tanto el comercio como su representante legal cumplen con los requisitos básicos impuestos por el SII. <br>El proceso de certificación de los documentos electrónicos seleccionados ha iniciado. Esta etapa puede tardar hasta 1 día hábil.`,
        MensajeSecundario: undefined
    }, {
        Caso: 'Activación en conflicto',
        Avance: '2',
        Estados: 'Activación en conflicto',
        Mensaje: `El proceso de certificación de boletas electrónicas ha finalizado, sin embargo no ha sido posible habilitar los documentos electrónicos por algún conflicto, el cual se encuentra en revisión.`,
        MensajeSecundario: undefined
    }, {
        Caso: 'Desarrollo: Error interno DB',
        Avance: '1',
        Estados: 'Certificación en conflicto',
        Mensaje: `El proceso de certificación de boletas electrónicas ha sido detenido por algún conflicto.`,
        MensajeSecundario: undefined
    }
]
export const RESPONSE_TABLE_HES = [
    {
        Caso: 'Tareas por hacer', //Tarea por hacer
        Avance: "0",
        Estados: 'Esperando asignación',    //(un tiempo máximo de 24 horas para asignación)
        Mensaje: "Tu caso ha sido registrado recientemente y está pendiente de revisión por nuestro equipo de soporte. Estamos al tanto de tu consulta y trabajaremos para proporcionarte una solución.",
        MensajeSecundario: undefined
    },
    {
        Caso: 'Prioritario',    //Prioritario
        Avance: "0",
        Estados: 'Esperando asignación',
        Mensaje: "Hemos marcado tu caso como prioritario, lo que significa que recibirá una atención especial y se revisará con alta prioridad. Estamos comprometidos en resolverlo rápidamente.",
        MensajeSecundario: undefined
    },
    {
        Caso: 'Urgente',    //Urgente
        Avance: "0",
        Estados: 'Esperando asignación',
        Mensaje: "Hemos identificado la urgencia de tu solicitud, y por eso, tu caso ha sido incluido en la lista de revisiones urgentes. Nuestro equipo se enfocará en proporcionarte una solución lo más rápido posible.",
        MensajeSecundario: undefined
    },
    {
        Caso: 'En revisión',    //En revisión
        Avance: "1",
        Estados: 'En progreso', //(un tiempo máximo de 24 horas para contacto)
        Mensaje: "Un agente especializado está revisando detenidamente tu caso. Pronto recibirás una llamada de nuestro equipo para obtener más detalles y comprender mejor tu situación.",
        MensajeSecundario: undefined
    },
    {
        Caso: 'Re-dirigido', //Redirigido
        Avance: "2",
        Estados: 'Requiere desarrollo', //***(tiempo indefinido negociado por el agente de lvl2)
        Mensaje: "Nuestro equipo de soporte ha determinado que se requiere la colaboración de otro departamento para resolver tu solicitud de manera efectiva. Esto podría prolongar el tiempo de resolución.",
        MensajeSecundario: undefined
    },
    {
        Caso: 'Proveedor Externo - Redirect',  //Proveedor externo
        Avance: "2",
        Estados: 'Requiere desarrollo',
        Mensaje: "Tu caso está relacionado con nuestros proveedores, con quienes tenemos una asociación estratégica. El tiempo de resolución podría verse afectado, pero trabajaremos con ellos para brindarte la mejor solución posible.",
        MensajeSecundario: undefined
    },
    {
        Caso: 'Waiting Client', //Esperando tu respuesta
        Avance: "3",
        Estados: 'Espera de contacto',  //(24 horas a partir del inicio de la revisión)
        Mensaje: "Hemos enviado un mensaje a través del medio asociado (correo, WhatsApp, ticket) solicitando información adicional para resolver el problema. Por favor, revisa esos medios para continuar el proceso y brindarte una solución rápida y efectiva.",
        MensajeSecundario: undefined
    },
    {
        Caso: 'Archived',  //Archivado
        Avance: "4",
        Estados: 'Terminado',
        Mensaje: "Tu caso permanecerá en este estado para un seguimiento continuo. Estaremos atentos a cualquier cambio y te mantendremos informado hasta que se considere conveniente cerrarlo.",
        MensajeSecundario: undefined
    },
    {
        Caso: 'Cerrada',    //Cerrado
        Avance: "4",
        Estados: 'Terminado',
        Mensaje: "Lamentablemente, hemos tenido dificultades para contactarte o resolver el requerimiento según lo solicitado. Si tienes más consultas o inquietudes, no dudes en contactarnos nuevamente.",
        MensajeSecundario: undefined
    },
    {
        Caso: 'Finalizada', //Finalizado
        Avance: "4",
        Estados: 'Terminado',
        Mensaje: "Nos complace informarte que hemos resuelto tu caso exitosamente. Un agente de soporte se comunicará contigo para proporcionarte detalles sobre la solución implementada.",
        MensajeSecundario: undefined
    }
]
interface ICard {
    titulo: SafeHtml,
    descripcion: SafeHtml,
    motivo: string,
    className: string,
    elipse: string,
    lastUpdate: string
}
export interface ITextMapDocs {
    despacho: {
        activeIndex: number,
        cardList: [ICard, ICard, ICard, ICard, ICard, ICard, ICard]
    },
    certificacion: {
        activeIndex: number,
        cardList: [ICard, ICard, ICard, ICard, ICard, ICard, ICard]
    },
    garantia: {
        activeIndex: number,
        cardList: [ICard, ICard, ICard, ICard, ICard, ICard, ICard]
    },
    reclamo: {
        activeIndex: number,
        cardList: [ICard, ICard, ICard, ICard, ICard, ICard, ICard]
    }
}
export interface ITextMapBoleta {
    despacho: {
        activeIndex: number,
        cardList: [ICard, ICard, ICard, ICard]
    },
    certificacion: {
        activeIndex: number,
        cardList: [ICard, ICard, ICard, ICard]
    },
    garantia: {
        activeIndex: number,
        cardList: [ICard, ICard, ICard, ICard]
    },
    reclamo: {
        activeIndex: number,
        cardList: [ICard, ICard, ICard, ICard]
    }
}
export interface ITextMapHES {
    activeIndex: number,
    cardList: [ICard, ICard, ICard, ICard, ICard]
}

interface IIssue {
    fields: {
        issuetype: {
            description: string;
            name: string;
        }
        status: {
            description: string;
            name: string;
        }
        summary: string;
        updated: string | Date;
    };
}

export interface IResponseFromAPI {
    res1?: number;
    res2?: number;
    res3?: number;
    certificacion_inicial?: {
        issues: Array<IIssue>;
    }
    boletas?: {
        issues: Array<IIssue>;
    }
    hes?: {
        issues: Array<IIssue>;
    }
}

export function translateStatus(status: string): string {
    let translatedStatus: string;
    switch (status) {
        case "Tareas por hacer":
            translatedStatus = "Tarea por hacer";
            break;
        case "Proveedor Externo - Redirect":
            translatedStatus = "Proveedor Externo - Redirección"
            break;
        case "Waiting Client":
            translatedStatus = "Esperando tu respuesta";
            break;
        case "Archived":
            translatedStatus = "Archivado";
            break;
        case "Cerrada":
            translatedStatus = "Cerrado";
            break;
        case "Finalizada":
            translatedStatus = "Finalizado";
            break;
        default:
            translatedStatus = status;
            break;
    }
    return translatedStatus;
}

export function generateTextMapDocs(): ITextMapDocs {
    return {
        despacho: {
            activeIndex: 0,
            cardList: [
                {
                    titulo: `<span><h1 class="elipse-disabled">1</h1></span><h1 class="title-state">STATE1</h1>`,
                    descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: `<span><h1 class="elipse-disabled">2</h1></span><h1 class="title-state">STATE2</h1>`,
                    descripcion: `<div class="description">
                                <p>
                                    TEXT
                                </p>
                                <div class="recommended">
                                    <h1>
                                        TITLE
                                    </h1>
                                    <p>
                                        <a href="LINK">
                                            TEXT
                                        </a>
                                    </p>
                                </div>
                            </div>`, motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: `<span><h1 class="elipse-disabled">3</h1></span><h1 class="title-state">STATE3</h1>`,
                    descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`, motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: `<span><h1 class="elipse-disabled">4</h1></span><h1 class="title-state">STATE4</h1>`,
                    descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`, motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: `<span><h1 class="elipse-disabled">5</h1></span><h1 class="title-state">STATE5</h1>`,
                    descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: `<span><h1 class="elipse-disabled">6</h1></span><h1 class="title-state">STATE6</h1>`,
                    descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`, motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: `<span><h1 class="elipse-disabled">7</h1></span><h1>STATE7</h1>`,
                    descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`, motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                }
            ]
        },
        certificacion: {
            activeIndex: 0,
            cardList: [
                {
                    titulo: '<span><h1 class="elipse-disabled">1</h1></span><h1 class="title-state">Tarea Terminada</h1>',
                    descripcion: `<div class="description">
                                    <p>
                                        La firma electrónica del representante legal del comercio ya ha sido cargada exitosamente.
                                        El proceso automático para verificar información asociada y configuraciones requeridas ha finalizado.
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            Acciones recomendadas
                                        </h1>
                                        <p>
                                            <a href="https://help.tuu.cl/">
                                                Centro de Ayuda TUU
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: '<span><h1 class="elipse-disabled">2</h1></span><h1 class="title-state">Pruebas de configuración terminada</h1>',
                    descripcion: `<div class="description">
                                    <p>
                                        Las pruebas de configuración previas a la certificación ya se han realizado correctamente.
                                        El proceso de certificación de documentos tributarios electrónicos se ha iniciado.
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            Acciones recomendadas
                                        </h1>
                                        <p>
                                            <a href="https://help.tuu.cl/">
                                                Centro de Ayuda TUU
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: '<span><h1 class="elipse-disabled">3</h1></span><h1 class="title-state">Cola terminada</h1>',
                    descripcion: `<div class="description">
                                    <p>
                                        Hemos verificado que tanto el comercio como su representante legal cumplen con los requisitos básicos impuestos por el SII. La solicitud de certificación de documentos tributarios electrónicos se ha iniciado automáticamente.
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            Acciones recomendadas
                                        </h1>
                                        <p>
                                            <a href="https://help.tuu.cl/">
                                                Centro de Ayuda TUU
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: '<span><h1 class="elipse-disabled">4</h1></span><h1 class="title-state">Certificación automática terminada</h1>',
                    descripcion: `<div class="description">
                                    <p>
                                        El proceso automático de certificación de los documentos electrónicos seleccionados ha terminado correctamente.
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            Acciones recomendadas
                                        </h1>
                                        <p>
                                            <a href="https://help.tuu.cl/">
                                                Centro de Ayuda TUU
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: '<span><h1 class="elipse-disabled">5</h1></span><h1 class="title-state">Certificación enviada al SII</h1>',
                    descripcion: `<div class="description">
                                    <p>
                                        Se ha completado todas las etapas de la certificación automática de los documentos tributarios electrónicos.
                                        Se ha enviado su certificación al SII para la revisión y aprobación final.
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            Acciones recomendadas
                                        </h1>
                                        <p>
                                            Revisa el tutorial:
                                        </p>
                                        <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/situaciones-pendientes-en-el-sii/sEVG3YDCVPnu9BW3xqYHiu">
                                            ¿Cómo solucionar situaciones pendientes en el SII?
                                        </a>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: '<span><h1 class="elipse-disabled">6</h1></span><h1 class="title-state">Certificación Finalizada: Comienzo de operación</h1>',
                    descripcion: `<div class="description">
                                    <p>
                                    La certificación de documentos tributarios electrónicos del comercio ha finalizado.
                                    Ya se ha comenzado operación en <a href="https://espacio.haulmer.com/">plataforma web</a>.
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            Acciones recomendadas
                                        </h1>
                                        <p>
                                        <ul>
                                            <li>
                                            <a href="https://espacio.haulmer.com/">Espacio de Trabajo</a>
                                            </li>
                                            <li>
                                                <a href="https://www.youtube.com/watch?v=lyJnKVVrPaU&list=PLu7bvUiAPJCHn_NixQEFscbbhzp3BxoET&index=10">Video: Comienza operación en tu espacio de trabajo</a>
                                            </li>
                                            <li>
                                                <a href="https://agenda.haulmer.com/ ">Capacitación online: Agenda tu hora con un ejecutivo</a>
                                            </li>
                                        </ul>
                                        </p>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: '<span><h1 class="elipse-disabled">7</h1></span><h1 class="title-state">Certificación Finalizada</h1>',
                    descripcion: `<div class="description">
                                    <p>
                                        Los documentos tributarios electrónicos se han integrado exitosamente.
                                        Ya puedes comenzar a emitir documentos electrónicos desde tu plataforma web y aplicaciones.
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            Acciones recomendadas
                                        </h1>
                                        <p>
                                            <ul>
                                                <li>
                                                    <a href="https://espacio.haulmer.com/">
                                                        Espacio de Trabajo
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/donde-ubicar-documentos-electr%C3%B3nicos-dentro-del-espacio-de-trabajo/fCHkbv6ckhjJ6djPTeQGWp">
                                                        FAQ: ¿Dónde ubicar Documentos electrónicos dentro del Espacio de trabajo?
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/%C2%BFc%C3%B3mo-declarar-el-modelo-de-emisi%C3%B3n-de-boletas-en-el-sii/akS1qaL95H37T5yNMy8XFR">
                                                        FAQ: ¿Cómo declarar el modelo de emisión de boletas en el SII?
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://agenda.haulmer.com/">
                                                        Capacitación online: Agenda tu hora con un ejecutivo
                                                    </a>
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                }
            ]
        },
        garantia: {
            activeIndex: 0,
            cardList: [
                {
                    titulo: `<span><h1 class="elipse-disabled">1</h1></span><h1 class="title-state">STATE1</h1>`,
                    descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: `<span><h1 class="elipse-disabled">2</h1></span><h1 class="title-state">STATE2</h1>`,
                    descripcion: `<div class="description">
                                <p>
                                    TEXT
                                </p>
                                <div class="recommended">
                                    <h1>
                                        TITLE
                                    </h1>
                                    <p>
                                        <a href="LINK">
                                            TEXT
                                        </a>
                                    </p>
                                </div>
                            </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: `<span><h1 class="elipse-disabled">3</h1></span><h1 class="title-state">STATE3</h1>`,
                    descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: `<span><h1 class="elipse-disabled">4</h1></span><h1 class="title-state">STATE4</h1>`,
                    descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: `<span><h1 class="elipse-disabled">5</h1></span><h1 class="title-state">STATE5</h1>`,
                    descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: `<span><h1 class="elipse-disabled">6</h1></span><h1 class="title-state">STATE6</h1>`,
                    descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: `<span><h1 class="elipse-disabled">7</h1></span><h1>STATE7</h1>`,
                    descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                }
            ]
        },
        reclamo: {
            activeIndex: 0,
            cardList: [
                {
                    titulo: `<span><h1 class="elipse-disabled">1</h1></span><h1 class="title-state">STATE1</h1>`,
                    descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: `<span><h1 class="elipse-disabled">2</h1></span><h1 class="title-state">STATE2</h1>`,
                    descripcion: `<div class="description">
                                <p>
                                    TEXT
                                </p>
                                <div class="recommended">
                                    <h1>
                                        TITLE
                                    </h1>
                                    <p>
                                        <a href="LINK">
                                            TEXT
                                        </a>
                                    </p>
                                </div>
                            </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: `<span><h1 class="elipse-disabled">3</h1></span><h1 class="title-state">STATE3</h1>`,
                    descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: `<span><h1 class="elipse-disabled">4</h1></span><h1 class="title-state">STATE4</h1>`,
                    descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: `<span><h1 class="elipse-disabled">5</h1></span><h1 class="title-state">STATE5</h1>`,
                    descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: `<span><h1 class="elipse-disabled">6</h1></span><h1 class="title-state">STATE6</h1>`,
                    descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                },
                {
                    titulo: `<span><h1 class="elipse-disabled">7</h1></span><h1 class="title-state">STATE7</h1>`,
                    descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                    motivo: undefined,
                    className: "label_disabled",
                    elipse: "elipse-disabled",
                    lastUpdate: undefined
                }
            ]
        }
    }
}

export function generateTextMapBoleta(): ITextMapBoleta {
    return {
        despacho: {
            activeIndex: 0,
            cardList: [{
                titulo: `<span><h1 class="elipse-disabled">X</h1></span><h1 class="title-state">STATEX</h1>`,
                descripcion: `<div class="description">
                                <p>
                                    TEXT
                                </p>
                                <div class="recommended">
                                    <h1>
                                        TITLE
                                    </h1>
                                    <p>
                                        <a href="LINK">
                                            TEXT
                                        </a>
                                    </p>
                                </div>
                            </div>`,
                motivo: undefined,
                className: "label_disabled",
                elipse: "elipse-disabled",
                lastUpdate: undefined
            },
            {
                titulo: `<span><h1 class="elipse-disabled">X</h1></span><h1 class="title-state">STATEX</h1>`,
                descripcion: `<div class="description">
                                <p>
                                    TEXT
                                </p>
                                <div class="recommended">
                                    <h1>
                                        TITLE
                                    </h1>
                                    <p>
                                        <a href="LINK">
                                            TEXT
                                        </a>
                                    </p>
                                </div>
                            </div>`,
                motivo: undefined,
                className: "label_disabled",
                elipse: "elipse-disabled",
                lastUpdate: undefined
            },
            {
                titulo: `<span><h1 class="elipse-disabled">X</h1></span><h1 class="title-state">STATEX</h1>`,
                descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                motivo: undefined,
                className: "label_disabled",
                elipse: "elipse-disabled",
                lastUpdate: undefined
            },
            {
                titulo: `<span><h1 class="elipse-disabled">X</h1></span><h1 class="title-state">STATEX</h1>`,
                descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                motivo: undefined,
                className: "label_disabled",
                elipse: "elipse-disabled",
                lastUpdate: undefined
            },]
        },
        certificacion: {
            activeIndex: 0,
            cardList: [{
                titulo: `<span><h1 class="elipse-disabled">1</h1></span><h1 class="title-state">Certificación por realizar</h1>`,
                descripcion: `<div class="description">
                                <p>
                                    La firma electrónica del represententante legal del comercio ha sido cargada correctamente.
                                </p>
                                <div class="recommended">
                                    <h1>
                                        Acciones recomendadas
                                    </h1>
                                    <p>
                                        <a href="https://help.tuu.cl/">
                                            Centro de Ayuda TUU
                                        </a>
                                    </p>
                                </div>
                            </div>`,
                motivo: undefined,
                className: "label_disabled",
                elipse: "elipse-disabled",
                lastUpdate: undefined
            },
            {
                titulo: `<span><h1 class="elipse-disabled">2</h1></span><h1 class="title-state">Certificando</h1>`,
                descripcion: `<div class="description">
                                <p>
                                Hemos verificado que tanto el comercio como su representante legal cumplen con los requisitos básicos impuestos por el SII. El proceso de certificación de los documentos electrónicos seleccionados ha finalizado correctamente.
                                </p>
                                <div class="recommended">
                                    <h1>
                                        Acciones recomendadas
                                    </h1>
                                    <p>
                                        <a href="https://help.tuu.cl/">
                                            Centro de Ayuda TUU
                                        </a>
                                    </p>
                                </div>
                            </div>`,
                motivo: undefined,
                className: "label_disabled",
                elipse: "elipse-disabled",
                lastUpdate: undefined
            },
            {
                titulo: `<span><h1 class="elipse-disabled">3</h1></span><h1 class="title-state">Certificación Finalizada: Comienzo de operación</h1>`,
                descripcion: `<div class="description">
                                    <p>
                                        La certificación de boletas electrónicas del comercio ha finalizado correctamente. Ya se ha comenzado operación en <a href="https://espacio.haulmer.com/">plataforma web</a><br>
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            Acciones recomendadas
                                        </h1>
                                        <p>
                                            <ul>
                                                <li>
                                                    <a href="https://espacio.haulmer.com/">Espacio de Trabajo</a>
                                                </li>
                                                <li>
                                                    <a href="https://www.youtube.com/watch?v=lyJnKVVrPaU&list=PLu7bvUiAPJCHn_NixQEFscbbhzp3BxoET&index=10">Video: Comienza operación en tu espacio de trabajo</a>
                                                </li>
                                                <li>
                                                    <a href="https://agenda.haulmer.com/">Capacitación online: Agenda tu hora con un ejecutivo</a>
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>`,
                motivo: undefined,
                className: "label_disabled",
                elipse: "elipse-disabled",
                lastUpdate: undefined
            },
            {
                titulo: `<span><h1 class="elipse-disabled">4</h1></span><h1 class="title-state">Certificación Terminada: Comercio se encuentra operando</h1>`,
                descripcion: `<div class="description">
                                    <p>
                                        Las boletas electrónicas se han integrado exitosamente. Ya puedes comenzar a emitir boletas desde tu plataforma web y aplicaciones.
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            Acciones recomendadas
                                        </h1>
                                        <p>
                                            <ul>
                                                <li>
                                                    <a href="https://espacio.haulmer.com/">Espacio de Trabajo</a>
                                                </li>
                                                <li>
                                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/donde-ubicar-documentos-electr%C3%B3nicos-dentro-del-espacio-de-trabajo/fCHkbv6ckhjJ6djPTeQGWp">FAQ: ¿Dónde ubicar Documentos electrónicos dentro del Espacio de trabajo?</a>
                                                </li>
                                                <li>
                                                    <a href="https://help.tuu.cl/temas-de-ayuda/5pKp9Zk7c41cBeKgJEzQRB/%C2%BFc%C3%B3mo-declarar-el-modelo-de-emisi%C3%B3n-de-boletas-en-el-sii/akS1qaL95H37T5yNMy8XFR">FAQ: ¿Cómo declarar el modelo de emisión de boletas en el SII?</a>
                                                </li>
                                                <li>
                                                    <a href="https://agenda.haulmer.com/">Capacitación online: Agenda tu hora con un ejecutivo</a>
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>`,
                motivo: undefined,
                className: "label_disabled",
                elipse: "elipse-disabled",
                lastUpdate: undefined
            },]
        },
        garantia: {
            activeIndex: 0,
            cardList: [{
                titulo: `<span><h1 class="elipse-disabled">X</h1></span><h1 class="title-state">STATEX</h1>`,
                descripcion: `<div class="description">
                                <p>
                                    TEXT
                                </p>
                                <div class="recommended">
                                    <h1>
                                        TITLE
                                    </h1>
                                    <p>
                                        <a href="LINK">
                                            TEXT
                                        </a>
                                    </p>
                                </div>
                            </div>`,
                motivo: undefined,
                className: "label_disabled",
                elipse: "elipse-disabled",
                lastUpdate: undefined
            },
            {
                titulo: `<span><h1 class="elipse-disabled">X</h1></span><h1 class="title-state">STATEX</h1>`,
                descripcion: `<div class="description">
                                <p>
                                    TEXT
                                </p>
                                <div class="recommended">
                                    <h1>
                                        TITLE
                                    </h1>
                                    <p>
                                        <a href="LINK">
                                            TEXT
                                        </a>
                                    </p>
                                </div>
                            </div>`,
                motivo: undefined,
                className: "label_disabled",
                elipse: "elipse-disabled",
                lastUpdate: undefined
            },
            {
                titulo: `<span><h1 class="elipse-disabled">X</h1></span><h1 class="title-state">STATEX</h1>`,
                descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                motivo: undefined,
                className: "label_disabled",
                elipse: "elipse-disabled",
                lastUpdate: undefined
            },
            {
                titulo: `<span><h1 class="elipse-disabled">X</h1></span><h1 class="title-state">STATEX</h1>`,
                descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                motivo: undefined,
                className: "label_disabled",
                elipse: "elipse-disabled",
                lastUpdate: undefined
            },]
        },
        reclamo: {
            activeIndex: 0,
            cardList: [{
                titulo: `<span><h1 class="elipse-disabled">X</h1></span><h1 class="title-state">STATEX</h1>`,
                descripcion: `<div class="description">
                                <p>
                                    TEXT
                                </p>
                                <div class="recommended">
                                    <h1>
                                        TITLE
                                    </h1>
                                    <p>
                                        <a href="LINK">
                                            TEXT
                                        </a>
                                    </p>
                                </div>
                            </div>`,
                motivo: undefined,
                className: "label_disabled",
                elipse: "elipse-disabled",
                lastUpdate: undefined
            },
            {
                titulo: `<span><h1 class="elipse-disabled">X</h1></span><h1 class="title-state">STATEX</h1>`,
                descripcion: `<div class="description">
                                <p>
                                    TEXT
                                </p>
                                <div class="recommended">
                                    <h1>
                                        TITLE
                                    </h1>
                                    <p>
                                        <a href="LINK">
                                            TEXT
                                        </a>
                                    </p>
                                </div>
                            </div>`,
                motivo: undefined,
                className: "label_disabled",
                elipse: "elipse-disabled",
                lastUpdate: undefined
            },
            {
                titulo: `<span><h1 class="elipse-disabled">X</h1></span><h1 class="title-state">STATEX</h1>`,
                descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                motivo: undefined,
                className: "label_disabled",
                elipse: "elipse-disabled",
                lastUpdate: undefined
            },
            {
                titulo: `<span><h1 class="elipse-disabled">X</h1></span><h1 class="title-state">STATEX</h1>`,
                descripcion: `<div class="description">
                                    <p>
                                        TEXT
                                    </p>
                                    <div class="recommended">
                                        <h1>
                                            TITLE
                                        </h1>
                                        <p>
                                            <a href="LINK">
                                                TEXT
                                            </a>
                                        </p>
                                    </div>
                                </div>`,
                motivo: undefined,
                className: "label_disabled",
                elipse: "elipse-disabled",
                lastUpdate: undefined
            },]
        }
    }
}

export function generateTextMapHES(): ITextMapHES {
    return {
        activeIndex: 0,
        cardList: [{
            titulo: `<span><h1 class="elipse-disabled">1</h1></span><h1 class="title-state">Tarea asignada</h1>`,
            descripcion: `<div class="description">
                                <p>
                                    Tu caso ya ha sido registrado y está en revisión por nuestro equipo de soporte. Estamos al tanto de tu consulta y estamos trabajando para proporcionarte una solución.
                                </p>
                            </div>`,
            motivo: undefined,
            className: "label_disabled",
            elipse: "elipse-disabled",
            lastUpdate: undefined
        }, {
            titulo: `<span><h1 class="elipse-disabled">2</h1></span><h1 class="title-state">En revisión</h1>`,
            descripcion: `<div class="description">
                                <p>
                                Un agente especializado está revisando detenidamente tu caso. Ya has recibido una llamada de nuestro equipo para obtener más detalles y comprender mejor tu situación.
                                </p>
                            </div>`,
            motivo: undefined,
            className: "label_disabled",
            elipse: "elipse-disabled",
            lastUpdate: undefined
        }, {
            titulo: `<span><h1 class="elipse-disabled">3</h1></span><h1 class="title-state">Desarrollo terminado</h1>`,
            descripcion: `<div class="description">
                                <p>
                                    Tu caso ya ha sido trabajado para resolver el problema brindándote la mejor solución efectiva.
                                </p>
                            </div>`,
            motivo: undefined,
            className: "label_disabled",
            elipse: "elipse-disabled",
            lastUpdate: undefined
        }, {
            titulo: `<span><h1 class="elipse-disabled">4</h1></span><h1 class="title-state">Respuesta recibida</h1>`,
            descripcion: `<div class="description">
                                <p>
                                    Hemos recibido tu respuesta a través del medio asociado (correo, Whatsapp, ticket) con la información adicional para resolver el problema.
                                </p>
                            </div>`,
            motivo: undefined,
            className: "label_disabled",
            elipse: "elipse-disabled",
            lastUpdate: undefined
        }, {
            titulo: `<span><h1 class="elipse-disabled">5</h1></span><h1 class="title-state">Terminado</h1>`,
            descripcion: `<div class="description">
                                <p>
                                    El caso ha finalizado según el último estado proporcionado.
                                </p>
                            </div>`,
            motivo: undefined,
            className: "label_disabled",
            elipse: "elipse-disabled",
            lastUpdate: undefined
        }]
    }
}