import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { urlMapping } from '../../route-ids';

@Component({
    selector: 'app-popup-reserva-release',
    templateUrl: './popup-reserva-release.component.html',
    styleUrls: ['./popup-reserva-release.component.scss']
})
export class PopupReservaReleaseComponent implements OnInit {

    public url: string;

    ngOnInit(): void { }

    constructor(
        private router: Router,
        private dialogRef: MatDialogRef<PopupReservaReleaseComponent>
    ) { 
        const urlTree = this.router.parseUrl(this.router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';
        this.url = urlMapping[urlSegment] || urlSegment;
    }

    redireccionarAConocerReserva() {
        this.router.navigate(['/tuu-reserva']);
        this.dialogRef.close();
    }
}
