import { Component } from '@angular/core';

@Component({
    selector: 'app-parking-benefits',
    templateUrl: './parking-benefits.component.html',
    styleUrls: ['./parking-benefits.component.scss']
})
export class ParkingBenefitsComponent {

    public cards = [
        {
            icon: 'icon-sprite-attach-money',
            text: 'Definir tarifas por tiempo y aceptar pagos en tu terminal.'
        }, {
            icon: 'icon-sprite-car',
            text: 'Llevar registro de la entrada y salida de vehículos.'
        }, {
            icon: 'icon-sprite-clock-eight',
            text: 'Controlar el tiempo de estacionamiento por vehículo.'
        }, {
            icon: 'icon-sprite-parking-circle',
            text: 'Identificar el nivel de uso de tu estacionamiento y espacios libres.'
        }, {
            icon: 'icon-sprite-money-withdraw',
            text: 'Llevar el control de los ingresos de tu estacionamiento.'
        }, {
            icon: 'icon-sprite-printer',
            text: 'Entregar voucher o boleta a tus clientes.'
        }
    ];

}
