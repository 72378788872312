import { Component, ElementRef, OnInit } from '@angular/core';
import { traceButton2 } from '../../../../../common/services/button-trace';
import { ScrollToClass } from '../../shared/scrollTo.class';

@Component({
    selector: 'app-inventory-first-screen',
    templateUrl: './inventory-first-screen.component.html',
    styleUrls: ['./inventory-first-screen.component.scss']
})
export class InventoryFirstScreenComponent extends ScrollToClass implements OnInit {

    constructor(private _el: ElementRef) {
        super(_el);
    }

    ngOnInit(): void {
    }

    public navigateToSection(className: string): void {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 500);
    }

    public track(event: Event){
        traceButton2(event,'scroll');
    }

}
