import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MetaService } from '@ngx-meta/core';

import { urlEnv } from '../../environments/environment';
import { SEOService } from '../shared/services/seo.service';
@Component({
    selector: 'app-payment-page',
    templateUrl: './payment-page.component.html'
})
export class PaymentPageComponent implements OnInit {

    public urlEnv = urlEnv;

    public showImage1 = false;
    public showImage2 = false;
    public showImage3 = false;

    public dataPriceSection = {
        buttons: [
            {
                id: 'TUU-pago-precios-compra-simple'
            },
            {
                id: 'UU-pago-precios-compra-con-complementos'
            }
        ]
    };

    public itemsFAQ = [
        {
            title: '¿Con TUU mis datos están protegidos?',
            text: '¡Hasta el más mínimo detalle! Todos nuestros dispositivos están certificados por PCI, lo cual asegura la invulnerabilidad de cada máquina, además de la protección de toda tu información confidencial. Todo esto tanto para ti como para tus clientes.'
        }, {
            title: '¿Con qué aplicaciones es compatible TUU?',
            text: '¡Funciona con todas aquellas "NFC"! Entre las que se encuentran: Google Play, Apple Pay y otras billeteras electrónicas. Así tu negocio no pierde ventas.'
        },{
            title: '¿Cuándo estarán disponibles los abonos en mi cuenta bancaria?',
            text: 'El abono del <b>100% de tus ventas diarias sin cortes bancarios</b> se carga de forma automática en la cuenta de tu comercio desde 1 día hábil, tanto para compras con tarjeta de débito como crédito.'
        }, {
            title: '¿Cómo funciona el abono de dinero para ventas con tarjetas de crédito?',
            text: 'Los abonos de transacciones con tarjetas de crédito pueden ser parciales o totales dependiendo de tu configuración:<ul><li>Puedes usar la modalidad de <b>abono total</b> desactivando las cuotas sin interés desde la configuración de tu canal de venta. De esta manera podrás recibir el dinero total de la venta desde 1 día hábil sin importar el número de cuotas.</li><li>La modalidad de <b>abono parcial</b> se activa cuando tu configuración de comercio acepta cuotas de comercio sin interés, las cuales pueden ir de 2 a 12 cuotas. Bajo esta modalidad el abono de ventas se hará parcialmente de acuerdo al número de cuotas.</li></ul>'
        }, {
            title: '¿Cuánto tarda en habilitarse el servicio para comenzar a procesar pagos con tarjetas?',
            text: 'Todos los POS se envían activados. EL proceso de habilitación comienza con la recolección de datos del comercio y cuenta de abono. Una vez que este proceso sea verificado y aceptado, se hará la activación de tu dispositivo POS para luego hacer el envío.'
        }
    ];

    constructor(
        private readonly _meta: MetaService,
        @Inject(PLATFORM_ID) private platformId: any,
        private seoService: SEOService
    ) { }

    ngOnInit(): void {
        this.seoService.updateCanonicalUrl('pago/');
        this._setMeta();

        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 500);
    }

    private _setMeta() {
        this._meta.setTitle('TUU - La máquina POS para pagar con tarjeta en tu negocio.');
        this._meta.setTag('description', 'Compra TUU máquina POS para pagar con tarjeta con las comisiones más bajas y abono al día hábil siguiente. Emite boleta electrónica y olvídate de pagar arriendo');
        this._meta.setTag('keywords', 'Pagos con tarjetas, dispositivo para pagar con tarjeta, sistema de pagos, sistema de pago con tarjeta, máquina para pago con tarjeta, POS, máquina POS, máquina redcompra');
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-payment.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Con TUU acepta pagos con tarjetas y administra tu negocio.');
    }

}
