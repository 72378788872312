import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import { Contact } from '../../common/Contact';
import { Chile } from '../../common/Chile';


export const EMPTY_PHONE_PLACEHOLDER = ' '; // NO MODIFICAR, por algún motivo se rompe el código si se cambia de espacio vacío a string vacío
export const EMPTY_PHONE_VALUE = '';
const MAX_PHONE_CHARACTERS = 20;
export const MAX_EMAIL_CHARACTERS = 200;

export const COUNTRY_STRING = 'Chile'
export const COUNTRY = Chile;
export const COUNTRY_REGION = COUNTRY[0];


export const PHONE_PATTERNS = {
    cl: '^[0-9]{9}$',
    pe: '^[0-9]{9,10}$',
    me: '^[0-9]{10,11}$',
    ar: '^[0-9]{11}$',
    co: '^[0-9]{8,10}$',
    us: '^[2-9]{1}[0-9]{2}[2-9]{1}[0-9]{6}$'
};

const PHONE_ERROR_MESSAGES = {
    default: 'Ingrese su número de teléfono.',
    maxLength: 'No puede exceder los '+MAX_PHONE_CHARACTERS+' caracteres (incluyendo espacios).',
};


const PHONE_PLACEHOLDERS = {
    cl: '912345678',
    pe: '912345678',
    me: '5512345678',
    ar: '91123456789',
    co: '3211234567',
    us: '2025550123',
    default: '1234567890'
};
  
function phonePatternInterpreter(pattern: string): string {
    const lengthPattern = pattern.match(/{(\d+),?(\d+)?}/);
    if (lengthPattern) {
        const min = lengthPattern[1];
        const max = lengthPattern[2] || min;
        return `Ingrese un teléfono de ${min}${min !== max ? ` a ${max}` : ''} dígitos.`;
    }
    return PHONE_ERROR_MESSAGES.default;
}



export function divideNames(name: string): string[] {
    name = name.trim();
    let divName = name.split(" ");
    let spaces = divName.length - 1;
    let firstName = "";
    let lastName = "";

    switch (spaces) {
        case 1: //Nombre Apellido
            [firstName, lastName] = divName;
            break;
        case 2: //Nombre Apellido1 Apellido2 || Nombre1 Nombre2 Apellido
            firstName = divName[0];
            lastName = divName.slice(1).join(" ");
            break;
        case 3: //Nombre1 Nombre2 Apellido1 Apellido2 || Nombre Apellido1 ApellidoCompuesto1 ApellidoCompuesto2 || Nombre1 Nombre2 Nombre3 Apellido
            firstName = divName.slice(0, 2).join(" ");
            lastName = divName.slice(2).join(" ");
            break;
        default: //Fallback Mitad Nombre
            let firstHalf = Math.ceil(divName.length / 2);
            firstName = divName.slice(0, firstHalf).join(" ");
            lastName = divName.slice(firstHalf).join(" ");
            break;
    }
    return [firstName, lastName];
}

export function changeValidation(form: FormGroup, country_code: string): FormGroup {
    form.get('phone').setValidators([Validators.required, phoneValidator(country_code)]);
    form.get('phone').updateValueAndValidity();
    return form;
}

export function getContact(TLD: string, contactList: Contact[]): Contact {
    const aux = (TLD === 'mx' ? 'me' : TLD);
    return contactList.filter(c => c.country_code === aux)[0];
}

export function buildFromPartners(formBuilder: FormBuilder, country_code: string): FormGroup {
    return formBuilder.group({
        name: ['', [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100)
        ]],
        email: ['', [
            Validators.required,
            customEmailValidator(),
            Validators.maxLength(MAX_EMAIL_CHARACTERS)
        ]],
        phone: [' ', [
            Validators.required,
            phoneValidator(country_code)
        ]],
        country: ['', [
        //     Validators.required
        ]],
        region: ['', [
        //     Validators.required
        ]],
        comuna: ['', [
        //     Validators.required
        ]]
    });
}

export function buildFromRequestQuote(formBuilder: FormBuilder, country_code: string): FormGroup {
    return formBuilder.group({
        name: ['', [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100)
        ]],
        rut: ['', []], // TODO add Validators later
        phone: [' ', [
            Validators.required,
            phoneValidator(country_code)
        ]],
        email: ['', [
            Validators.required,
            customEmailValidator(),
            Validators.maxLength(MAX_EMAIL_CHARACTERS)
        ]]
    });
}

export function buildBasicForm(formBuilder: FormBuilder, country_code: string): FormGroup {
    return formBuilder.group({
        name: ['', [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100)
        ]],
        email: ['', [
            Validators.required,
            customEmailValidator(),
            Validators.maxLength(MAX_EMAIL_CHARACTERS)
        ]],
        phone: [' ', [
            Validators.required,
            phoneValidator(country_code)
        ]],
    });
}

export function buildFormContactTUU(formBuilder: FormBuilder, country_code: string): FormGroup {
    return formBuilder.group({
        name: ['', [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100)
        ]],
        email: ['', [
            Validators.required,
            customEmailValidator(),
            Validators.maxLength(MAX_EMAIL_CHARACTERS)
        ]],
        phone: [' ', [
            Validators.required,
            phoneValidator(country_code)
        ]],
        schedule: ['', [
            Validators.required
        ]],
    });
}

export function changeCountry(country_code: string): boolean {
    return country_code !== 'cl';
}

export function phoneValidator(country_code: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }

        const rawValue = control.value;
        if (rawValue.length > MAX_PHONE_CHARACTERS) {
            return { 'maxLength': true };
        }

        const trimmedValue = rawValue.replace(/\s+/g, '');
        const pattern = new RegExp(PHONE_PATTERNS[country_code]);

        return pattern.test(trimmedValue) ? null : { 'pattern': true };
    };
}

export function sanitizePhone(phone: string): string {
    return phone.replace(/\s+/g, '');
}

export function getPhoneErrorMessage(form: FormGroup, country_code: string): string {
    if (form.get('phone').hasError('pattern')) {
      const pattern = PHONE_PATTERNS[country_code];
        if (pattern) {
            return phonePatternInterpreter(pattern);
        }
    }
    if (form.get('phone').hasError('maxLength')) {
        return PHONE_ERROR_MESSAGES.maxLength;
    }
    return PHONE_ERROR_MESSAGES.default;
}

export function getPhonePlaceholder(country_code: string): string {
    return PHONE_PLACEHOLDERS[country_code] || PHONE_PLACEHOLDERS.default;
}

export function customEmailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const email = control.value;

      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
      if (!email) {
        return null;
      }
  
      const valid = emailPattern.test(email);
  
      return valid ? null : { invalidEmail: true };
    };
}

export function getEmailErrorMessage(control: AbstractControl): string {
    if (control.hasError('required')) {
        return 'Ingrese su correo electrónico.';
    } else if (control.hasError('invalidEmail')) {
        return 'El formato del correo electrónico no es válido.';
    } else if (control.hasError('maxlength')) {
        return `Su email debe contener como máximo ${control.getError('maxlength').requiredLength} caracteres.`;
    } else {
        return '';
    }
}