import { Component, Input, ElementRef } from '@angular/core';
import { traceButton2 } from '../../../../../common/services/button-trace';
import { ScrollToClass } from '../../shared/scrollTo.class';

@Component({
    selector: 'app-section-income-mode',
    templateUrl: './section-income-mode.component.html',
    styleUrls: ['./section-income-mode.component.scss']
})
export class SectionIncomeModeComponent extends ScrollToClass {

    constructor(
        private _el: ElementRef
    ) {
        super(_el);
    }
    @Input() public loadingImages: boolean;

    public goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    public track(event: Event){
        traceButton2(event,'scroll');
    }
}
