import { Component, OnInit, Inject, HostBinding } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-code-reader',
    templateUrl: './dialog-code-reader.component.html',
    styleUrls: ['./dialog-code-reader.component.scss']
})
export class DialogCodeReaderComponent implements OnInit {
    @HostBinding('class') classList = 'default';

    public isChecked = false;


    public title = '¿Estás seguro de que no quieres adquirir el lector de códigos?';
    public bodyText = 'Desmarcaste la opción de adquirir pistola código de barra, recuerda que:';
    public textLabel = 'Sí, no deseo adquirir el Lector de códigos bluetooth';
    public listBody = [
        'Si vas a usar otra pistola código de barra, recuerda que 	este debe tener conexión bluetooth para funcionar 	tanto con nuestra aplicación como con el terminal'
    ];

    defaultData = {
        title: this.title,
        bodyText: this.bodyText,
        textLabel: this.textLabel,
        listBody: this.listBody
    };

    dataDialog = this.defaultData;

    constructor(
        private dialogRef: MatDialogRef<DialogCodeReaderComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (!!this.data) {
            this.dataDialog = this.data.dataDilaog ? this.data['dataDilaog'] : this.defaultData;
        }
    }

    ngOnInit() {
        if (!!this.data) {
            this.classList = this.data.name;
        }
    }

    saveAction(btn) {
        if (btn === '1') {
            this.dialogRef.close(true);
        } else if (btn === '2' && this.isChecked === true) {
            this.dialogRef.close(false);
        } else {
            this.dialogRef.close(true);
        }
    }
}
