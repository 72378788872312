import { Component } from '@angular/core';

@Component({
    selector: 'app-section-statistics',
    templateUrl: './section-statistics.component.html',
    styleUrls: ['./section-statistics.component.scss']
})
export class SectionStatisticsComponent {

}
