import { Component, ElementRef } from '@angular/core';
import { traceButton2 } from '../../../../../common/services/button-trace';
import { ScrollToClass } from '../../shared/scrollTo.class';

@Component({
    selector: 'app-partners-first-screen',
    templateUrl: './partners-first-screen.component.html',
    styleUrls: ['./partners-first-screen.component.scss']
})
export class PartnersFirstScreenComponent extends ScrollToClass {

    constructor(
        private _el: ElementRef
    ) {
        super(_el);
    }

    public navigateToSection(className: string): void {
        const element = document.querySelector(`.${className}`);
        setTimeout(() => {
            this.scrollToSection(className);
        }, 600);
        if (!this.isElementInView(element as HTMLElement)) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    public track(event: Event){
        traceButton2(event,'scroll');
    }

    private isElementInView(element: HTMLElement): boolean {
        const rect = element.getBoundingClientRect();
        const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
        const windowWidth = (window.innerWidth || document.documentElement.clientWidth);
    
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= windowHeight &&
          rect.right <= windowWidth
        );
      }

}
