import { Component, Input, OnInit } from '@angular/core';
import { traceButton2 } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-section-sii-certification',
    templateUrl: './section-sii-certification.component.html',
    styleUrls: ['./section-sii-certification.component.scss']
})
export class SectionSIICertificationComponent implements OnInit {
    @Input() public loadingImages: boolean;

    constructor() { }

    ngOnInit(): void {
    }

    goToUrl(url: string, event: Event): void {
        window.open(url, '_blank');
        traceButton2(event, 'navigate');
    }

}
