import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MetaService } from '@ngx-meta/core';
import { isPlatformBrowser } from '@angular/common';

import { urlEnv } from '../../environments/environment';
import { SEOService } from '../shared/services/seo.service';

@Component({
    selector: 'app-partners-page',
    templateUrl: './partners-page.component.html'
})
export class PartnersPageComponent implements OnInit {

    public urlEnv = urlEnv;

    public showImages1 = false;
    public showImages2 = false;
    public showImages3 = false;
    public showImages4 = false;

    constructor(
        private readonly _meta: MetaService,
        @Inject(PLATFORM_ID) private platformId: any,
        private seoService: SEOService
    ) { }

    public ngOnInit(): void {
        this._setMeta();
        this.seoService.updateCanonicalUrl('partner/');

        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 500);
    }

    private _setMeta() {
        this._meta.setTitle('Gana dinero con el Programa de Partners TUU ');
        this._meta.setTag('description', 'Con el Programa de Partners TUU gana dinero vendiendo máquinas de pago y boletas electrónicas');
        this._meta.setTag('keywords', 'Partners, partner, máquina de pagos, emprendimiento, comisión, máquina pos, TUU, ganancias, punto de venta, pagos con tarjetas, POS, máquina para pagar con tarjeta, pago con tarjeta, boleta electrónica, emprendedores');
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-partners.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Gana dinero con el Programa de Partners TUU ');
    }

}
