import { Component, Inject, Input, OnInit, PLATFORM_ID, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { PopUpComponent } from '../leaving-popup.component.TUU/leaving-popup.component.TUU';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-leaving-popup-wrapper',
  template: '',
  styleUrls: ['./leaving-popup-wrapper.component.scss'],
})
export class LeavingPopupWrapperComponent implements OnInit, OnChanges {
  @Input() route: string;
  @Input() blacklist: any;
  @Input() panelClassSelection: string;
  @Input() TLD: any;
  @Input() contactList: any;
  @Input() siteKey: any;
  @Input() API_POPUP: string;
  @Input() enabledLeavingPopUp: boolean;
  @Input() isDirect: boolean;
  @Input() reservaDialogOpened: boolean;
  @Output() emmiterLeavingDialogOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private _router: Router,
    public dialog: MatDialog,
    private deviceService: DeviceDetectorService
  ) { }
  public singleTrigger: boolean = true;
  ngOnChanges(changes: SimpleChanges) {
    if (changes['reservaDialogOpened']) {
      this.reservaDialogOpened = changes['reservaDialogOpened'].currentValue;
    }
  }
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const deviceType = this.deviceService.getDeviceInfo().deviceType;
      if (deviceType === 'desktop') this.trackingDesktop();
      else this.trackingMobile();
      document.addEventListener('disable-leaving-popup', (event) => {
        this.singleTrigger = false;
      });
    }
  }

  private async trackingMobile() {
    const inactivitySeconds = 60 * 1000;
    await this.delay(inactivitySeconds).then(() => {
      let passBlacklist = true;
      if (typeof (this.blacklist === 'object')) {
        this.blacklist.forEach((element) => {
          if (element === this._router.url) passBlacklist = false;
        });
      }
      if (this.singleTrigger && passBlacklist && !this.isDirect && !this.reservaDialogOpened) {
        this.cookieTrigger();
        this.singleTrigger = false;
      }
    });
  }

  private trackingDesktop() {
    let trigger: any;
    document.documentElement.addEventListener('mouseleave', (event) => {
      trigger = {
        event: event,
        exitTop: event.clientY < 10,
      };
      let passBlacklist = true;
      if (typeof (this.blacklist === 'object')) {
        this.blacklist.forEach((element) => {
          if (element === this._router.url) passBlacklist = false;
        });
      }
      if (trigger.exitTop && this.singleTrigger && passBlacklist && !this.isDirect && !this.reservaDialogOpened) {
        this.cookieTrigger();
        this.singleTrigger = false;
      }
    });
  }

  private delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private async cookieTrigger() {
    let gotCookie = this.CookieService.getCookieStatus();
    if (
      gotCookie.exitIntentShownDay === null &&
      gotCookie.exitTriesInWeek === null
    ) {
      this.CookieService.setCookie();
      this.waitForEnabledLeavingPopUp(this.panelClassSelection);
    } else if (
      gotCookie.exitIntentShownDay != 'true' &&
      JSON.parse(String(gotCookie.exitTriesInWeek)).tries < 3
    ) {
      this.CookieService.updateCookieWeek(
        JSON.parse(String(gotCookie.exitTriesInWeek))
      );
      this.waitForEnabledLeavingPopUp(this.panelClassSelection);
    }
  }

  private CookieService = {
    setCookie() {
      const expirationSeconds_Day = 60 * 60 * 24;
      const expirationSeconds_Week = 60 * 60 * 24 * 7;
      let date1 = new Date();
      date1 = new Date(date1.getTime() + 1000 * expirationSeconds_Day);
      let newCookie1 = 'exitIntentShownDay=' + true + '; expires=' + date1.toUTCString() + '; path=/;';
      document.cookie = newCookie1;

      let date2 = new Date();
      date2 = new Date(date2.getTime() + 1000 * expirationSeconds_Week);
      let newCookie2 = 'exitTriesInWeek=' + '{"tries":1,"expiration":"' + date2.toUTCString() + '"}' + '; expires=' + date2.toUTCString() + '; path=/;';
      document.cookie = newCookie2;
    },
    getCookieStatus() {
      const cookies = document.cookie.split(';');
      let returnValue = {
        exitIntentShownDay: null,
        exitTriesInWeek: null,
      };
      for (const cookie of cookies) {
        if (cookie.indexOf('exitIntentShownDay' + '=') > -1) {
          returnValue.exitIntentShownDay = cookie.split('=')[1];
        }
        if (cookie.indexOf('exitTriesInWeek' + '=') > -1) {
          returnValue.exitTriesInWeek = cookie.split('=')[1];
        }
      }
      return returnValue;
    },
    updateCookieWeek(cookieObject: any) {
      const expirationSeconds_Day = 60 * 60 * 24;
      let date1 = new Date();
      date1 = new Date(date1.getTime() + 1000 * expirationSeconds_Day);
      let newCookie1 =
        'exitIntentShownDay=' +
        true +
        '; expires=' +
        date1.toUTCString() +
        '; path=/;';
      document.cookie = newCookie1;
      let newCookie2 =
        'exitTriesInWeek=' +
        '{"tries":' +
        String(cookieObject.tries + 1) +
        ',"expiration":"' +
        cookieObject.expiration +
        '"}' +
        '; expires=' +
        cookieObject.expiration +
        '; path=/;';
      document.cookie = newCookie2;
    },
  };

  private waitForEnabledLeavingPopUp(panelClassSelection: string): void {
    const interval = setInterval(() => {
      if (this.enabledLeavingPopUp) {
        clearInterval(interval);
        if (panelClassSelection === 'panel-tuu') {
          
          const dialog = this.dialog.open(PopUpComponent, {
            hasBackdrop: true,
            disableClose: false,
            width: '720px',
            height: '720px',
            panelClass: panelClassSelection,
            data: {
              TLD: this.TLD,
              contactList: this.contactList,
              siteKey: this.siteKey,
              site: 'TUU',
              API_POPUP: this.API_POPUP,
            },
          });
          dialog.afterClosed().subscribe(() => {
            this.emmiterLeavingDialogOpen.emit(false);
          });

          this.emmiterLeavingDialogOpen.emit(true);
        } else {
          const dialog = this.dialog.open(PopUpComponent, {
            hasBackdrop: true,
            disableClose: false,
            //panelClass: panelClassSelection
          });
          dialog.afterClosed().subscribe(() => {
            this.emmiterLeavingDialogOpen.emit(false);
          });

          this.emmiterLeavingDialogOpen.emit(true);
        }
      }
    }, 500);
  }
}
