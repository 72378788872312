import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-section-geography',
    templateUrl: './section-geography.component.html',
    styleUrls: ['./section-geography.component.scss']
})
export class SectionGeographyComponent {
    @Input() public loadingImages: boolean;
}
