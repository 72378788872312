import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { traceButton2 } from '../../../../../common/services/button-trace';
import { ScrollToClass } from '../../shared/scrollTo.class';
import { urlMapping } from '../route-ids';

@Component({
    selector: 'app-news-feed',
    templateUrl: './news-feed.component.html',
    styleUrls: ['./news-feed.component.scss']
})
export class NewsFeedComponent extends ScrollToClass implements OnInit  {

    public url: string;

    ngOnInit(): void { 
        const urlTree = this.router.parseUrl(this.router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';
        this.url = urlMapping[urlSegment] || urlSegment;
    }
    constructor(
        private _el: ElementRef,
        private router: Router,
    ) { super(_el); }

    items = new Array(8).fill(0);

    redireccionarAMaquinas(event: Event) {
        traceButton2(event, "scroll");
        // Navega a la ruta 'home' con el fragmento 'sectionPrice'
        this.router.navigate(['/']).then(() => {
            setTimeout(() => {
                this.scrollToSection("sectionPrice");
            }, 500); // Espera antes de realizar el scroll
        });
        this.scrollToSection("sectionPrice");
    }
}