import { Component, Input } from '@angular/core';
import { traceButton2 } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-section-sales',
    templateUrl: './section-sales.component.html',
    styleUrls: ['./section-sales.component.scss']
})
export class SectionSalesComponent {
    @Input() public loadingImages: boolean;

    public onClick(event: Event): void {
        traceButton2(event, 'navigate');
    }
}
