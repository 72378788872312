import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-pos-management',
    templateUrl: './pos-management.component.html',
    styleUrls: ['./pos-management.component.scss']
})
export class PosManagementComponent implements OnInit {
    @Input() public loadingImages: boolean;

    public posManagementList = [
        {
            text: '<b>Activa tu POS</b> <br> a distancia',
            iconName: 'icon-sprite-cloud',
            iconSize: 24
        },
        {
            text: 'Organizalos por su <br> <b>ubicación</b>',
            iconName: 'icon-sprite-map-point',
            iconSize: 24
        },
        {
            text: '<b>Personaliza tus dispositivos</b> <br> con un nombre',
            iconName: 'icon-sprite-premium',
            iconSize: 24
        },
        {
            text: 'Maneja las <b>aplicaciones <br> vinculadas</b>',
            iconName: 'icon-sprite-screen',
            iconSize: 24
        }
    ];
    constructor() { }

    ngOnInit(): void {
    }

}
