import { Component } from '@angular/core';

@Component({
    selector: 'app-section-faq-partners',
    templateUrl: './section-faq-partners.component.html',
    styleUrls: ['./section-faq-partners.component.scss']
})
export class SectionFaqPartnersComponent {

    public items = [
        {
            title: '¿Quién determina los precios de los productos que vendo?',
            text: 'Como revendedor el valor del equipo será el mismo del sitio web. Para los otros modelos de Partner tu decides el precio final de los equipos, así mismo el valor de la comisión. Tú decides cuanto ganas.'
        }, {
            title: '¿Cómo puedo manejar las cuentas de mis clientes?',
            text: 'A través del panel de control de Haulmer Partners podrás gestionar todos los aspectos de la cuenta de tus clientes: contratar planes, compras de equipos e insumos, ver el estadus de los cobros, añadir nuevos servicios a las cuentas de tus clientes, entre muchas otras cosas.'
        }, {
            title: '¿Sabrá el cliente el periodo por el que su servicio fue contratado?',
            text: 'Sí, se le comunicará al cliente el periodo por el que fue contratado su servicio para que este tenga conocimiento de cuando es momento de renovar su suscripción.'
        }, {
            title: '¿Cuándo recibiré mis pagos de comisiones?',
            text: 'Finalizando cada mes, recibirás un reporte con el monto de tu comisión y el detalle de estas, además en el mismo te indicaremos los pasos a seguir para recibir tu pago.'
        }
    ];

    public activePanel = -1;

    public openPanel(index): void {
        setTimeout(() => {
            this.activePanel = index;
        }, 0);

    }

}
