import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';

@Component({
    selector: 'app-slider-quotas',
    templateUrl: './slider-quotas.component.html',
    styleUrls: ['./slider-quotas.component.scss']
})
export class SliderQuotasComponent {
    @ViewChild('sliderContainer') public sliderContainer: ElementRef;

    public data = [
        {
            pay: 250000,
            quotas: 6,
            cards: [267789, 276896],
            saveValue: 9107
        },
        {
            pay: 1000000,
            quotas: 12,
            cards: [1134715, 1205545],
            saveValue: 70830
        }
    ];

    public currentIndex = 0;

    constructor(private _renderer: Renderer2) { }

    public prev(): void {
        if (this.currentIndex > 0) {
            this.currentIndex--;

            if (this.currentIndex > 1) {
                this._renderer.setAttribute(
                    this.sliderContainer.nativeElement,
                    'style',
                    `transform: translate(-${this.currentIndex * this.sliderContainer.nativeElement.children[0].offsetWidth}px)`
                );
            } else {
                this._renderer.setAttribute(
                    this.sliderContainer.nativeElement,
                    'style',
                    `transform: translate(-${this.currentIndex * this.sliderContainer.nativeElement.children[0].offsetWidth}px)`
                );
            }
        }
    }

    public next(): void {
        if (this.data.length - 1 > this.currentIndex) {
            this.currentIndex++;

            this._renderer.setAttribute(
                this.sliderContainer.nativeElement,
                'style',
                `transform: translate(-${this.currentIndex * this.sliderContainer.nativeElement.children[0].offsetWidth}px)`
            );
        }
    }
}
