import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IMatMenuStatus{
    isOpenMat1: boolean
    isOpenMat2: boolean
    isOpenMat3: boolean
    isOpenMat4: boolean
}

@Injectable({providedIn:'root'})
export class panelStatus{
    private matMenu = new BehaviorSubject<IMatMenuStatus>({
        isOpenMat1: false,
        isOpenMat2: false,
        isOpenMat3: false,
        isOpenMat4: false
    })
    public matMenu$ = this.matMenu.asObservable();

    public getValue(MatSelector: string): boolean{
        return this.matMenu.value[MatSelector]
    }

    public managePanel(MatSelector: string): void{
        
        switch (MatSelector) {
            case 'isOpenMat1':
                this.matMenu.value.isOpenMat1 = !this.matMenu.value.isOpenMat1;
                this.matMenu.value.isOpenMat2 = false;
                this.matMenu.value.isOpenMat3 = false;
                this.matMenu.value.isOpenMat4 = false;
                break;
            case 'isOpenMat2':
                this.matMenu.value.isOpenMat1 = false;
                this.matMenu.value.isOpenMat2 = !this.matMenu.value.isOpenMat2;
                this.matMenu.value.isOpenMat3 = false;
                this.matMenu.value.isOpenMat4 = false;
                break;
            case 'isOpenMat3':
                this.matMenu.value.isOpenMat1 = false;
                this.matMenu.value.isOpenMat2 = false;
                this.matMenu.value.isOpenMat3 = !this.matMenu.value.isOpenMat3;
                this.matMenu.value.isOpenMat4 = false;  
                break;
            case 'isOpenMat4':
                this.matMenu.value.isOpenMat1 = false;
                this.matMenu.value.isOpenMat2 = false;
                this.matMenu.value.isOpenMat3 = false;
                this.matMenu.value.isOpenMat4 = !this.matMenu.value.isOpenMat4;
                break;
            default:
                break;
        }
    }

    constructor() {
    }
}