import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-button-whatsapp',
  templateUrl: './button-whatsapp.html',
  styleUrls: ['./button-whatsapp.scss']
})

export class ButtonWhatsapp implements OnInit {
  public visible: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if(!(location.pathname.includes('/codigo-amigo'))&&!(location.pathname.includes('/directa'))) {
        setTimeout(() => {
          this.visible = true;
        }, 3000);
      }
    }
  }

  public openWhatsapp(): void {
    window.open('https://wa.me/56930834861?text=Quiero+conocer+más+información+de+planes+y+servicios', '_blank');
  }
}