import { Component, OnInit, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-see-product',
    templateUrl: './dialog-see-product.component.html',
    styleUrls: ['./dialog-see-product.component.scss']
})
export class DialogSeeProductComponent implements OnInit {
    @HostBinding('class') classList = 'default';

    optionsList = [
        {
            text: 'Conexión Bluetooth 4.0 compatible con Android',
            icon: 'icon-sprite-bluetooth'
        }, {
            text: 'Adaptador AC - 110-240V∼  50/60Hz',
            icon: 'icon-sprite-buttery'
        }, {
            text: 'Alta velocidad de<br> impresión (50 - 80 mm/s)',
            icon: 'icon-sprite-lightning'
        }, {
            text: 'Soporta rollos de papel<br> de 58mm de ancho',
            icon: 'icon-sprite-rolls'
        }, {
            text: 'Funda protectora',
            icon: 'icon-sprite-security'
        }, {
            text: 'Garantía de 90 días',
            icon: 'icon-sprite-guarantee'
        }
    ];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        if (!!this.data) {
            this.classList = this.data.name;
        }
    }
}
