import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-section-subscriptions',
    templateUrl: './section-subscriptions.component.html',
    styleUrls: ['./section-subscriptions.component.scss']
})
export class SectionSubscriptionsComponent {
    @Input() loadingImages: boolean;

}
