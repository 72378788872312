import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-card-total',
    templateUrl: './card-total.component.html',
    styleUrls: ['./card-total.component.scss']
})
export class CardTotalComponent {
    @Input() public price;
    @Input() public priceAnnually;
    @Input() public url;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any
    ) {
    }

    public goToUrl(): void {
        window.open(this.url, '_blank');
    }

    public scrollTop(): void {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    public isDeliveryFree(): boolean {

        let products = this.url.split('?')[1].split('&');
        products = products.map(x => x.split('=')[1]).map(y => y.split(','));
        const qtyKeyProducts = {};
        const arrayQty = products[1];
        const arrayProducts = products[0];
        if (arrayQty.length === arrayProducts.length) {
            arrayProducts.forEach((element, index) => {
                qtyKeyProducts[element] = arrayQty[index];
            });
        }
        if (
            (
                (
                    !!qtyKeyProducts['F1'] ||
                    !!qtyKeyProducts['F2'] ||
                    !!qtyKeyProducts['F3']
                ) && (
                    Object.keys(qtyKeyProducts).length === 1 ||
                    (
                        (this.price - (qtyKeyProducts['F1'] * 11790)) >= 12000 ||
                        (this.price - (qtyKeyProducts['F2'] * 16790)) >= 12000 ||
                        (this.price - (qtyKeyProducts['F3'] * 19790)) >= 12000
                    )
                )
            ) ||
            (
                this.price >= 12000 &&
                (
                    !qtyKeyProducts['F1'] &&
                    !qtyKeyProducts['F2'] &&
                    !qtyKeyProducts['F3']
                )
            )
        ) {
            return true;
        }
        return false;
    }

}
