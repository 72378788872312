import { Component, ElementRef, Input } from '@angular/core';

import { ScrollToClass } from '../../shared/scrollTo.class';
import { traceButton2 } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-home-first-screen',
    templateUrl: './home-first-screen.component.html',
    styleUrls: ['./home-first-screen.component.scss']
})
export class HomeFirstScreenComponent extends ScrollToClass {

    @Input() public url:string;

    constructor(
        private _el: ElementRef
    ) {
        super(_el);
    }

    public navigateToSection(className: string, event: Event): void {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 200);
        traceButton2(event, 'scroll');
    }

}
