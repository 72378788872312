import { Component, Input } from '@angular/core';
import { traceButton2 } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-section-offers-free',
    templateUrl: './section-offers-free.component.html',
    styleUrls: ['./section-offers-free.component.scss']
})
export class SectionOffersFreeComponent {
    @Input() public loadingImages: boolean;
    @Input() public isDirect: boolean;

    public isShowVideo: boolean = false;

    public list = [
        {
            id: 't-home-todo-ver-pagos',
            icon: 'icon-sprite-card-circle',
            title: 'Acepta pagos.',
            list: [
                'Acepta pagos con tarjetas de débito, crédito, prepago y aplicaciones.',
                'Acepta pagos en cuotas.'
            ],
            route: '/pago'
        },
        {
            id: 't-home-todo-ver-boleta',
            icon: 'icon-sprite-file-copy',
            title: 'Emite documentos electrónicos.',
            list: [
                'Emite boletas electrónicas, facturas y más.',
                'Sin límite de emisión.'
            ],
            route: '/boleta-electronica'
        },
        {
            id: 't-home-todo-ver-reserva',
            icon: 'icon-sprite-calendar-dots',
            title: 'Agenda de horas.',
            list: [
                'Crea una web para que tus clientes reserven citas.',
                'Envía recordatorios de horas.'
            ],
            route: '/tuu-reserva'
        },
        {
            id: 't-home-todo-ver-inventario',
            icon: 'icon-sprite-qrcode-scan',
            title: 'Inventario de productos.',
            list: [
                'Sincroniza el stock de tus productos con tu máquina POS.',
                'Cuenta con stock negativo y no te limites por falta de stock.'
            ],
            route: '/catalogo'
        }
    ]

    public track(event: Event): void {
        traceButton2(event, 'navigate');
    }

}
