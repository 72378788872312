import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';

import { ScrollToClass } from '../../shared/scrollTo.class';

@Component({
    selector: 'app-section-sales-process',
    templateUrl: './section-sales-process.component.html',
    styleUrls: ['./section-sales-process.component.scss']
})
export class SectionSalesProcessComponent extends ScrollToClass implements OnInit {
    @Input() public loadingImages: boolean;
    @ViewChild('animationContainer') public animationContainer: ElementRef;

    stepsArr = [
        {
            title: 'Crea TUU catálogo',
            text: 'En tu espacio de trabajo crea uno o más catálogos de productos con SKU ilimitados'
        },
        {
            title: 'Crea tus productos',
            text: 'Crea productos sin límites y personaliza cada detalle incluyendo el precio, código de barra, cantidad en stock existente y más.'
        },
        {
            title: 'Agrega productos a la venta',
            text: 'Elige tus productos directo desde el catálogo o utiliza la pistola para escanear los códigos de barra que asignaste a cada uno.'
        },
        {
            title: '¡Acepta el pago y listo!',
            text: 'Antes de finalizar puedes revisar los productos agregados, editar la cantidad, aplicar descuentos o eliminarlos. '
        }
    ];

    settings = {
        timer: null,
        startAnimation: false,
        currentIndex: null,
        items: null,
        duration: null
    };


    @HostListener('window:scroll')
    onScroll(): void {
        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                this.startAnimation();
            }
        }, 0);
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private _el: ElementRef
    ) {
        super(_el);
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                this.initSettings();
            }
        }, 0);
    }

    initSettings(): void {
        this.settings = {
            timer: null,
            startAnimation: false,
            currentIndex: null,
            items: this.animationContainer.nativeElement.getElementsByClassName('item-ts'),
            duration: window.innerWidth > 767 ? 4000 : 3000
        };
    }

    startAnimation(): void {
        const offsetTrigger = window.innerWidth > 767 ? (window.innerHeight / 2) : 56;
        let newIndex = null;

        for (let i = 0; i <= this.settings.items.length - 1; i++) {
            if (this.settings.items[i].getBoundingClientRect().top <= offsetTrigger ||
                this.settings.items[i].getBoundingClientRect().bottom < window.innerHeight) {
                newIndex = i;
            } else {
                this.settings.items[i].classList.remove('animate');
            }
        }

        this.settings.startAnimation = newIndex !== null;
        this.settings.currentIndex = newIndex;

        if (this.settings.startAnimation) {
            if (this.settings.items[this.settings.currentIndex].classList.contains('animate')) {
                return;
            }

            for (let i = 0; i < this.settings.currentIndex; i++) {
                this.settings.items[i].classList.remove('animate');
            }

            this.settings.items[this.settings.currentIndex].classList.add('animate');
            this.setTimer();
        } else {
            this.settings.currentIndex = null;
            clearInterval(this.settings.timer);
            this.settings.timer = null;

            for (let i = 0; i <= this.settings.items.length - 1; i++) {
                this.settings.items[i].classList.remove('animate');
            }
        }
    }

    setTimer(): void {
        clearInterval(this.settings.timer);
        this.settings.timer = null;

        this.settings.timer = setInterval(() => {
            if (this.settings.startAnimation) {

                if (this.settings.currentIndex < this.settings.items.length - 1) {

                    for (let i = 0; i <= this.settings.currentIndex; i++) {
                        this.settings.items[i].classList.remove('animate');
                    }

                    this.settings.currentIndex++;
                    this.settings.items[this.settings.currentIndex].classList.add('animate');

                    const itemRect = this.settings.items[this.settings.currentIndex].getBoundingClientRect();
                    const offsetTrigger = window.innerWidth > 767 ? (window.innerHeight / 2) : 56;

                    if (itemRect.top > offsetTrigger || itemRect.bottom < (window.innerHeight / 2)) {
                        // scroll to next Item
                        this.scrollToSection('animate', 500, (itemRect.top - (window.innerHeight / 2) + (itemRect.height / 2)));
                    }
                }
            }
        }, this.settings.duration);
    }
}
