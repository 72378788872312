import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-plans-table',
    templateUrl: './plans-table.component.html',
    styleUrls: ['../../../../assets/sass/components/_plans-table.component.scss']
})
export class PlansTableComponent implements OnInit {
    @Input() data;
    @Input() type = 1;

    constructor() {
    }

    ngOnInit(): void {
        if (this.type === 1) {
            this.addColumnStyles();
        }
    }

    isNumber(val): boolean { return typeof val === 'number'; }

    repeatColumn(rows): string {
        let columns = '';

        for (let i = 0; i < this.maxColumnsLength(rows); i ++) {
            columns = columns + ' 1fr';
        }

        return columns;
    }

    maxColumnsLength(rows): number {
        let maxLength = 0;

        for (let i = 0; i < rows.length; i++) {
            maxLength = rows[i].columns.length > maxLength ? rows[i].columns.length : maxLength;
        }

        return maxLength;
    }

    addColumnStyles(): void {
        this.data.body.forEach(plan => {

            // periods (rows) in the plan, starting from the second
            for (let comparisonIndex = 1; comparisonIndex < plan.rows.length; comparisonIndex++) {

                // columns
                for (let columnIndex = 2; columnIndex < plan.rows[comparisonIndex].columns.length; columnIndex++) {
                    const currentValue = plan.rows[comparisonIndex - 1].columns[columnIndex].value;
                    const comparisonValue = plan.rows[comparisonIndex].columns[columnIndex].value;
                    plan.rows[comparisonIndex - 1].columns[columnIndex].rowEnd = comparisonIndex + 1;

                    if (currentValue === comparisonValue) {
                        // Add 'hide' key in case of a repeated value in the corresponding column
                        plan.rows[comparisonIndex].columns[columnIndex].hide = true;
                        // Find duplicated value
                        const duplLength = plan.rows.filter(row => row.columns[columnIndex].hide === true).length;
                        // Update key 'rowEnd'
                        plan.rows[comparisonIndex - duplLength].columns[columnIndex].rowEnd =
                            comparisonIndex - duplLength + 2 + duplLength;

                        // Add item grid styles
                        plan.rows[comparisonIndex - 1].columns[columnIndex].styles = {
                            'grid-column-start': columnIndex + 1,
                            'grid-row-start': comparisonIndex
                        };
                    }
                }
            }
        });
    }
}
