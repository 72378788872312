import { Component } from '@angular/core';


@Component({
    selector: 'app-section-catalog-benefits',
    templateUrl: './section-catalog-benefits.component.html',
    styleUrls: ['./section-catalog-benefits.component.scss']
})
export class SectionCatalogBenefitsComponent {

    public benefitsList1 = [
        {
            icon: 'icon-sprite-clipboard',
            iconSize: 40,
            text: '<b>Catálogo con SKU Ilimitados, </b> tus productos en un solo lugar.'
        }, {
            icon: 'icon-sprite-weight',
            iconSize: 44,
            text: 'Vende en kilos, fracciones y otras <b>unidades de medida.</b>',
        }, {
            icon: 'icon-sprite-percent',
            iconSize: 36,
            text: '<b>Asocia los impuestos disponibles</b> en Chile, como bebidas alcohólicas entre otros.'
        }
    ];

    public benefitsList2 = [
        {
            icon: 'icon-sprite-inventary',
            iconSize: 40,
            text: '<b>Inventario</b> sincronizado con tus catálogos.'
        }, {
            icon: 'icon-sprite-pistol',
            iconSize: 42,
            text: 'Escanea tus productos <b>para agilizar tus ventas</b>'
        }, {
            icon: 'icon-sprite-grid',
            iconSize: 38,
            text: 'Crea más de un catálogo con la <b>opción multicatálogo.</b>'
        }
    ];
}
