import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';

interface SliderOptions {
    container: any;
    items: any;
    itemWidth: any;
    startPos: number;
    btnPrev?: any;
    btnNext?: any;
}

@Component({
    selector: 'app-section-reviews',
    templateUrl: './section-reviews.component.html',
    styleUrls: ['./section-reviews.component.scss'],
})
export class SectionReviewsComponent implements OnInit {
    @ViewChild('slider') public slider: ElementRef;
    @Input() public showImages: boolean;

    public reviews = [
        {
            img: 'jo-escobar',
            title: '+20.000 negocios prefieren TUU',
            text: 'Con TUU nos quedamos de aquí en adelante. Siento que es el mejor sistema de pagos con el que pudimos dar.',
            name: 'Jo Escobar.',
            position: 'CEO Kosmetic Store'
        }, {
            img: 'lorena-mardonez',
            title: '+20.000 negocios prefieren TUU',
            text: 'Con la aplicación nos dimos cuenta que llevábamos todo super ordenado. Al abrir nuestra tienda no dudamos en adquirir una máquina. ',
            name: 'Lorena Mardonez',
            position: 'Fundadora Cabritalegre'
        }
    ];

    public sliderProps: SliderOptions;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any
    ) { }

    ngOnInit(): void { }

    public ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                this.initSlider();
            }, 100);
        }
    }

    public initSlider(): void {
        const parrent = this.slider.nativeElement;
        this.sliderProps = {
            container: <HTMLElement>parrent.getElementsByClassName('inner-ts')[0],
            items: <HTMLCollection>parrent.getElementsByClassName('item-ts'),
            itemWidth: <HTMLCollection>parrent.getElementsByClassName('item-ts')[0].getBoundingClientRect().width,
            startPos: 0,
            btnPrev: <HTMLElement>parrent.getElementsByClassName('prev-ts')[0],
            btnNext: <HTMLElement>parrent.getElementsByClassName('next-ts')[0],
        };
    }

    public next(): void {
        if (this.sliderProps.startPos < this.sliderProps.items.length - 1) {
            this.sliderProps.startPos++;
            this.sliderProps.container.style =
                'transform: translateX(-' + (this.sliderProps.itemWidth * this.sliderProps.startPos) + 'px)';
        }
    }

    public prev(): void {
        if (this.sliderProps.startPos > 0) {
            this.sliderProps.startPos--;
            if (this.sliderProps.startPos > 0) {
                this.sliderProps.container.style =
                    'transform: translateX(-' + (this.sliderProps.itemWidth * this.sliderProps.startPos) + 'px)';
            } else {
                this.sliderProps.container.style =
                    'transform: translateX(' + (this.sliderProps.itemWidth * this.sliderProps.startPos) + 'px)';
            }
        }
    }

}
