import { Component, ElementRef, OnInit } from '@angular/core';
import { traceButton2 } from '../../../../../common/services/button-trace';
import { ScrollToClass } from '../../shared/scrollTo.class';

@Component({
    selector: 'app-payment-first-screen',
    templateUrl: './payment-first-screen.component.html',
    styleUrls: ['./payment-first-screen.component.scss']
})
export class PaymentFirstScreenComponent extends ScrollToClass implements OnInit {

    constructor(private _el: ElementRef) {
        super(_el);
    }

    ngOnInit(): void {
    }

    public navigateToSection(className: string, event: Event): void {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 500);
        traceButton2(event, 'scroll')
    }

}
