import { AfterViewInit, Component, OnInit} from '@angular/core';
@Component({
    selector: 'app-seguimiento-error-page',
    templateUrl: './seguimiento-error.component.html',
    styleUrls: ['../seguimiento-common/seguimiento-styles.component.scss']
})
export class SeguimientoErrorPageComponent implements OnInit, AfterViewInit {
    constructor() { }
    public ngOnInit(): void {
    }
    public ngAfterViewInit(): void {
    }
}
