import { Component } from '@angular/core';

@Component({
    selector: 'app-payment-logos',
    templateUrl: './payment-logos.component.html',
    styleUrls: ['./payment-logos.component.scss']
})
export class PaymentLogosComponent {

}
