import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-offer-for-clients',
    templateUrl: './offer-for-clients.component.html',
    styleUrls: ['./offer-for-clients.component.scss']
})
export class OfferForClientsComponent {
    @Input() public loadingImages: boolean;

}
