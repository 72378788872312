import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-section-adjustment-inventory',
    templateUrl: './section-adjustment-inventory.component.html',
    styleUrls: ['./section-adjustment-inventory.component.scss']
})
export class SectionAdjustmentInventoryComponent implements OnInit {
    @Input() public loadingImages: boolean;

    public list = [
        '<b>Reporta hurtos o pérdidas</b> de tus productos.',
        'Identifica daños o <b>errores en la calidad de lo que vendes.</b>',
        '<b>Modifica el valor o la cantidad</b> de todos tus productos en stock.'
    ];

    constructor() { }

    ngOnInit(): void {
    }

}
