import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';

import { IPricingCard } from './pricing-card/pricing-card.component';
import { DATA_CARD_PRICE } from './data-price';

@Component({
    selector: 'app-section-price',
    templateUrl: './section-price.component.html',
    styleUrls: ['./section-price.component.scss']
})
export class SectionPriceComponent implements OnInit {
    @Input() public loadingImages: boolean;
    @Input() public dataCard: any;
    @Input() public onlyBoleta: boolean = false;
    @Input() public heading?: { title: string; subtitle: string; };
    @Input() public isDirect: boolean;
    isPurchaseActive = true;

    public cardsTab1: Array<IPricingCard> = DATA_CARD_PRICE;

    public cardTab2: IPricingCard = {
        device: 'device-p2-mini',
        features: [
            {
                icon: 'icon-chip-tuu',
                name: 'Chip de <br>Internet'
            }
        ],
        title: 'Mini',
        price: 39900,
        button: {
            id: 'TUU-mini-H-precios-compra-comision',
            text: 'Comprar',
            path: '/comprar-mini',
            queryParams: true
        },
        list: [
            {
                icon: 'icon-sprite-card-atm',
                text: '<b>Acepta tarjetas</b> con banda</br> magnética, chip y sin contacto.'
            },
            {
                icon: 'icon-sprite-file',
                text: '<b>Recibe pago con tarjetas</b> y emite<br/> voucher válido como boleta<br/> electrónica.'
            },
            {
                icon: 'icon-sprite-sim-card',
                text: '<b>Chip de internet</b> con gigas libres.'
            },
            {
                icon: 'icon-sprite-battery',
                text: '<b>Batería</b> de larga duración.'
            }
        ]
    }

    public dataCommissions = [
        {
            days: 1,
            cards: [
                {
                    commission: '1,99'
                },
                {
                    commission: '1,79',
                    saving: '0,20'
                }
            ]
        },
        {
            days: 2,
            cards: [
                {   
                    //before: '1,96',
                    commission: '1,96',
                    saving: '0,03'

                },
                {
                    commission: '1,77',
                    saving: '0,22'
                }
            ]
        },
        {
            days: 5,
            cards: [
                {
                    commission: '1,87',
                    saving: '0,12'

                },
                {
                    commission: '1,71',
                    saving: '0,28'
                }
            ]
        },
        {
            days: 10,
            cards: [
                {
                    commission: '1,61',
                    saving: '0,38'

                },
                {
                    commission: '1,61',
                    saving: '0,38'
                }
            ]
        },
        {
            days: 15,
            cards: [
                {
                    commission: '1,40',
                    saving: '0,59'

                },
                {
                    commission: '1,40',
                    saving: '0,59'
                }
            ]
        },
        {
            days: 25,
            cards: [
                {
                    commission: '0,98',
                    saving: '1,01'

                },
                {
                    commission: '0,98',
                    saving: '1,01'
                }
            ]
        }
    ];

    constructor(@Inject(PLATFORM_ID) private platformId: any) { }

    public ngOnInit(): void {
    }

    public scrollTop(): void {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

}
