import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-parking-advantages',
    templateUrl: './parking-advantages.component.html',
    styleUrls: ['./parking-advantages.component.scss']
})
export class ParkingAdvantagesComponent {
    @Input() public loadingImages: boolean;

}
