import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-reserve-for-everyone',
    templateUrl: './reserve-for-everyone.component.html',
    styleUrls: ['./reserve-for-everyone.component.scss'],
})
export class ReserveForEveryoneComponent {
    @Input() public showImages: boolean;

    public list = [
        {
            img: 'latido-del-corazon',
            text: 'Salud y bienestar'
        }, {
            img: 'masaje-facial',
            text: 'Belleza y estética'
        }, {
            img: 'ping-pong',
            text: 'Centros deportivos'
        }, {
            img: 'veterinario',
            text: 'Veterinarias'
        }, {
            img: 'casa',
            text: 'Hoteles y hostales'
        }, {
            img: 'papel-picado',
            text: 'Eventos'
        }, {
            img: 'portapapeles',
            text: 'Educación'
        }, {
            img: 'director',
            text: 'Profesionales'
        }, {
            img: 'aplicacion',
            text: 'Otros'
        }
    ];
}
