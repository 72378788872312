import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dialog-sinature',
    templateUrl: './dialog-sinature.component.html',
    styleUrls: ['./dialog-sinature.component.scss']
})
export class DialogSinatureComponent implements OnInit {

    constructor() {
    }

    public ngOnInit(): void {
    }
}
