import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-steps-to-reseller',
    templateUrl: './steps-to-reseller.component.html',
    styleUrls: ['./steps-to-reseller.component.scss']
})
export class StepsToResellerComponent {
    @Input() public loadingImages: boolean;

    public steps = [
        {
            icon: 'icon-sprite-interactive',
            title: 'Regístrate',
            text: 'Regístrate, seleccionando categoría estándar o pro.'
        },
        {
            icon: 'icon-sprite-envelope',
            title: 'Obtén TUU código',
            text: 'Recibirás un correo de confirmación y tu código de venta.'
        },
        {
            icon: 'icon-sprite-headset',
            title: 'Capacítate',
            text: 'Agenda tu capacitación online inicial.'
        },
        {
            icon: 'icon-sprite-attach-money',
            title: '¡Vende!',
            text: 'Comienza a vender y haz crecer tus ingresos.'
        }
    ];

}
