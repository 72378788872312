import { Component } from '@angular/core';

@Component({
    selector: 'app-parking-faq',
    templateUrl: './parking-faq.component.html',
    styleUrls: ['./parking-faq.component.scss']
})
export class ParkingFaqComponent {

    public itemsFAQ = [
        {
            title: '¿Por qué es gratis?',
            text: 'Tanto la APP como la emisión de documentos tributarios electrónicos serán gratuitos siempre y cuando proceses al menos un 15% de tus pagos con tarjetas en TUU POS.'
        }, {
            title: '¿Puedo aceptar pagos con efectivo?',
            text: 'Sí, además de recibir pagos con cualquier tipo de tarjeta también podrás recibir pagos en efectivo. Aunque recuerda que al menos un 15% de los pagos que recibas deben ser hechos con tarjetas.'
        }, {
            title: '¿Puedo usar TUU Estacionamiento en otro dispositivo?',
            text: 'No, nuestra APP funciona sólo en terminales de pago TUU. Si aún no tienes uno puedes comprarlo en www.tuu.cl'
        }, {
            title: '¿Puedo integrar otros dispositivos con TUU Estacionamiento?',
            text: 'La versión actual de la aplicación no es compatible con otros dispositivos como barreras o lectores de patentes.'
        }, {
            title: '¿El terminal POS TUU tiene garantía?',
            text: 'Los terminales POS de TUU tienen una garantía extendida de 2 años.'
        }
    ];

    public activePanel = -1;

    public openPanel(index): void {
        setTimeout(() => {
            this.activePanel = index;
        }, 0);

    }

}
