import { Component } from '@angular/core';

@Component({
    selector: 'app-price-first-screen',
    templateUrl: './price-first-screen.component.html',
    styleUrls: ['./price-first-screen.component.scss']
})
export class PriceFirstScreenComponent {

}
