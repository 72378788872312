export interface Product {
    product: {
        name?: string;
        title?: string;
        subtext?: string;
        note?: string;
        subproduct?: {
            title?: string;
            text?: string;
        }
    };
    table: {
        header: Array<string>;
        body: Array<Plans>;
    };
}
export interface Plans {
    plan: {
        name: string;
        list?: Array<string>;
        subtext?: string;
    };
    rows: Array<Period>;
}
export interface Period {
    columns: Array<{ value: string | number; sale?: number }>;
}

export const DATA_PRODUCTS: Array<Product> = [
    {
        product: {
            name: 'Pagos',
            title: 'tuu',
            subtext: 'Recibe pagos con tarjetas y controla tu negocio desde un solo lugar, con abonos desde 1 día hábil y con comisiones bajas garantizadas.'
        },
        table: {
            header: [
                'Plan',
                'Ciclo',
                'Precio lista',
                ' ',
                'Precio en valores anuales'
            ],
            body: [
                {
                    plan: {
                        name: 'TUU'
                    },
                    rows: [
                        {
                            columns: [
                                {
                                    value: 'Anual'
                                },
                                {
                                    value: 0
                                },
                                {
                                    value: 0,
                                    sale: 0
                                },
                                {
                                    value: 0,
                                    sale: 0
                                }
                            ]
                        }
                    ]
                },
                {
                    plan: {
                        name: 'TUU++'
                    },
                    rows: [
                        {
                            columns: [
                                {
                                    value: 'Anual'
                                },
                                {
                                    value: 180000
                                },
                                {
                                    value: 90000,
                                    sale: 50
                                },
                                {
                                    value: 90000,
                                    sale: 50
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    },
    // {
    //     product: {
    //         name: 'Terminal SE',
    //         title: 'MAQUINA POS:',
    //         subtext: 'Dispositivo requerido para utilizar tus servicios y aplicaciones Haulmer, especialmente con el servicio TUU para recibir pagos con tarjetas.'
    //     },
    //     table: {
    //         header: [
    //             'Plan',
    //             'Ciclo',
    //             'Precio lista',
    //             ' ',
    //             ' '
    //         ],
    //         body: [
    //             {
    //                 plan: {
    //                     name: 'Terminal',
    //                     list: [
    //                         'Acepta tarjetas con banda magnética, chip y Contactless®.',
    //                         'Impresora térmica integrada de alta velocidad.',
    //                         'Conexión Wifi y soporte  Bluetooth.',
    //                         'Batería de 12hrs de  duración.'
    //                     ]
    //                 },
    //                 rows: [
    //                     {
    //                         columns: [
    //                             {
    //                                 value: 'Pago Único'
    //                             },
    //                             {
    //                                 value: 99900
    //                             },
    //                             {
    //                                 value: 99900,
    //                                 sale: 0
    //                             },
    //                             {
    //                                 value: 155000,
    //                                 sale: 14
    //                             }
    //                         ]
    //                     }
    //                 ]
    //             }
    //         ]
    //     }
    // },
    {
        product: {
            title: 'COMPLEMENTOS',
            subtext: 'Potencia el servicio de tus clientes con los mejores implementos.',
            note: '(*)EL tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado con otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.'
        },
        table: {
            header: [
                'Plan',
                'Ciclo',
                'Precio lista',
                ' ',
                ' '
            ],
            body: [
                // {
                //     plan: {
                //         name: 'Impresora Térmica Bluetooth PT-210 58mm',
                //         list: ['Imprime documentos electrónicos desde tu Aplicación.']
                //     },
                //     rows: [
                //         {
                //             columns: [
                //                 {
                //                     value: 'Pago único'
                //                 },
                //                 {
                //                     value: 40000
                //                 },
                //                 {
                //                     value: 40000,
                //                     sale: 0
                //                 },
                //                 {
                //                     value: 38000,
                //                     sale: 5
                //                 }
                //             ]
                //         }
                //     ]
                // },
                // {
                //     plan: {
                //         name: 'Pistola código de barra Bluetooth 1D / 2D',
                //         list: ['Ingresa productos con nuestro lector automáticamente.']
                //     },
                //     rows: [
                //         {
                //             columns: [
                //                 {
                //                     value: 'Pago único'
                //                 },
                //                 {
                //                     value: 50000
                //                 },
                //                 {
                //                     value: 50000,
                //                     sale: 0
                //                 },
                //                 {
                //                     value: 47500,
                //                     sale: 5
                //                 }
                //             ]
                //         }
                //     ]
                // },
                {
                    plan: {
                        name: '200 rollos de papel térmico 58mm',
                        list: ['Utiliza el papel térmico en tu Terminal PRO.']
                    },
                    rows: [
                        {
                            columns: [
                                {
                                    value: 'Pago único'
                                },
                                {
                                    value: 66000
                                },
                                {
                                    value: 66000,
                                    sale: 0
                                },
                                {
                                    value: 52800,
                                    sale: 20
                                }
                            ]
                        }
                    ]
                },
                {
                    plan: {
                        name: 'Chip 4G Internet',
                        list: ['Entrega conexión a internet.*']
                    },
                    rows: [
                        {
                            columns: [
                                {
                                    value: 'Anual'
                                },
                                {
                                    value: 24000
                                },
                                {
                                    value: 18000,
                                    sale: 25
                                },
                                {
                                    value: 18000,
                                    sale: 25
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    }
];

export const DATA_COMISSIONS = {
    title: 'Comisión de ganancia para Partner',
    rows: [
        {
            columns: [
                {
                    value: ' '
                },
                {
                    value: ' '
                },
                {
                    value: '0,05%'
                },
                {
                    value: '0,1%'
                }
            ]
        }
    ]
};
