import { Component, OnInit, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-see-product2',
    templateUrl: './dialog-see-product2.component.html',
    styleUrls: ['./dialog-see-product2.component.scss']
})
export class DialogSeeProduct2Component implements OnInit {
    @HostBinding('class') classList = 'default';

    optionsList = [
        {
            text: 'Conexión Bluetooth 4.0 <br>compatible con Android',
            icon: 'icon-sprite-bluetooth'
        }, {
            text: 'Distancia de transmisión: <br>10-30m',
            icon: 'icon-sprite-bluetooth2'
        }, {
            text: 'Incluye cable USB y<br> adaptador de corriente',
            icon: 'icon-sprite-usb'
        }, {
            text: 'Diseño ergonómico',
            icon: 'icon-sprite-hand'
        }, {
            text: 'Escanea códigos 1D y 2D',
            icon: 'icon-sprite-barcode'
        }, {
            text: 'Incluye soporte para lector',
            icon: 'icon-sprite-reader-support'
        }, {
            text: 'Garantía de 2 años',
            icon: 'icon-sprite-guarantee'
        }
    ];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        if (!!this.data) {
            this.classList = this.data.name;
        }
    }
}
