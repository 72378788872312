import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-section-tuu-inventory',
    templateUrl: './section-tuu-inventory.component.html',
    styleUrls: ['./section-tuu-inventory.component.scss']
})
export class SectionTuuInventoryComponent implements OnInit {
    @Input() public loadingImages: boolean;

    public list = [
        'Te permitirá saber de manera trasparente y confiable <b>las cantidades y niveles de productos en stock.</b>',
        '<b>Sabrás cual es la situación económica</b> en general de tu negocio.',
        '<b>Ahorrarás dinero en temas de almacenamiento,</b> perdidas de productos o fluctuación de precios.',
        '<b>Aprovecharás todas las oportunidades de venta</b> evitando el desabastecimiento.',
        '<b>Evitarás perdidas de stock</b> y el  deterioro de tus productos.'
    ];

    constructor() { }

    ngOnInit(): void {
    }

}
