import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-section-comparisons-terminal',
    templateUrl: './section-comparisons-terminal.component.html',
    styleUrls: ['../../../assets/sass/components/_section-comparisons-pos.component.scss']
})
export class SectionComparisonsTerminalComponent implements OnInit {
    @Input() public loadingImages: boolean;

    constructor() { }

    ngOnInit(): void {
    }

}
