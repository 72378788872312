import { Component } from "@angular/core";

@Component({
    selector: 'app-section-sales-over',
    templateUrl: './section-sales-over.component.html',
    styleUrls: ['./section-sales-over.component.scss']
})

export class SectionSalesOverComponent {

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

}
