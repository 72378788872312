import {
    Component,
    ElementRef,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    EventEmitter,
    OnChanges,
    ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-modal-video',
    templateUrl: './modal-video.component.html',
    styleUrls: ['./modal-video.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ModalVideoComponent implements OnInit, OnChanges {
    @ViewChild('videoInModal') public videoInModal: ElementRef;
    @ViewChild('videoHolder') public videoHolder: ElementRef;
    @Input() public currentVideo: string = 'tutorial-pos';
    @Input() public isShowVideo: boolean = false;
    @Output() public close: EventEmitter<boolean> = new EventEmitter();

    public controlsTimeout = null;

    constructor(private renderer: Renderer2) { }

    ngOnInit(): void {
    }

    ngOnChanges(): void {
        if (this.isShowVideo) {
            this.playVideo();
        }
    }

    public playVideo(): void {
        setTimeout(() => {
            this._createVideoElements();

            const video = this.videoInModal.nativeElement;
            const playEl = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('play')[0];

            document.body.style.overflow = 'hidden';
            document.body.classList.add('open-video');

            playEl.addEventListener('click', () => {
                if (playEl.classList.contains('pause')) {
                    playEl.classList.remove('pause');
                    video.pause();
                } else {
                    playEl.classList.add('pause');
                    video.play();
                    video.volume = 1.0;
                }
            });

            this._movePlaceholder();

            setTimeout(() => {
                this._moveProgress();
                this._volumeControll();
                this._toggleMuted();
            }, 100);

        }, 100);
    }

    public hideVideo(): void {
        const video = this.videoInModal.nativeElement;
        const customControls = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('video-controls')[0];
        this.renderer.removeClass(document.body, 'open-video');

        video.currentTime = 0;
        this.isShowVideo = false;
        this.videoInModal.nativeElement.parentNode.removeChild(customControls);
        document.body.style.overflow = null;
        this.close.emit();
    }

    private _createVideoElements(): void {
        this.videoHolder.nativeElement.insertAdjacentHTML('beforeend',
            '<div class=\"video-controls\">\
                <div class=\"play \"><span></span><span></span></div>\
                <div class=\"volume\">\
                    <div class=\"icon\"></div>\
                    <div class=\"bar\"><span class=\"bar-fill\"></span></div>\
                </div>\
                <div class=\"progress\">\
                    <div class=\"progress-inner\">\
                        <div class=\"fill\"></div>\
                        <div class=\"placeholder\"></div>\
                    </div>\
                </div>\
            </div>');
    }

    private _movePlaceholder(): void {
        const progress = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('progress')[0];
        const paceholder = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('placeholder')[0];

        progress.addEventListener('mousemove', (ev) => {
            paceholder.style.width = ev.offsetX + 'px';
        });
    }

    private _moveProgress(): void {
        const video = this.videoInModal.nativeElement;
        const controls = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('video-controls')[0];
        const playEl = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('play')[0];
        const progress = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('progress')[0];
        const fill = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('fill')[0];
        const progressRect = progress.getBoundingClientRect();

        // update progress line as time changes
        video.addEventListener('timeupdate', () => {
            fill.style.width = (progressRect.width * video.currentTime) / video.duration + 'px';

            if (fill.style.width === progressRect.width + 'px') {
                playEl.classList.remove('pause');
                video.pause();
            }
        });

        // hide the Play button when stoping moving the cursor
        const hideControls = (ev) => {
            if (ev.type === 'touchstart' && controls.classList.contains('hide')) {
                ev.preventDefault();
            }

            controls.classList.remove('hide');

            if (this.controlsTimeout !== null) {
                clearTimeout(this.controlsTimeout);
                this.controlsTimeout = null;
            }

            if (playEl.classList.contains('pause')) {
                this.controlsTimeout = setTimeout(() => {
                    controls.classList.add('hide');
                }, 2000);
            }
        };

        playEl.addEventListener('mousemove', hideControls);
        playEl.addEventListener('touchstart', hideControls);

        // update line progress when clicking progress
        progress.addEventListener('click', (ev) => {
            video.currentTime = (ev.offsetX * video.duration) / progressRect.width;
            fill.style.width = ev.offsetX + 'px';
        });
    }

    private _volumeControll(): void {
        const video = this.videoInModal.nativeElement;
        const volumeBar = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('bar')[0];
        const volumeFill = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('bar-fill')[0];
        const volumeBarRect = volumeBar.getBoundingClientRect();

        // update volume when clicking bar
        volumeBar.addEventListener('click', (ev) => {
            video.volume = (100 - ev.offsetY) / volumeBarRect.height;
            volumeFill.style.height = (100 - ev.offsetY) + 'px';
        });
    }

    private _toggleMuted(): void {
        const video = this.videoInModal.nativeElement;
        const iconVolume = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('icon')[0];
        const volumeFill = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('bar-fill')[0];

        iconVolume.addEventListener('click', (ev) => {
            if (iconVolume.classList.contains('muted')) {
                iconVolume.classList.remove('muted');
                video.removeAttribute('muted');
                video.volume = 1;
                volumeFill.style.height = '100px';
            } else {
                iconVolume.classList.add('muted');
                video.setAttribute('muted', '');
                video.volume = 0;
                volumeFill.style.height = '0';
            }
        });
    }

}
