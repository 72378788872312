import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-section-benefits-boleta',
    templateUrl: './section-benefits-boleta.component.html',
    styleUrls: ['./section-benefits-boleta.component.scss']
})
export class SectionBenefitsBoletaComponent implements OnInit {
    @Input() public loadingImages: boolean;

    list = [
        {
            icon: 'icon-sprite-file',
            text: 'Emisión de Boleta y Factura electrónica <b>ilimitada.</b>'
        }, {
            icon: 'icon-sprite-magazine',
            text: '<b>Reportes de ventas</b> para tu negocio.',
            tooltip: null
        }, {
            icon: 'icon-sprite-electronic-doc',
            text: '<b>Sistema de facturación</b> incluido desde tu POS o espacio de trabajo.',
            tooltip: null
        },/* {
            icon: 'icon-sprite-wifi-off',
            text: 'Emisión en modo <br> <b>sin conexion.</b>',
            tooltip: {
                text: 'Para aceptar pago debes tener conexión a internet.'
            }
        },*/{
            icon: 'icon-sprite-cloud',
            text: '<b>Tu información actualizada</b> y protegida en la nube.',
            tooltip: null
        }, {
            icon: 'icon-sprite-doc',
            text: '<b>Emisión de comprobante de pago electrónico (voucher),</b> también válido como boleta de ventas y servicios.',
            tooltip: null
        }, {
            icon: 'icon-sprite-task-planning',
            text: 'Sincronización automática <b>con el SII.</b>',
            tooltip: null
        }
    ];

    constructor() { }

    ngOnInit(): void {
    }

}
