import { Component, Input } from '@angular/core';
import { traceButton2 } from '../../../../../../common/services/button-trace';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-pos-machine-capabilities',
    templateUrl: './pos-machine-capabilities.component.html',
    styleUrls: ['./pos-machine-capabilities.component.scss']
})
export class PosMachineCapabilitiesComponent {

    public url: string;
    public cards = [];
    @Input() public isDirect: boolean;

    constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        this.route.url.subscribe(url => {
            this.url = url[0]?.path || 'home';
            this.url = (this.url === 'punto-de-venta') ? 'pdv' : this.url;
        });

        this.cards = [
            {
                id: 't-'+this.url+'-precios-ver-boleta',
                icon: 'icon-sprite-doc',
                text: 'Boletas y facturas',
                route: '/boleta-electronica'
            },
            {
                id: 't-'+this.url+'-precios-ver-reserva',
                icon: 'icon-sprite-calendar-dots',
                text: 'Reserva de horas',
                route: '/tuu-reserva'
            },
            {
                id: 't-'+this.url+'-precios-ver-inventario',
                icon: 'icon-sprite-layout-fluid',
                text: 'Inventario de productos',
                route: '/inventario'
            },
            {
                id: 't-'+this.url+'-precios-ver-pago-online',
                icon: 'icon-sprite-laptop',
                text: 'Pagos Online'
                // route: '/pago'
            }
        ]
      }

    public track(event: Event): void {
        traceButton2(event, 'navigate');
    }

}
