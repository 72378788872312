import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DataInstallment } from '../../../installment-payment/data-installment-payment';

@Component({
    selector: 'app-dialog-installment-period',
    templateUrl: './dialog-installment-period.component.html',
    styleUrls: ['./dialog-installment-period.component.scss'],
})
export class DialogInstallmentPeriodComponent implements OnInit {
    public currentObj: DataInstallment = {
        title: null,
        body: null,
        footer: {
            caption: null,
            total: null,
            value: null,
            label: null,
            options: null,
        },
    };

    constructor(
        private dialogRef: MatDialogRef<DialogInstallmentPeriodComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (!!this.data) {
            this.currentObj = this.data;
        }
    }
    public ngOnInit(): void {}

    public selectOption(option: string, index: number): void {
        this.currentObj.body[index].value = option;
        // this.calcInstallmentValue();
    }

    // private calcInstallmentValue(): void {
    //     var resto = 179900 - Number(this.currentObj.body[0].value);

    //     switch (this.currentObj.body[1].value) {
    //         case '3 meses':
    //             this.currentObj.footer.total = resto / 3;
    //             break;
    //         case '6 meses':
    //             this.currentObj.footer.total = resto / 6;
    //             break;
    //         case '9 meses':
    //             this.currentObj.footer.total = resto / 9;
    //             break;
    //     }
    // }

    public save(): void {
        this.dialogRef.close(this.currentObj);
    }

    public isNumber(val): boolean {
        return typeof val === 'number';
    }
}
