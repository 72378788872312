import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { traceButton2 } from '../../../../../../common/services/button-trace';

interface IReservationPlans {
    header: Array<{ title: string; subtitle?: string; }>;
    items: Array<{
        title?: string;
        rows: Array<{ columns: Array<{ value?: string | number; state?: string; }> }>;
    }>;
    footer: Array<{ button: { id: string; text: string; link: string, params: { subproduct: string; purchaseOrigin: string } }; note: string; }>;
}

@Component({
    selector: 'app-reservation-plans',
    templateUrl: './reservation-plans.component.html',
    styleUrls: ['./reservation-plans.component.scss'],
})
export class ReservationPlansComponent {

    public plansArray: IReservationPlans = {
        header: [
            {
                title: 'CARACTERÍSTICAS'
            }, {
                title: 'BÁSICO',
                subtitle: 'Contrátalo gratis por siempre llevando una máquina POS'
            }, {
                title: 'PLUS',
                subtitle: 'Prueba 3 meses Gratis ($15.000 mensual + IVA facturado anual)'
            }
        ],
        items: [
            {
                rows: [
                    {
                        columns: [
                            {
                                value: 'Cantidad de <b>agendas</b>'
                            }, {
                                value: 3
                            }, {
                                value: 'Ilimitadas'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: 'Cantidad de <b>sucursales</b>'
                            }, {
                                value: 1
                            }, {
                                value: 'Ilimitadas'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: 'Límite de <b>cupos</b> simultáneos'
                            }, {
                                value: 5
                            }, {
                                value: 'Ilimitadas'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: '<b>Sitio web</b> de reserva personalizable con logos, colores y redes sociales'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: '<b>Catálogo</b> de los servicios que ofreces'
                            }, {
                                value: 'Ilimitadas'
                            }, {
                                value: 'Ilimitadas'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: 'Requiere comprar una <b>Máquina POS</b>'
                            }, {
                                value: 'Si'
                            }, {
                                value: 'Opcional'
                            }
                        ]
                    }
                ]
            }, {
                title: 'comunicación con clientes',
                rows: [
                    {
                        columns: [
                            {
                                value: '<b>Envío automático de comprobante</b> de reserva al agendar'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: 'Envío de <b>recordatorio de asistencia</b> para confirmar reserva'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: 'Envío de <b>correo de notificación adicional</b> para cada agenda'
                            }, {
                                state: 'unavailable'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: 'Botón para enviar <b>mensajes de Whatsapp</b> a tus clientes'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }
                ]
            }, {
                title: 'pagos',
                rows: [
                    {
                        columns: [
                            {
                                value: 'Recibe <b>pagos por adelantado</b> con TUU Pago Online'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: '<b>Recibe pagos presencial</b> con tarjeta en Tuu Máquina POS (Se compra aparte)'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: '<b>Abonos</b> al siguiente día hábil'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: 'Acepta pagos en cuotas'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: 'Emisión de <b>boleta y factura electrónica</b> (Solo para pago presencial con TUU POS)'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: '<b>Todas las funcionalidades de la máquina POS</b> que lleves (Opcional en plan Plus)'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }
                ]
            }, {
                title: 'funcionalidades de tuu reserva',
                rows: [
                    {
                        columns: [
                            {
                                value: '<b>Notificaciones automáticas por Whatsapp</b> para confirmar hora, modificaciones y otros.'
                            }, {
                                value: 'Pack desde $5.000 + IVA mensual'
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                value: '<b>Encuestas de satisfacción NPS:</b> envío automático por correo con preguntas personalizadas'
                            }, {
                                value: 'Pack $4.990 + IVA mensual'
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                value: '<b>Fichas</b> clínicas'
                            }, {
                                value: 'Pack $12.990 + IVA mensual'
                            }
                        ]
                    },
                    {
                        columns: [
                            {
                                value: '<b>Formulario de agendamiento</b> con 5 campos personalizables para pedir información'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: '<b>Campo adicional en formulario</b> para subir archivos al momento de reservar'
                            }, {
                                state: 'unavailable'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: 'Ofrece tus <b>servicios</b> en modalidad <b>presencial, a domicilio</b> o por <b>videollamada.</b>'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: '<b>Integración con Zoom</b>, envío automático de enlace a videollamada'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: '<b>Fichas de atención</b> para tomar nota de cada reserva'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: '<b>Perfil de cliente</b> con historial de agendas, estados de pago y otros'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: '<b>Panel de estadística</b> de reservas, pagos y ocupación'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: '<b>Exportación de estadísticas</b> en archivo para tus servicios'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: 'Creación de <b>códigos de descuento</b> para tus servicios'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: '<b>Creación de cuentas de clientes</b> para agendar más rápido y ver historial'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: 'Posibilidad de <b>dejar notas a cliente</b> para que vea desde su cuenta y notificarlo'
                            }, {
                                state: 'unavailable'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: 'Conexión de <b>dominio personalizado</b> (ejemplo: agenda.tusitioweb.cl)'
                            }, {
                                state: 'unavailable'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: 'Crea <b>usuarios con distintos niveles de acceso</b> a tu sistema'
                            }, {
                                state: 'unavailable'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: '<b>Adjuntar archivos</b> en notas de cada reserva'
                            }, {
                                state: 'unavailable'
                            }, {
                                state: 'available'
                            }
                        ]
                    }
                ]
            }, {
                title: 'soporte y ayuda',
                rows: [
                    {
                        columns: [
                            {
                                value: 'Soporte por <b>llamada telefónica y Whatsapp</b>'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: '<b>Capacitación</b> inicial por videollamada'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: '<b>Video tutoriales</b> para todas las funcionalidades'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }, {
                        columns: [
                            {
                                value: '<b>Artículos</b> de ayuda'
                            }, {
                                state: 'available'
                            }, {
                                state: 'available'
                            }
                        ]
                    }
                ]
            }
        ],
        footer: [
            {
                button: {
                    id:'t-reserva-planes-contrata',
                    text: 'Contrata',
                    link: 'seleccion-producto',
                    params: { subproduct: 'TUU', purchaseOrigin: 'reserva' }
                },
                note: 'Sin pagar licencias mensuales'
            },
            {
                button: {
                    id:'t-reserva-planes-prueba',
                    text: 'Prueba gratis 3 meses',
                    link: 'seleccion-producto',
                    params: { subproduct: 'TUUP', purchaseOrigin: 'reserva' }
                },
                note: 'Luego $15.000 por mes<br> con facturación anual.'
            }
        ]
    }

    constructor(
        private router: Router
    ) { }

    public navigateTo(path: string, params: { subproduct: string; purchaseOrigin: string }): void {
        this.router.navigate([path], {
            queryParams: params,
            queryParamsHandling: 'merge'
        })
    }

    public track(event: Event){
        traceButton2(event, 'navigate');
    }

}
