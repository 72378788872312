import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-section-about-payment',
    templateUrl: './section-about-payment.component.html',
    styleUrls: ['./section-about-payment.component.scss']
})
export class SectionAboutPaymentComponent implements OnInit {
    @Input() public loadingImages: boolean;

    cardList = [
        {
            title: 'Añade tus productos',
            text: 'Agrega productos escaneando con tu lector de códigos de barra o ingresando los precios directo desde el catálogo.'
        },
        {
            title: 'Inserta, desliza o acerca la tarjeta',
            text: 'Completa el pago con todo tipo de tarjeta, Contactless, banda magnética o chip.'
        },
        {
            title: 'Escoge el tipo de documento',
            text: 'Selecciona entre voucher, boleta, factura o guía de despacho dependiendo el modelo de TUU POS.'
        },
        {
            title: '¡Listo! Tu negocio ya está creciendo',
            text: 'Tus ventas rebajarán stock automáticamente, se generarán registros y crearán los informes de ventas.'
        }
    ];

    constructor() { }

    ngOnInit(): void {
    }

}
