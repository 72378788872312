import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-section-free-e-billing',
    templateUrl: './section-free-e-billing.component.html',
    styleUrls: ['./section-free-e-billing.component.scss']
})
export class SectionFreeEBillingComponent implements OnInit {
    @Input() loadingImages = false;

    list = [
        'Disponible en <b>TUU POS y desde tu espacio de trabajo web.</b>',
        '8 documentos tributarios entre <b>boletas, facturas, notas de crédito y otros.</b>',
        'Todos tus movimientos en orden, <b>al detalle y sin topes de emisión.</b>',
        '<b>Personaliza el diseño de tus documentos</b> y distínguete del resto.',
        'En el plan TUU+ puedes emitir documentos de forma masiva <b>gracias al complemento Google Sheets.</b>'
    ];

    constructor() { }

    ngOnInit(): void {
    }

}
