import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-dialog-custom-menu',
    templateUrl: './dialog-custom-menu.component.html',
    styleUrls: ['./dialog-custom-menu.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DialogCustomMenuComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

}
