import { Directive, Input, ElementRef, forwardRef, OnInit, HostListener } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import * as VMasker from 'vanilla-masker';

const PATTERNS = {
    'rut': ['999.999-S', '9.999.999-S', '99.999.999-S']
};

const MASKDIRECTIVE_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    // tslint:disable-next-line: no-use-before-declare
    useExisting: forwardRef(() => MaskDirective),
    multi: true
};

const MASKDIRECTIVE_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    // tslint:disable-next-line: no-use-before-declare
    useExisting: forwardRef(() => MaskDirective),
    multi: true
};

@Directive({
    selector: '[appStrideMask]',
    providers: [
        MASKDIRECTIVE_VALUE_ACCESSOR,
        MASKDIRECTIVE_VALIDATOR]
})

export class MaskDirective implements OnInit, ControlValueAccessor, Validators {

    private patternType = '';
    private patterns: string[] = [];
    private currentPattern = '';
    private lastValue = '';
    private _onChange: Function = (_: any) => { };
    private _onTouched: Function = () => { };

    @Input()
    set appStrideMask(pattern: string) {
        this.patternType = pattern;
        if (pattern !== null) {
            this.patterns = PATTERNS[pattern];
        } else {
            this.patterns = [];
        }
    }

    constructor(
        private element: ElementRef
    ) { }

    registerOnChange(fn: Function): void {
        this._onChange = fn;
    }
    registerOnTouched(fn: Function): void {
        this._onTouched = fn;
    }

    @HostListener('blur') onblur() {
        this._onTouched();
    }

    @HostListener('input')
    public onChange(): void {
        if (this.getValue() !== this.lastValue) {
            let cleanValue = this.getValue();

            if (this.patternType === 'rut') {
                cleanValue = this.getValue().replace(new RegExp(/\s+/g), '');
                cleanValue = cleanValue.toUpperCase();
            }

            this.applyMask(cleanValue);
            this.lastValue = this.getValue();
        }

    }

    @HostListener('keydown', ['$event'])
    public onKeyDown(event): void {
        const key = event.keyEventCode || event.charCode;
        if ((key === 8 || key === 46) && this.getValue().length === 1) {
            this._onChange('');
        }
    }

    public ngOnInit(): void {
        if (this.patternType === 'rut') {
            this.patterns.sort((a, b) => b.length - a.length).reverse();
        }
        this.applyMask(this.getValue());
    }

    private checkMask(value, pattern) {
        if (this.patternType !== 'rut') {
            VMasker(this.getEl()).unMask();
            VMasker(this.getEl()).maskPattern();
        }
        this.updateValue(VMasker.toPattern(value, pattern));
    }

    private applyMask(value): void {
        let clearValue;

        if (value === undefined || value === null) {
            clearValue = '';
            this.updateValue('');
            return;
        } else {
            clearValue = value.replace(/-/g, '').replace(/\·/g, '').replace(/\./g, '');
        }

        let fitToPattern = false;
        if (this.patternType === 'rut' && clearValue.length <= 7) {
            this.currentPattern = this.patterns[0];
            fitToPattern = true;
            this.checkMask(value, this.currentPattern);
        } else if (this.patternType === 'rut' && clearValue.length > 7 && clearValue.length < 9) {
            this.currentPattern = this.patterns[1];
            fitToPattern = true;
            this.checkMask(value, this.currentPattern);
        } else if (this.patternType === 'rut' && clearValue.length === 9) {
            this.currentPattern = this.patterns[2];
            fitToPattern = true;
            this.checkMask(value, this.currentPattern);
        }
        if (!fitToPattern) {
            this.updateValue(this.lastValue);
        }
    }

    private updateValue(v: any): void {
        this.setValue(v);

        this._onChange(v);
        if (v) {
            this._onTouched();
        }
    }

    private setValue(value: string): void {
        this.element.nativeElement.value = value;
    }

    private _isValidRUT(value) {
        if (value === null || value === undefined) {
            return '';
        }

        let rutValue = value.toString().replace(/\./g, '');

        let oldDV;
        const hasDV = value.toString().includes('-');

        if (hasDV) {
            rutValue = rutValue.split('-')[0];
            oldDV = String(value.split('-')[1].toUpperCase());
            value = value.split('-')[0];
        }

        if (value.length < 4) {
            return '';
        }

        const series: number[] = [2, 3, 4, 5, 6, 7];
        const rut: string = rutValue.toString();
        let sum = 0;
        let mirsa = 0;

        for (let i = rut.length - 1; i >= 0; i--) {
            sum = sum + (parseInt(rut.charAt(i), 10) * series[mirsa % 6]);
            mirsa++;
        }

        sum = sum % 11;
        sum = 11 - sum;

        let dv = '';

        switch (sum) {
            case 11:
                dv = '0';
                break;
            case 10:
                dv = 'K';
                break;
            default:
                dv = sum.toString();
                break;
        }

        if (oldDV !== String(dv)) {
            return false;
        } else {
            return true;
        }
    }

    private getValue(): string {
        return this.element.nativeElement.value;
    }

    private getEl(): any {
        return this.element.nativeElement;
    }

    // If the length of the value not fit with the pattern is not a valid input
    validate(c: AbstractControl): { [key: string]: any } {
        if (
            this.getValue().length === 0 ||
            this.getValue() === null ||
            this.getValue() === undefined
        ) {
            return null;
        } else if (
            this.currentPattern.length !== 0 &&
            this.currentPattern.length > this.getValue().length
        ) {
            return { 'pattern': this.getValue() };
        } else if (
            // comprueba el digito verificador
            this.patternType === 'rut' && !this._isValidRUT(this.getValue())
        ) {
            return { 'pattern': this.getValue() };
        }
        return null;
    }

    writeValue(value: any) {
        // emit new value to the View
        this.applyMask(value);
    }
}
