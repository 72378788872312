import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-section-order-reception',
    templateUrl: './section-order-reception.component.html',
    styleUrls: ['./section-order-reception.component.scss']
    })
export class SectionOrderReceptionComponent implements OnInit {
    @Input() public loadingImages: boolean;

    list = [
        'Conocer al detalle <b>tus proveedores y lotes.</b>',
        '<b>Realizar un control cuantitativo y cualitativo</b> de tus productos.',
        'Obtener <b>documentación de las fechas de descarga</b> de la mercancía.',
        'Acceder a <b>información de ingreso de mercancía recibida</b> en el stock de la empresa.',
    ];

    constructor() { }

    ngOnInit(): void {
    }

}
