import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dialog-plans',
    templateUrl: './dialog-plans.component.html',
    styleUrls: ['./dialog-plans.component.scss']
})
export class DialogPlansComponent implements OnInit {

    constructor() {
    }

    public ngOnInit(): void {
    }
}
