import { Component, EventEmitter, Output, ViewChild, ViewContainerRef, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalMenuService } from '../../services/modal-menu.service';
import { MobileMenuChildComponent } from './mobile-menu-child/mobile-menu-child.component';
import { MobileMenuSupportComponent } from './mobile-menu-support/mobile-menu-support.component';
import { Router } from '@angular/router';
import { traceButton2 } from '../../../../../../common/services/button-trace';
import { urlMapping } from '../../route-ids';

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {
    @ViewChild('mobileMenuChild', { read: ViewContainerRef }) containerMenuChild!: ViewContainerRef;
    @Output() closeEventParent = new EventEmitter();

    subscriptionMobileMenuOpen!: Subscription;
    subscriptionMobileMenuClose!: Subscription;
    public url:string;

    constructor(
        private modalMenuSrv: ModalMenuService,
        private _router: Router,
    ) { }

    ngOnInit(): void {
        const urlTree = this._router.parseUrl(this._router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';;
        this.url = urlMapping[urlSegment] || urlSegment;
        const queryParams = urlTree.queryParams;
        this.url = (queryParams?.['purchaseOrigin'] === 'reserva')
            ? (queryParams['subproduct'] === 'TUU' ? 'reserva' : 'reserva-plus')
            : this.url;
    }

    close() {
        this.closeEventParent.emit();
        if (this.subscriptionMobileMenuClose) {
            this.subscriptionMobileMenuClose.unsubscribe();
        }
        if (this.subscriptionMobileMenuOpen) {
            this.subscriptionMobileMenuOpen.unsubscribe();
        }
    }

    openChildMenu(childMenuName: string): void {
        const childMenu = childMenuName === 'products' ? MobileMenuChildComponent : MobileMenuSupportComponent;
        this.subscriptionMobileMenuOpen = this.modalMenuSrv.openModal(this.containerMenuChild, childMenu).subscribe();
        this.subscriptionMobileMenuClose = this.modalMenuSrv.componentRef.instance.closeEventChild.subscribe(() => {
            this.close();
        });
    }

    public goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    public track(event: Event): void {
        traceButton2(event, 'navigate');
    }
}
