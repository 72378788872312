import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MetaService } from '@ngx-meta/core';

import { urlEnv } from '../../environments/environment';
import { SEOService } from '../shared/services/seo.service';

@Component({
    selector: 'app-partner-price-page',
    templateUrl: './partner-price-page.component.html'
})
export class PartnerPricePageComponent implements OnInit {

    public urlEnv = urlEnv;

    public itemsFAQ = [
        {
            title: '¿Cómo puedo subir de nivel en Haulmer Partner?',
            text: 'Para subir de nivel y acceder a mejores precios de los productos y servicios Haulmer tienes que abonar una  cantidad mayor o igual a la señalada en cada nivel.'
        }, {
            title: '¿Puedo bajar de nivel en Haulmer Partner?',
            text: 'No. Una vez que accediste a un nivel en Haulmer Partner siempre podrás acceder a esa lista de precios o ascender si es que abonas la cantidad señalada en el siguiente nivel.'
        }, {
            title: '¿El dinero abonado lo puedo utilizar para contratar servicios Haulmer?',
            text: 'Sí, puedes abonar la cantidad de dinero que tú quieras, después ese dinero lo utilizarás en su totalidad para contratar los servicios que tus clientes necesiten.'
        }, {
            title: '¿Se acumula el dinero abonado para poder subir de nivel en Haulmer Partner? ',
            text: 'No. Para acceder a la siguiente nivel se debe ingresar un monto mayor o igual al señalado en un único depósito.'
        }
    ];

    public itemsFAQ2 = [
       {
            title: '¿Cuándo recibiré los pagos de las comisiones?',
            text: 'La comisiones acumuladas serán depositadas durante los primeros 10 días hábiles del mes siguiente al cuál fue procesada la transacción.'
        }, {
            title: '¿A qué cuenta me van a depositar?',
            text: 'Al momento de convertirte en partner deberás indicar la cuenta a la cual desees que realicemos los abonos correspondientes. Ten en cuenta que puedes realizar un cambio de cuenta de abono.'
        }, {
            title: '¿Hay alguna diferencia en la comisión por las transacciones de crédito y débito?',
            text: 'Ambas comisiones corresponden a un 0,10% + iva del monto total de la transacción.'
        }, {
            title: '¿Cuánto voy a ganar por cada transacción?',
            text: 'Por cada transacción el partner recibirá un 0,10% + iva del monto total.'
        }, {
            title: '¿Cómo realizo un cambio de cuenta?',
            text: 'Si deseas realizar un cambio en la cuenta de abono, deberás completar el siguiente <b><a href="https://docs.google.com/forms/d/e/1FAIpQLSeKBuQJJXVkOCN9v_UbOzZZqUL4Y-msWWpGoG5okmwFmURZkg/viewform" target="_blank">formulario</a></b> e informar el ingreso de la solicitud a tu ejecutivo de cuenta.'
        }, {
            title: '¿Qué pasa si el monto recibido no cuadra o no he recibido el pago?',
            text: 'En caso de detectar alguna inconsistencia, podrás solicitar la revisión de las comisiones a través de los canales oficiales, creando un ticket de soporte e informar el ingreso de la solicitud a tu ejecutivo de cuenta.'
        }
    ];

    titleFAQ2 = '<i>Condiciones comerciales TUU</i>';

    constructor(
        private readonly _meta: MetaService,
        @Inject(PLATFORM_ID) private platformId: any,
        private seoService: SEOService
    ) { }

    ngOnInit(): void {
        this._setMeta();
        this.seoService.updateCanonicalUrl('partner/precios/');

        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 500);
    }

    private _setMeta() {
        this._meta.setTitle('TUU Partners - Genera ingresos siendo el partner de los emprendedores');
        this._meta.setTag('description', 'Ofrece a tus clientes un producto de calidad que les permitirá recibir pagos con tarjetas, emitir boletas, crear catálogo de productos y manejar su inventario');
        this._meta.setTag('keywords', 'Máquina para cobrar con tarjeta, máquina para recibir pagos con tarjeta, dispositivo para pagar con tarjeta, POS, máquina pos, sistema de pago con tarjeta. Boleta electrónica, máquina boleta electrónica, emitir boleta.');
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-partner-price.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'TUU Partners - Genera ingresos siendo el partner de los emprendedores');
    }

}
