import { Component } from '@angular/core';
import { AppType } from '../../../../../common/assets/website-data';
import { IDataModalTemplate } from '../../../../../common/interfaces/promotions.interface';

@Component({
    selector: 'app-dialog-promo-wrapper',
    templateUrl: './dialog-promo.component.html',
})
export class DialogPromoWrapperComponent {
    public dataModal: IDataModalTemplate = {
        title: {
            text: 'MINI y MINI S',
            icon: false,
            style: 'title italic bold',
        },
        interTitle: 'Ahora con emisión de Boleta Electrónica',
        subtitle: {
            text: 'Celébralo con 30% de dcto.',
            style: 'subtitle_many_text italic bold',
        },
        promoCode: {
            code: 'TUUMINI30',
            style: 'promoCode_primary',
            borderStyle: 'promoCodeBorder_accent-secondary',
        },
        button: {
            text: 'COMPRAR MÁQUINA',
            buttonId: 'mini30-diciembre23',
            style: 'promotionalBtn',
        },
        footer: {
            text: 'Válido hasta agotar stock. Máximo 10 unidades por comercio.',
            style: 'color__white',
        },
    };

    public dataModalCyber: IDataModalTemplate = {
        title: {
            text: '<span class="color-accent-third">CYBER</span> llegó a <span class="color-accent-third">TUU</span>',
            icon: false,
            style: 'title italic bold color__white'
        },
        subtitle: {
            text: 'Hasta <span class="color-accent-third">60%OFF</span> en máquinas POS',
            style: 'subtitle_many_text italic bold color__white'
        },
        button: {
            text: 'COMPRAR MÁQUINA',
            buttonId: '',
            style: 'btnFillThird',
            type: 'mat-button',
            scrollTo: 'price-ts'
        },
        footer: {
            text: 'Del 23 de septiembre al 6 de octubre o hasta agotar stock. Máximo 10 unidades por comercio',
            style: 'color__white'
        },
    }

    get appType() {
        return AppType.tuu;
    }
}
