import { Component, OnInit } from '@angular/core';

import { DATA_COMISSIONS, DATA_PRODUCTS } from './data-table-price';

@Component({
    selector: 'app-partners-plans',
    templateUrl: './partners-plans.component.html',
    styleUrls: ['./partners-plans.component.scss']
})
export class PartnersPlansComponent implements OnInit {

    dataProducts;
    dataCommissions;

    constructor() {
        this.dataProducts = DATA_PRODUCTS;
        this.dataCommissions = DATA_COMISSIONS;
    }

    ngOnInit(): void {
    }

}
