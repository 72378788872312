import { Component, ElementRef } from '@angular/core';
import { ScrollToClass } from '../../shared/scrollTo.class';
import { traceButton2 } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-reservation-first-screen',
    templateUrl: './reservation-first-screen.component.html',
    styleUrls: ['./reservation-first-screen.component.scss']
})
export class ReservationFirstScreenComponent extends ScrollToClass {

    constructor(
        private _el: ElementRef
    ) {
        super(_el)
    }

    public track(event: Event, type: string){
        traceButton2(event, type);
    }
}
