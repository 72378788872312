import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-section-models',
    templateUrl: './section-models.component.html',
    styleUrls: ['./section-models.component.scss']
})
export class SectionModelsComponent {
    @Input() public loadingImages: boolean;
}
