import { IDataStrip } from './../../../../../../common/interfaces/promotions.interface';
import { AppType } from './../../../../../../common/assets/website-data';
import { Component, Input, ElementRef } from "@angular/core";
import { traceButton2 } from '../../../../../../common/services/button-trace';
import { Router } from '@angular/router';
import { ScrollToClass } from '../../../shared/scrollTo.class';
import { urlMapping } from '../../../shared/route-ids';

@Component({
    selector: 'app-strip-tuu',
    templateUrl: './strip.component.html',
    styleUrls: ['./strip.component.scss']
})

export class StripComponentTUU extends ScrollToClass{
    @Input() data: IDataStrip;
    @Input() appType: AppType;
    public svgSize = { width: '70px', height: '35px' };
    public url: string;

    constructor(
        private _el: ElementRef,
        private router: Router,
    ) { 
        super(_el); 
        const urlTree = this.router.parseUrl(this.router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';
        this.url = urlMapping[urlSegment] || urlSegment;
    }


    redireccionarAMaquinas(event: Event) {
        traceButton2(event, "scroll");
        // Navega a la ruta 'home' con el fragmento 'sectionPrice'
        this.router.navigate(['/']).then(() => {
            setTimeout(() => {
                this.scrollToSection("sectionPrice");
            }, 500); // Espera antes de realizar el scroll
        });
        this.scrollToSection("sectionPrice");
    }
}