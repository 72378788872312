import { Component, Inject, PLATFORM_ID, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-all-for-my-friend',
    templateUrl: './all-for-my-friend.component.html',
    styleUrls: ['./all-for-my-friend.component.scss']
})

export class AllForMyFriendComponent {

    public isMobile: boolean = false;
    public isNeededBackButton: boolean = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private _router: Router
    ) {
        if (isPlatformBrowser(platformId)) {
            if (!!sessionStorage.getItem('fromdevice')) {
                this.isNeededBackButton = true;
            }
        }
    }

    @HostListener('window.resize')
    onResize(){
        this.isMobile = window.innerWidth < 767;
    }

    ngOnInit():void{
        if(isPlatformBrowser(this.platformId)){
            this.isMobile = window.innerWidth < 767;
        }
    }

    public exit(): void {
        this._router.navigate(['codigo-amigo/salir/']);
    }

    public back(): void {
        this._router.navigate(['codigo-amigo']);
    }
}
