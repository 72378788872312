import { Component } from '@angular/core';

@Component({
    selector: 'app-section-plans',
    templateUrl: './section-plans.component.html',
    styleUrls: ['./section-plans.component.scss']
})
export class SectionPlansComponent {

    public showImage1 = false;

}
