import { Component } from '@angular/core';

@Component({
    selector: 'app-section-reservation-plans',
    templateUrl: './section-reservation-plans.component.html',
    styleUrls: ['./section-reservation-plans.component.scss'],
})
export class SectionReservationPlansComponent {

}
