import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-section-catalog',
    templateUrl: './section-catalog.component.html',
    styleUrls: ['./section-catalog.component.scss']
})
export class SectionCatalogComponent implements OnInit {
    @Input() public loadingImages: boolean;

    constructor() { }

    ngOnInit(): void {
    }

}
