import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

import { urlEnv } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SEOService {
    public urlEnv = urlEnv;

    constructor(
        @Inject(DOCUMENT) private doc,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
    }

    updateCanonicalUrl(url: string) {
        if (isPlatformBrowser(this.platformId)) {
            const head = this.doc.getElementsByTagName('head')[0] as HTMLElement;

            let element: HTMLLinkElement = this.doc.querySelector(`link[rel='canonical']`) || null
            if (element == null) {
                element = this.doc.createElement('link') as HTMLLinkElement;
                head.appendChild(element);
            }
            element.setAttribute('rel', 'canonical')
            element.setAttribute('href', this.urlEnv.TUU_WEBSITE + url)
        }
    }
}
