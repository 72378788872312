import { Component, Input, OnInit } from '@angular/core';
import { traceButton2 } from '../../../../../common/services/button-trace';
import { Router } from '@angular/router';
import { urlMapping } from '../../shared/route-ids';
declare var Storylane: any;
@Component({
    selector: 'app-section-demo-view',
    templateUrl: './section-demo-view.component.html',
    styleUrls: ['./section-demo-view.component.scss']
})
export class SectionDemoViewComponent implements OnInit {
    @Input() public loadingImages: boolean;

    public url:string;
    constructor(
        private _router: Router
    ) {
    }
    ngOnInit(): void {
        const urlTree = this._router.parseUrl(this._router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';;
        this.url = urlMapping[urlSegment] || urlSegment;
    }

    public openDialog(event: Event): void {
        let baseWidth = 336;
        let baseHeight = 630;
        let windowHeight = window.innerHeight;
        let windowWidth = window.innerWidth;
        let scaleHeight = windowHeight / baseHeight;
        let scaleWidth = windowWidth / baseWidth;
        let scale = Math.min(scaleHeight, scaleWidth);
        let widthS = baseWidth * scale;
        let heightS = baseHeight * scale;
        let padding_bottom = heightS;
        traceButton2(event, 'popup');
        Storylane.Play({
            type: 'popup',
            demo_type: 'image',
            width: String(widthS) + 'px',
            height: String(heightS) + 'px',
            scale: 1,
            demo_url: 'https://app.storylane.io/demo/pazcvw2uhmjh',
            padding_bottom: String(padding_bottom) + 'px'
        });
    }
}