import { Component, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { DialogSeeProductComponent } from '../../../shared/dialogs/dialog-see-product/dialog-see-product.component';
import { DialogSeeProduct2Component } from '../../../shared/dialogs/dialog-see-product2/dialog-see-product2.component';
import { DialogSeeProduct3Component } from '../../../shared/dialogs/dialog-see-product3/dialog-see-product3.component';
import { DialogInfoComponent } from '../../../shared/dialogs/dialog-info/dialog-info.component';
import { DataService } from '../../../shared/services/data.service';

@Component({
    selector: 'app-supplies-card-options',
    templateUrl: './supplies-card-options.component.html',
    styleUrls: ['./supplies-card-options.component.scss']
})
export class SuppliesCardOptionsComponent {

    @Input() public data;
    @Input() public loadingImages: boolean;

    public activeCard = 0;
    public bottomPosition: string;

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.bottomPosition = window.innerWidth > 480 ? '' : '0';
    }

    constructor(
        public dialog: MatDialog,
        private dataService: DataService,
        private _router: Router
    ) { }

    public openDialog(option): void {
        switch (option.actionText) {
            case 'Ver Pistola':
                this.dialog.open(DialogSeeProduct2Component, {
                    panelClass: 'dialogWrapper',
                    width: '680px',
                    position: {
                        bottom: this.bottomPosition
                    },
                    data: { name: 'color-accent' }
                });
                break;
            case 'Ver Terminal':
                this.dialog.open(DialogSeeProduct3Component, {
                    panelClass: 'dialogWrapper',
                    width: '680px',
                    position: {
                        bottom: this.bottomPosition
                    },
                    data: { name: 'color-accent' }
                });
                break;
            case 'Ver Impresora':
                this.dialog.open(DialogSeeProductComponent, {
                    panelClass: 'dialogWrapper',
                    width: '680px',
                    position: {
                        bottom: this.bottomPosition
                    },
                    data: { name: 'color-accent' }
                });
                break;
        }
    }

    public selectOption(options, option): void {
        if (option.isDisabled) {
            return;
        }
        if (option.title === 'TUU máquina POS' && !option.isChecked) {
            const dialogRef = this.dialog.open(DialogInfoComponent, {
                panelClass: 'primaryDialog',
                width: '496px',
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this._router.navigate(['/precios']);
                }
                if (result === undefined) {
                    this.changeCounter(options, option, 'dec');
                }
            });
        }
        if (
            option.title === 'TUU máquina POS' ||
            option.icon === 'icon-sprite-pistol' ||
            option.icon === 'icon-sprite-print' ||
            option.icon === 'icon-sprite-rolls'
        ) {
            this.dataService.isHardwareSelected$.emit(option.isChecked);
        }
        if (option.icon === 'icon-sprite-feather') {
            this.checkedFirma(options, option);
        }
        if (option.isChecked) {
            option.count = 0;
        } else {
            option.count = 1;
        }
    }

    public changeCounter(options, option, type): void {
        switch (type) {
            case 'inc':
                option.count = option.count + 1;
                break;
            case 'dec':
                option.count = option.count - 1;
                break;
        }
        if ((option.icon === 'icon-sprite-feather' ||
            option.title === 'TUU máquina POS' ||
            option.icon === 'icon-sprite-pistol' ||
            option.icon === 'icon-sprite-rolls' ||
            option.icon === 'icon-sprite-print') &&
            !option.isChecked
        ) {
            this.selectOption(options, option);
        }
        if (option.isChecked && option.count === 0 &&
            (option.title === 'TUU máquina POS' ||
                option.icon === 'icon-sprite-pistol' ||
                option.icon === 'icon-sprite-print' ||
                option.icon === 'icon-sprite-rolls'
            )) {
            this.selectOption(options, option);
        }
        option.isChecked = option.count !== 0;
    }

    public checkedFirma(options, option) {
        switch (option.id) {
            case 1:
                options[1].isChecked = false;
                options[1].count = 0;
                options[2].isChecked = false;
                options[2].count = 0;
                break;
            case 2:
                options[0].isChecked = false;
                options[0].count = 0;
                options[2].isChecked = false;
                options[2].count = 0;
                break;
            case 3:
                options[0].isChecked = false;
                options[0].count = 0;
                options[1].isChecked = false;
                options[1].count = 0;
                break;
        }
    }

}
