import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MetaService } from '@ngx-meta/core';

import { urlEnv } from '../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-parking',
    templateUrl: './parking.component.html'
})
export class ParkingComponent implements OnInit {

    public urlEnv = urlEnv;
    public showImages: boolean;

    public headingPrice = {
        title: `<b>Comienza a <span class="decoration-underline accent-secondary">usar la app.</span></b>`,
        subtitle: `TUU Estacionamiento está disponible gratis en la APP Pagos de nuestros terminales TUU SE. <b>¡Asegúrate de tener uno para poder usarla!</b>`
    };

    public dataPriceSection = {
        buttons: [
            {
                id: 'TUU-B-precios-compra-simple'
            },
            {
                id: 'TUU-B-precios-compra-con-complementos'
            }
        ]
    };

    constructor(
        private readonly _meta: MetaService,
        @Inject(PLATFORM_ID) private platformId: any,
    ) { }

    ngOnInit(): void {
        this._setMeta();

        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 500);
    }

    private _setMeta(): void {
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-parking.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
    }

}
