import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pos-section-benefits',
  templateUrl: './pos-section-benefits.component.html',
  styleUrls: ['./pos-section-benefits.component.scss']
})
export class PosSectionBenefitsComponent {

    public benefitsList1 = [
        {
            icon: 'icon-sprite-catalogue',
            iconSize: 40,
            text: 'Dale mayor agilidad a tu punto de venta con un <b>catálogo de fácil y rápida usabilidad.</b>'
        }, {
            icon: 'icon-sprite-pos',
            iconSize: 40,
            text: '<b>Gestiona tu POS a distancia</b> y personaliza todos tus dispositivos y aplicaciones.'
        }, {
            icon: 'icon-sprite-inventary',
            iconSize: 40,
            text: '<b>Maneja tu stock</b> en tiempo real con gestión de traslados y control de valores de productos.'
        }
    ];

    public benefitsList2 = [
        {
            icon: 'icon-sprite-transfer-2',
            iconSize: 40,
            text: '<b>Ordena tus operaciones y transacciones</b>, reembolsa fácilmente y emite notas de crédito de manera fácil y rápida.'
        }, {
            icon: 'icon-sprite-map-point',
            iconSize: 40,
            text: '<b>Ten todas tus ubicaciones en un solo lugar</b>, controla sucursales y bodegas clasificando y personalizando a tu preferencia.'
        }, {
            icon: 'icon-sprite-trends',
            iconSize: 40,
            text: '<b>Maneja tus informes</b>, con detalle de tus ventas diarias y comparación a niveles anteriores desde tu aplicación.'
        }
    ];

}
