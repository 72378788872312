import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-boleta-info-support',
  templateUrl: './boleta-info-support.component.html',
  styleUrls: ['./boleta-info-support.component.scss']
})
export class BoletaInfoSupportComponent implements OnInit {
    @Input() public loadingImages: boolean;

    constructor() { }

    ngOnInit(): void {
    }

}
