// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export class urlEnv {
  // DEVELOP.
  public static readonly HAULMER_FACEBOOK = 'https://www.facebook.com/HaulmerLAT/';
  public static readonly HAULMER_TWITTER = 'https://twitter.com/Haulmerlat';
  public static readonly HAULMER_YOUTUBE = 'https://www.youtube.com/channel/UC9zdjjv0lgG0UtxWVZEJN_w';
  public static readonly HAULMER_LINKEDIN = 'https://www.linkedin.com/company/27017963/admin/';
  public static readonly HAULMER_INST = 'https://www.instagram.com/haulmerlat/?hl=es-la';
  public static readonly HAULMER_WORK_FOR_US = 'mailto:career@haulmer.com';
  public static readonly HAULMER_BLOG = 'https://www.haulmer.com/blog/';
  public static readonly HAULMER_NEWS = 'https://www.haulmer.com/news/';
  public static readonly HAULMER_WEBSITE = 'https://www.haulmer.com/';
  public static readonly HAULMER_PUNTO_DE_VENTA = 'https://www.haulmer.com/';
  public static readonly HAULMER_CHILEFIRMAS = 'https://www.chilefirmas.cl/';
  public static readonly HAULMER_OPENFACTURA = 'https://www.openfactura.cl/';
  public static readonly HAULMER_PAGOMASTER = 'https://www.pagomaster.com/cl/';
  public static readonly HAULMER_WEBHOSTING = 'https://www.bluehosting.cl/web-hosting/';
  public static readonly HAULMER_OPENCLOUD = 'https://www.opencloud.cl/';
  public static readonly HAULMER_HELP = 'https://help.haulmer.com/';
  public static readonly HAULMER_TUTORIALES = 'https://www.haulmer.com/docs/';
  public static readonly HAULMER_SLACK = 'https://haulmer.slack.com/';
  public static readonly HAULMER_IDEAS_HAULMER = 'https://ideas.haulmer.com/';
  public static readonly HAULMER_CONTACTAR = 'https://www.haulmer.com/contactar-con-ventas';
  public static readonly TUU_WEBSITE = 'https://website-tuu-cl.haulmer.dev/';
  public static readonly TUU_ARTICULOS = 'https://www.tuu.cl/articulos';
  public static readonly TUU_FACEBOOK = 'https://www.facebook.com/TUU-por-Haulmer-104224755200255/';
  public static readonly TUU_INSTAGRAM = 'https://www.instagram.com/tuu.cl/';
  public static readonly TUU_YOUTUBE = 'https://www.youtube.com/channel/UCwl4ufBqLY8uGiI78i4ioMA';
  public static readonly TUU_TWITTER = 'https://twitter.com/TUU_Chile';
  public static readonly TUU_CART_URL = 'https://website-tuu-cl.haulmer.dev/cart/contact?';
}

export class API {
  public static readonly SEND_FORM_SOMOS_TUU = 'https://api-frontend.haulmer.dev/v1/referred';
  public static readonly GIFT_CODE_TUU = 'https://api.haulmer.dev/v1/payment-account/tuu/giftCode/';
  public static readonly API_STATUS_SIM = 'https://api.haulmer.com/v1/network/sim';
  public static readonly API_JIRA_BOARD = 'https://api-frontend.haulmer.dev/v1/jira';
  public static readonly API_POPUP = 'https://api-frontend.haulmer.dev/v1/popup-form';
  public static readonly API_PROMO_ACTIVATION = 'https://script.google.com/a/macros/haulmer.com/s/AKfycbyVE284AsCgu-ZIVLpJ90WcNG7ORjQpsT3fYBZIlSirRDz5-2SThSObMP8PwGdGnoOW/exec';
  public static readonly SEND_CONTACT_FORM_TUU = 'https://script.google.com/macros/s/AKfycbyEmJQ5K5rWNeylV1Uhrk1miVQCrvO5qcubG_wNJYQJneILIEkYNciNZ8kdJPTC_4gxBQ/exec';
  public static readonly SEND_CONTACT_FORM_TUU_PARTNERS ='https://script.google.com/macros/s/AKfycbyYe9vHKOGo2ktPqYa0uNilFZixXj-27-mpJ_TEVG6yNFWNXRESRJXGPN8xn1MO-l-pKw/exec';
}