import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  private params;

  constructor(
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.params = params;
    })
  }

  public getValue(key: string): string {
    return this.params[key];
  }

  public addParams(url: URL, params: { name: string, value: string }[]): URL {
    params.forEach(param => {
      if (!!param.name && !!param.value) {
        url.searchParams.append(param.name, param.value);
      }
    });
    return url;
  }
}
