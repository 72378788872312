import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';

@Component({
    selector: 'app-section-payment-system',
    templateUrl: './section-payment-system.component.html',
    styleUrls: ['./section-payment-system.component.scss']
})
export class SectionPaymentSystemComponent implements OnInit {
    @Input() public loadingImages: boolean;
    @ViewChild('animationBlock') public animationBlock: ElementRef;

    list = [
        {
            icon: 'icon-sprite-card-chip',
            text: 'Chip'
        },
        {
            icon: 'icon-sprite-credit-card',
            text: 'Banda magnética'
        },
        {
            icon: 'icon-sprite-card-contactless',
            text: 'Contacless'
        },
        {
            icon: 'icon-sprite-g-pay',
            text: 'G pay'
        },
        {
            icon: 'icon-sprite-apple-pay',
            text: 'Apple pay'
        }
    ];

    settings = {
        blockTrigger: undefined,
        list: undefined,
        itemClass: String(),
        currentItem: 0,
        triggerAnimation: false,
        interval: 0,
        timer: undefined
    };

    @HostListener('window:scroll')
    onScroll(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.startAnimation();
        }
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any
    ) { }

    ngOnInit(): void {
        setTimeout(() => {
            this.initAnimationSettings();
        }, 0);
    }

    initAnimationSettings(): void {
        this.settings = {
            blockTrigger: this.animationBlock.nativeElement,
            list: this.animationBlock.nativeElement.getElementsByClassName('list-ts')[0],
            itemClass: 'list-item-ts',
            currentItem: 0,
            triggerAnimation: false,
            interval: 3000,
            timer: undefined
        };
    }

    playAnimation(): void {
        const items = <HTMLCollectionOf<HTMLElement>>
                        this.settings.list.getElementsByClassName(this.settings.itemClass);

        this.settings.timer = setInterval(() => {
            if (this.settings.currentItem < items.length - 1) {
                this.settings.currentItem++;
                this.settings.blockTrigger.classList.add('step-' + this.settings.currentItem);
                items[this.settings.currentItem].classList.add('animate');
            } else {
                for (let i = 0; i <= items.length - 1; i++) {
                    if (items[i].classList.contains('animate')) {
                        items[i].classList.remove('animate');
                    }
                    if (this.settings.blockTrigger.classList.contains('step-' + i)) {
                        this.settings.blockTrigger.classList.remove('step-' + i);
                    }
                }

                setTimeout(() => {
                    this.settings.currentItem = 0;
                    items[this.settings.currentItem].classList.add('animate');
                    this.settings.blockTrigger.classList.add('step-' + this.settings.currentItem);
                }, 100);
            }
        }, this.settings.interval);
    }

    startAnimation(): void {
        const boundingParrent = this.settings.blockTrigger.getBoundingClientRect();

        if ((boundingParrent.top <= 56 || boundingParrent.bottom < window.innerHeight) && boundingParrent.bottom > 100) {
            if (this.settings.triggerAnimation) {
                return;
            }

            this.settings.blockTrigger.classList.add('start-animation');
            this.playAnimation();
            this.settings.triggerAnimation = true;
        } else {
            this.settings.triggerAnimation = false;
            clearInterval(this.settings.timer);
        }
    }

    selectedItem(index: number): void {
        clearInterval(this.settings.timer);
        for (let i = 0; i <= this.settings.list.children.length - 1; i++) {
            if (this.settings.blockTrigger.classList.contains('step-' + i)) {

                this.settings.blockTrigger.classList.remove('step-' + i);
            }
        }
        this.settings.currentItem = index;
        this.settings.blockTrigger.classList.add('step-' + index);
        this.playAnimation();
    }

}
